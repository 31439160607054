.online-msc {
    padding: 5% 0;
    background-color: #F6F6F6;
}

.online-msc .heading-holder .title {
    font-size: 34px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    line-clamp: 1;
}

.online-msc:hover .heading-holder .title {
    color: #FF8600;
}

.online-msc .heading-holder p {
    font-size: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 6;
    line-clamp: 6;
}

.online-msc .image-holder {
    position: relative;
    z-index: 1;
}

.online-msc .image-holder .lawyer-img {
    box-shadow: -2px 3px 6px #00000029;
    border: 4px solid #FFFFFF;
    border-radius: 20px;
    width: 296px;
    height: 358px;
}

.online-msc .video-holder {
    position: absolute;
    /* top: 5%; */
    bottom: 40px;
    left: 20%;
    /* overflow: hidden; */
    z-index: 1;
    transition: top 0.5s ease, transform 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    transform: translateX(0);
}


.online-msc:hover .video-holder {
    /* top: 10%; */
    transform: translateX(40px);
}

.online-msc .online-video {
    box-shadow: -2px 3px 6px #00000029;
    border: 4px solid #FFFFFF;
    border-radius: 20px;
    /* width: 100%; */
    width: 296px;
    height: 198px;
    object-fit: cover;
    /* margin-top: 10px; */
}

.online-msc .playBtnMain .platBtn {
    width: 37px;
    height: 37px;

}

.online-msc .playBtnMain {
    position: absolute;
    bottom: 40%;
    right: 41%;
}



.online-msc .circle-holder {
    width: 208px;
    height: 208px;
    position: absolute;
    top: 18%;
    left: 38%;
    z-index: -1;
    border: 21px solid #FF8600;
    border-radius: 100px;
    transition: top 0.5s ease, transform 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    transform: translateY(0);
}

.online-msc:hover .circle-holder {
    -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) alternate both;
    animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) alternate both;
    top: 10%;
    transform: translateY(-5px);
}

@-webkit-keyframes slide-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
}

@keyframes slide-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
}

@-webkit-keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(5px);
        transform: translateY(-5px);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@media(min-width:0px) and (max-width:320px) {
    .online-msc .video-holder {
        left: 0%;
    }

    .online-msc .circle-holder {
        left: 14%;
        width: 155px;
        height: 156px;
    }

    .online-msc .image-holder .lawyer-img {
        width: 199px;
        height: 251px;
    }

    .online-msc .online-video {
        width: 187px;
        height: 116px;
    }

    @keyframes moveVideo {
        0% {
            transform: translateX(0);
        }

        50% {
            transform: translateX(-5%);
        }

        100% {
            transform: translateX(0);
        }
    }
}

@media(min-width:321px) and (max-width:420px) {
    .online-msc .video-holder {
        left: 0%;
    }

    .online-msc .circle-holder {
        left: 14%;
        width: 155px;
        height: 156px;
    }

    .online-msc .image-holder .lawyer-img {
        width: 238px;
        height: 281px;
    }

    .online-msc .online-video {
        width: 221px;
        height: 138px;
    }

    @keyframes moveVideo {
        0% {
            transform: translateX(0);
        }

        50% {
            transform: translateX(-5%);
        }

        100% {
            transform: translateX(0);
        }
    }
}

@media(min-width:421px) and (max-width:485px) {
    .online-msc .video-holder {
        left: 0%;
    }

    .online-msc .circle-holder {
        left: 17%;
        width: 185px;
        height: 190px;
    }

    .online-msc .image-holder .lawyer-img {
        width: 256px;
        height: 306px;
    }

    .online-msc .online-video {
        width: 250px;
        height: 159px;

    }

}

@media(min-width:486px) and (max-width:576px) {
    .online-msc .video-holder {
        left: 3%;
    }

    .online-msc .circle-holder {
        left: 23%;
    }
}

@media(min-width:578px) and (max-width:767px) {
    .online-msc .video-holder {
        left: 3%;
    }

    .online-msc .circle-holder {
        left: 27%;
    }
}

@media(min-width:0px) and (max-width:991px) {
    .online-msc .heading-holder .title {
        font-size: 20px;
    }

    .online-msc .heading-holder p {
        font-size: 14px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .online-msc .circle-holder {
        top: 18%;
        left: 20%;

    }

    .online-msc .video-holder {
        position: absolute;
        /* top: 5%; */
        bottom: 40px;
        left: 0px;
        /* overflow: hidden; */
        z-index: 1;
    }

    .online-msc .heading-holder .title {
        font-size: 25px;
    }

    .online-msc .heading-holder p {
        font-size: 14px;
    }

    .online-msc .online-video {
        width: 257px;
        height: 182px;

    }

}

@media(min-width:1200px) and (max-width:1400px) {
    .online-msc .circle-holder {
        top: 18%;
        left: 30%;

    }

    .online-msc .video-holder {
        position: absolute;
        /* top: 5%; */
        bottom: 40px;
        left: 19px;
        /* overflow: hidden; */
        z-index: 1;
    }
}