.leardership-specialization {
    margin: 3% 0;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    padding: 70px 0px;
    max-height: fit-content;
    overflow: hidden;
    overflow-y: scroll;
}

.leardership-specialization .first-box {
    position: relative;

}

.leardership-specialization .first-box .image-holder {
    overflow: hidden;
    border-radius: 35px;
    width: 50%;
    height: 50%;
    aspect-ratio: 1/1;

}

.leardership-specialization .first-box .image-holder .student-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: 0px 0px 3px #00000029;
    border-radius: 35px;
    transition: 0.3s;

}

.leardership-specialization .first-box:hover .image-holder .student-img {
    transform: scale(1.1);
    transition: 0.3s;

}

.leardership-specialization .first-box .card-holder {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 16px;
    padding: 20px;
    position: absolute;
    top: 61px;
    left: 262px;
    transition: 0.3s;
    border: 1px solid #FFF;
    height: 234px;
}

.leardership-specialization .first-box:hover .card-holder {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #FF8000;
    border-radius: 16px;
    transition: 0.3s;

}

.leardership-specialization .first-box .card-holder h2 {
    font-size: 20px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
}

.leardership-specialization .first-box .card-holder p {
    font-size: 13px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 10;
    text-align: justify;
    margin-bottom: 0;
}

@media(min-width:1201px) and (max-width:1440px) {
    .leardership-specialization .first-box .card-holder p {
        -webkit-line-clamp: 7;
    }
}



@media(min-width:992px) and (max-width:1200px) {
    .leardership-specialization .first-box .card-holder {
        left: 179px;
        top: 28px;
    }

    .leardership-specialization .first-box .card-holder2 {
        top: 22px;
        left: 197px;
    }

    .leardership-specialization .first-box .card-holder h2 {
        font-size: 16px;
    }

    .leardership-specialization .first-box .card-holder p {
        -webkit-line-clamp: 7;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .leardership-specialization .first-box .card-holder {
        left: 163px;
        top: 11px;
    }

    .leardership-specialization .first-box .card-holder h2 {
        font-size: 16px;
    }

    .leardership-specialization .first-box .card-holder p {
        -webkit-line-clamp: 7;
    }


}

@media(min-width:468px) and (max-width:767.98px) {

    .leardership-specialization .first-box .card-holder h2 {
        font-size: 16px;
    }

    .leardership-specialization .first-box .card-holder {
        position: unset;
        margin-top: 10px;
        height: auto;
    }

    .leardership-specialization .first-box .image-holder {
        margin: auto;
    }
}

@media(min-width:0px) and (max-width:486px) {
    .leardership-specialization .first-box .card-holder {
        position: unset;
        margin-top: 10px;
        height: auto;
    }

    .leardership-specialization .first-box .image-holder {
        margin: auto;
    }

    .leardership-specialization .first-box .card-holder h2 {
        font-size: 16px;
    }
}