.suggest_course_section {
    margin: 5% 0;

}


.suggest_course_section .suggest_card{
    border-radius: 10px;
    /* background-color: aliceblue; */
    box-shadow: 0px 3px 6px #00000029;
    overflow:hidden ;
    min-height: 335px;
    position: relative;
}

.suggest_course_section .title_suggest {
    /* text-align: center; */
    display: flex;
    justify-content: center;
}

.suggest_course_section .suggtitle {
    font-size: 20px;
    font-weight: 500;
    border-bottom: 2px solid #FF9900;
    width: -moz-fit-content;
    width: fit-content;
    padding-bottom: 5px;
}
.suggest_course_section .suggest_card .suggest_imagemain_div{
    position: relative;
}
.suggest_course_section .suggest_card .suggest_imagemain_div .sugggest_image {
    height: auto;
    width: 100%;
}
.suggest_course_section .suggest_card .suggest_imagemain_div .sugggest_image .sugg_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.suggest_course_section .suggest_card .suggest_imagemain_div .type_div{
    position: absolute;
    right: 0;
    top: 0;
    background-color: #7F2FE7;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 0px 0px 0px 10px;
}

.suggest_course_section .suggest_card .suggest_imagemain_div .clg_bgrd {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60%;
    background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
    /* border-radius: 0px 0px 27px 27px; */
}
.suggest_course_section .suggest_card .suggest_imagemain_div .type_div p{
   margin: 0;
   font-size: 12px;
   color: #fff;
   display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.suggest_course_section .suggest_card .suggest_imagemain_div .logos_main_div{
    position: absolute;
    bottom: 10px;
    left: 10px;
    height: 50px;
    width: 50px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}

.suggest_course_section .suggest_card .suggest_imagemain_div .logos_div{
    width: 35px;
    height: 35px;
}
.suggest_course_section .suggest_card .suggest_imagemain_div .logos_div .logos-img{
    width: 100%;
    aspect-ratio: 1/1;
}

.suggest_course_section .suggest_card .clgname_div{
    background-color: #FCF1F1;
    padding: 5px 10px;
    text-align: center;
}
.suggest_course_section .suggest_card .clgname_div p{
    color: #f70909ef;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.suggest_course_section .suggest_card .card_text{
    padding: 10px;
}

.suggest_course_section .suggest_card .facality {
    color: #6A12B1;
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 600;
    display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.suggest_course_section .suggest_card .coursename {
    font-size: 18px;
    font-weight: 600;
    display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}
.suggest_course_section .suggest_card .degreename {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
    display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.suggest_course_section .suggest_card .iconname{
    color: #FF9900;
}

.suggest_course_section .suggest_card .button_main_div {
    position: absolute;
    width: 100%;
    bottom: 10px;
    left: 0;
    padding: 0px 10px;
}
.suggest_course_section .suggest_card .view_program_btn {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #b5afaf;
    font-size: 12px;
    font-weight: 500;
    padding: 6px 0px;
}
.suggest_course_section .suggest_card .view_program_btn:hover {
    background-color: #FF8600;
    color: #fff;
}

.suggest_course_section .suggest_card .syllabus_btn {
    background-color: #FF8600;
}