.become-partner-modal-form {
    padding: 30px;
    text-align: center;
}

.become-partner-modal-form h2 {
    text-align: center;
}

.become-partner-modal-form input {
    box-shadow: 0px 0px 5px #00000029;
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border-radius: 5px;
    margin-top: 3%;
    border: none;
    outline: none;
}

.become-partner-modal-form textarea {
    box-shadow: 0px 0px 5px #00000029;
    width: 100%;
    background: #FFFFFF;
    border-radius: 5px;
    margin-top: 3%;
    border: none;
    outline: none;
}

.become-partner-modal-form [type=number]::-webkit-inner-spin-button,
.become-partner-modal-form [type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.become-partner-modal-form input::placeholder {
    font-size: 14px;
    color: #000000B2;
    font-weight: 500;
    padding-left: 4%;
}

.become-partner-modal-form textarea::placeholder {
    font-size: 14px;
    color: #000000B2;
    font-weight: 500;
    padding-left: 4%;
}


.become-partner-modal-form .submit-btn {
    background: #FF8600;
    border-radius: 5px;
    border: none;
    color: #FFFFFF;
    font-size: 16px;
    margin-top: 5%;
    padding: 5px 20px;
}

@media(min-width:0px) and (max-width:767px){
    .become-partner-modal-form {
        padding: 10px;
        text-align: center;
    }
}