.home-banner-section {
    margin: 2% 0% 5% 0%;
}
.home-banner-section .slider-section .banner-slide-div{
    position: relative;
    width: 100%;
    height: 100%;
}

.home-banner-section .slider-section .banner-slide-div .image-div .banner-img{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
}
.home-banner-section .slider-section .banner-slide-div .banner-content-div .prop-title{
    font-weight: bold;
    font-size: 56px;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
}
.home-banner-section .slider-section .banner-slide-div .banner-content-div {
    position: absolute;
    top: 28%;
    left: 8%;
    z-index: 99;
}
.home-banner-section .slider-section .banner-slide-div .banner-content-div .subtitle{
    font-weight: 600;
    font-size: 32px;
    color: #fff;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;                                       
}
.home-banner-section .slider-section .banner-slide-div .banner-content-div .subcontent {
    background: linear-gradient(90deg, #ff8600, #0000);
    font-size: 20px;
    color: #fff;
    padding: 10px;
    margin-top: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
}
.home-banner-section .slider-section .swiper-pagination {
    text-align: left;
    left: 8% !important;
    bottom: 9% !important;
}
.home-banner-section .slider-section .swiper-pagination-bullet-active {
    background: #fff;
    width: 20px !important;
    border-radius: 5px;
    height: 4px;
}
.home-banner-section .slider-section .swiper-pagination-clickable .swiper-pagination-bullet {
    background: #fff;
    width: 10px;
    height: 4px;
    border-radius: 5px;
    margin: 2px !important;
}


@media (min-width:0px) and (max-width:400px){
    .home-banner-section .slider-section .banner-slide-div .image-div .banner-img {
        /* height: 200px; */
    }
    .home-banner-section .slider-section .banner-slide-div .banner-content-div .prop-title {
        font-size: 22px;
    }
    .home-banner-section .slider-section .banner-slide-div .banner-content-div .subtitle {
        font-size: 14px;
    }
    .home-banner-section .slider-section .banner-slide-div .banner-content-div .subcontent {
        font-size: 11px;
        padding: 4px;
        margin-top: 15px;
    }
    .home-banner-section .slider-section .banner-slide-div .banner-content-div {
        top: 20%;
    }
}

@media (min-width:401px) and (max-width:575px){
    .home-banner-section .slider-section .banner-slide-div .image-div .banner-img {
        /* height: 200px; */
    }
    .home-banner-section .slider-section .banner-slide-div .banner-content-div .prop-title {
        font-size: 25px;
    }
    .home-banner-section .slider-section .banner-slide-div .banner-content-div .subtitle {
        font-size: 16px;
    }
    .home-banner-section .slider-section .banner-slide-div .banner-content-div .subcontent {
        font-size: 12px;
        padding: 4px;
        margin-top: 15px;
    }
    .home-banner-section .slider-section .banner-slide-div .banner-content-div {
        top: 20%;
    }
}

@media (min-width:576px) and (max-width:768px){
    .home-banner-section .slider-section .banner-slide-div .image-div .banner-img {
        /* height: 300px; */
    }
    .home-banner-section .slider-section .banner-slide-div .banner-content-div .prop-title {
        font-size: 35px;
    }
    .home-banner-section .slider-section .banner-slide-div .banner-content-div .subtitle {
        font-size: 20px;
    }
    .home-banner-section .slider-section .banner-slide-div .banner-content-div .subcontent {
        font-size: 16px;
        padding: 6px;
        margin-top: 30px;
    }
}

@media (min-width:769px) and (max-width:991px){
    .home-banner-section .slider-section .banner-slide-div .image-div .banner-img {
        /* height: 400px; */
    }
    .home-banner-section .slider-section .banner-slide-div .banner-content-div .prop-title {
        font-size: 42px;
    }
    .home-banner-section .slider-section .banner-slide-div .banner-content-div .subtitle {
        font-size: 22px;
    }
    .home-banner-section .slider-section .banner-slide-div .banner-content-div .subcontent {
        font-size: 17px;
        padding: 7px;
        margin-top: 32px;
    }
}


@media (min-width:992px) and (max-width:1200px){
    .home-banner-section .slider-section .banner-slide-div .image-div .banner-img {
        /* height: 430px; */
    }
    .home-banner-section .slider-section .banner-slide-div .banner-content-div .prop-title {
        font-size: 45px;
    }
    .home-banner-section .slider-section .banner-slide-div .banner-content-div .subtitle {
        font-size: 25px;
    }
    .home-banner-section .slider-section .banner-slide-div .banner-content-div .subcontent {
        font-size: 18px;
        padding: 8px;
        margin-top: 35px;
    }
}


@media (min-width:1201px) and (max-width:1400px){

}