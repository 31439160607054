.contact-banner {
    margin: 2% 0% 0% 0%;
}


.contact-banner .bbabanner-img {
    width: 100%;
}

.card-section .main-card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #00000029;
    position: relative;
    min-height: 193px;
    height: 97%;
    margin-top: -28px;
    padding: 0px 10px;
}

.card-section .main-card .card-content h6 {
    font-size: 18px;
    font-weight: bold;
    text-decoration: underline;
    text-align: center;
    padding-top: 66px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2;
}

.card-section .main-card .card-content p {
    font: normal normal normal 14px/25px Roboto;
    letter-spacing: 0px;
    color: #383838;
    text-align: center;
    font-weight: 500;
    padding-top: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;
    line-clamp: 4;
}

.card-section .main-card .address-icon {
    width: 78px;
    height: 78px;
    background: #FF8600 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 5px #00000029;
    border-radius: 50px;
    position: absolute;
    top: -36px;
    left: 41%;
    text-align: center;
}

.card-section .main-card .address-icon .add-icon {
    width: 30px;
    height: 40px;
    margin-top: 17px;
}

.card-section .main-card .address-icon .call-icon {
    width: 40px;
    height: 40px;
    margin-top: 17px;
}

.card-section .main-card .address-icon .email-icon {
    width: 49px;
    height: 35px;
    margin-top: 20px;
}

@media(min-width:0px) and (max-width:420px) {
    .card-section .main-card .address-icon {
        left: 42% !important;

    }
}

@media(min-width:421px) and (max-width:486px) {
    .card-section .main-card .address-icon {
        left: 43% !important;

    }
}

@media(min-width:0px) and (max-width:767px) {
    .card-section .main-card {
        min-height: 193px;
        height: auto;
        margin-top: 15%;
        padding: 0px 10px;

    }

    .card-section .main-card .card-content p {
        font: normal normal normal 13px/25px Roboto;
        font-weight: 500;
    }

    .card-section .main-card .address-icon .call-icon {
        width: 28px;
        height: 27px;
        margin-top: 17px;
    }

    .card-section .main-card .address-icon .add-icon {
        width: 20px;
        height: 27px;
        margin-top: 17px;
    }

    .card-section .main-card .address-icon .email-icon {
        width: 33px;
        height: 22px;
        margin-top: 18px;
    }

    .card-section .main-card .address-icon {
        width: 61px;
        height: 61px;
        top: -27px;
        left: 45%;

    }
}

@media(min-width:768px) and (max-width:991px) {
    .card-section .main-card .card-content p {
        font: normal normal normal 13px/25px Roboto;
        font-weight: 500;
    }

    .card-section .main-card .card-content p br {
        display: none;
        /* Hides the <br> tag */
    }

    .card-section .main-card .address-icon .call-icon {
        width: 28px;
        height: 27px;
        margin-top: 17px;
    }

    .card-section .main-card .address-icon .add-icon {
        width: 20px;
        height: 27px;
        margin-top: 17px;
    }

    .card-section .main-card .address-icon .email-icon {
        width: 33px;
        height: 22px;
        margin-top: 18px;
    }

    .card-section .main-card {
        min-height: 215px;
        height: 100%;


    }

    .card-section .main-card .address-icon {
        width: 61px;
        height: 61px;
        top: -27px;
        left: 38%;

    }

}

@media(min-width:769px) and (max-width:992px) {
    .card-section .main-card {
        margin-top: -10px;

    }
}

@media(min-width:992px) and (max-width:1200px) {
    .card-section .main-card .card-content p {
        font: normal normal normal 13px/25px Roboto;
        font-weight: 500;
    }

    .card-section .main-card .address-icon {
        left: 38%;
    }

    .card-section .main-card {
        margin-top: -12px;
    }
}