.third-banner-section .blogbanner-image .blog-img {
    width: 100%;    
    height: auto;
    background: no-repeat; 
 }

 .blogbanner-image
 {
    position: relative;
 }
 .third-banner-section .back-icon-div
 {
    position: absolute;
    top: 10px;
    left: 10px;
 }


 .third-banner-section .back-icon-div .back-icon {
   width: 25px;
}

 @media (min-width:0px) and (max-width:768px){
   .third-banner-section .back-icon-div .back-icon {
       width: 20px;
   }
}