.loader {
    width: 100%;
    height: 100vh;
    background: #fff;
}

.loader .loader-animation {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
}

/* .loader .loader-animation img {
    width: 200px;
    animation: beat .25s infinite alternate;
    transform-origin: center;
}

@keyframes beat {
    to {
        transform: scale(1.4);
    }
} */