.registration-process {
    margin: 5% 0 1% 0;
}

.registration-process .text-holder-process {
    text-align: center;
    color: #000000;
}

.registration-process .text-holder-process h2 {
    font-size: 32px;
    font-weight: bold;
}

.registration-process .text-holder-process p {
    font-size: 16px;
    font-weight: 500;
}

.registration-process .process-swiper .main-card-process {
    height: 111px;
    /* width: 296px; */
    box-shadow: 0px 1px 6px #FF860059;
    border: none;
    margin: 15% 0 25% 0%;
}

.registration-process .process-swiper .main-card-process .card-text {
    padding: 0;
    font-size: 14px;
    text-align: center;
    padding: 5px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    font-weight: 500;
    color: #000;
}

.registration-process .swiper-pagination-bullet {
    width: 10px;
    height: 4px;
    border-radius: 5px;
    opacity: 1;
    background-color: #CECECE;
}

.registration-process .swiper-pagination-bullet-active {
    width: 18px;
    background: #000;
}

/* Media */

@media(min-width:0px) and (max-width:320px) {
    .registration-process .text-holder-process h2 {
        font-size: 20px;
    }

    .registration-process .text-holder-process p {
        font-size: 12px;
    }



    .registration-process .process-swiper .main-card-process .card-text {
        font-size: 12px;
    }
}

@media(min-width:321px) and (max-width:485px) {
    .registration-process .text-holder-process h2 {
        font-size: 20px;
    }

    .registration-process .text-holder-process p {
        font-size: 13px;
    }



    .registration-process .process-swiper .main-card-process .card-text {
        font-size: 13px;
    }
}

@media(min-width:486px) and (max-width:576px) {
    .registration-process .text-holder-process h2 {
        font-size: 20px;
    }

    .registration-process .text-holder-process p {
        font-size: 13px;
    }



    .registration-process .process-swiper .main-card-process .card-text {
        font-size: 13px;
    }
}

@media(min-width:577px) and (max-width:768px) {
    .registration-process .text-holder-process h2 {
        font-size: 20px;
    }



    .registration-process .text-holder-process p {
        font-size: 14px;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .registration-process .text-holder-process h2 {
        font-size: 20px;
    }



    .registration-process .text-holder-process p {
        font-size: 13px;
    }
}

@media(min-width:992px) and (max-width:1200px) {


    .registration-process .text-holder-process p {
        font-size: 14px;
    }

    .registration-process .text-holder-process h2 {
        font-size: 25px;
    }

}