.MAcards .MACardImg {
    width: 100%;
}

.MAcards {
    background: #FFF8F0 0% 0% no-repeat padding-box;
    margin: 7% 0% 3% 0%;
    /* padding-top: 3%; */
}

.MAcards .maCardsec {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #00000029;
    border-radius: 15px;
    height: 145px;
    position: relative;
    transition: transform 0.4s ease-in-out;
    border: none;
    margin: 10px 10px 20px 10px;
}

.MAcards .offered_img {
    width: 50px;
    height: 50px;
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translate(-50%, 0%);
}

.MAcards .cardPara {
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
}

.MAcards .blackStrip {
    background: #E6E6E6 0% 0% no-repeat padding-box;
    height: 54px;
    width: 509px;
    float: right;
}

.MAcards .MAcardsrow {
    margin-top: 4%;

}

.MAcards .platbtnImg {
    width: 54px;
    height: 54px;
}

.MAcards:hover .MAcardsrow .upper-card {
    transform: translateY(30px);
}

.MAcards:hover .MAcardsrow .down-card {
    transform: translateY(-30px);
}

@media(min-width:0px) and (max-width:425px) {

    .MAcards .cardPara {
        margin-top: 10px;
        font-size: 14px;
    }

    .MAcards .blackStrip {
        width: 260px !important;

    }
}

@media(min-width:0px) and (max-width:767px) {

    .MAcards .cardPara {
        margin-top: 10px;
        font-size: 14px;
    }

    .MAcards .blackStrip {
        width: 357px;

    }

    .MAcards .MAcardsrow:hover .upper-card {
        transform: translateY(0px);
    }

    .MAcards .MAcardsrow:hover .down-card {
        transform: translateY(0px);
    }

    .MAcards .maCardsec {
        margin: 25px 10px 25px 10px;
    }
}

@media(min-width:768px) and (max-width:991px) {

    .MAcards .cardPara {
        margin-top: 10px;
        font-size: 14px;
    }

    .MAcards .blackStrip {
        width: 533px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .MAcards .blackStrip {
        width: 370px;

    }

    .MAcards .cardPara {
        margin-top: 10px;
        font-size: 13px;
    }
}

@media(min-width:1200px) and (max-width:1400px) {
    .MAcards .blackStrip {
        width: 450px;

    }

    .MAcards .cardPara {
        margin-top: 10px;
        font-size: 14px;
    }
}