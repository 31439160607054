.OurAluminis {
    background: url("../../../../../public/assets/images/home//OurAlumini/aluminibanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 3%;
    margin-bottom: 5%;
}

.OurAluminis .AluminiMain .aluminiImgcont {
    position: relative;
    /* border-radius: 50%;
    width: 138px;
    height: 138px; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.OurAluminis .AluminiMain .aluminiImgcont img {
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 5px;
}

.OurAluminis .AluminiMain .aluminiImgcont .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 0.76;
    overflow: hidden;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transition: .3s ease;
    border-radius: 50%;
}

.OurAluminis .AluminiMain .aluminiImgcont .overlay .image-app {
    color: #000;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.OurAluminis .AluminiMain .companyimg {
    width: 128px !important;
    object-fit: contain;
    height: 65px !important;
}

.OurAluminis .AluminiMain .aluminiheadmain {
    font-size: 32px;
    text-align: center;
}

.OurAluminis .AluminiMain .aluminiheadmain h3 {
    font: normal normal bold 32px/30px Open Sans;
    margin-bottom: 1%;
}

.OurAluminis .AluminiMain .aluminiheadmain p {
    text-align: center;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000;
    margin-bottom: 20px;
}

.OurAluminis .aluminibtnsec .careerbtn {
    margin-top: 2%;
    background: #FF9900 0% 0% no-repeat padding-box;
    border: 0;
}

.OurAluminis .swiper-pagination-bullet-active {
    background: #ee9723;
}

.OurAluminis .AluminiMain .swiper-wrapper {
    margin-bottom: 5%;
    /* justify-content: center; */
}

.OurAluminis .AluminiMain .swiper-slide {
    margin: auto;
}

/* .OurAluminis .commmm-img {
    width: 50%;
    height: 100%;
} */

.OurAluminis .commmm-img {
    /* width: 100%; */
    height: 30px;
}

.OurAluminis .AluminiMain .white-bgggg {
    background: #FFF;
    box-shadow: 0px 3px 6px #a4a2a2;
    padding: 10px;
}

.OurAluminis .AluminiMain .name-alumini {
    font-size: 16px;
    font-weight: 500;
}

@media screen and (min-width: 0px) and (max-width: 575px) {
    .OurAluminis .AluminiMain .aluminiheadmain h3 {
        font: normal normal bold 20px/30px Open Sans;
    }

    .OurAluminis .AluminiMain .aluminiheadmain p {
        font-size: 14px;
    }

    .OurAluminis .AluminiMain .aluminiImgcont img {
        width: 80px;
        height: 80px;
    }

    .OurAluminis .AluminiMain .swiper-wrapper {
        margin-bottom: 15%;
    }

    .OurAluminis .AluminiMain .name-alumini {
        font-size: 13px;
    }
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {


    .OurAluminis .AluminiMain .aluminiheadmain p {
        font-size: 14px;
    }

    .OurAluminis .AluminiMain .aluminiImgcont img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }

    .OurAluminis .AluminiMain .swiper-wrapper {
        margin-bottom: 15%;
    }

    .OurAluminis .AluminiMain .aluminiheadmain h3 {
        font: normal normal bold 20px/30px Open Sans !important;

    }

    .OurAluminis .AluminiMain .name-alumini {
        font-size: 14px;
    }
}

@media screen and (min-width: 576px) and (max-width: 991px) {
    .OurAluminis .AluminiMain .aluminiImgcont {
        width: 100px;
        height: 100px;
    }

    .OurAluminis .AluminiMain .name-alumini {
        font-size: 12px;
    }
}