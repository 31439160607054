.we-deliver-section{
    background: #fff8f0;
    margin-bottom: 5%;
}
.we-deliver-section .contttt-div .wede-title{
    font-weight: bold;
    font-size: 32px;
    color: #000;
    margin-bottom: 30px;
}
.we-deliver-section .contttt-div{
    text-align: center;
}
.we-deliver-section .contttt-div .wede-cont{
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: #383838;
    margin-bottom: 0px;
}


@media (min-width:0px) and (max-width:768px){
    .we-deliver-section .contttt-div .wede-title {
        font-size: 20px;
    }
    .we-deliver-section .contttt-div .wede-title {
        margin-bottom: 15px;
    }
    .we-deliver-section .contttt-div .wede-cont {
        font-size: 14px;
        line-height: 25px;
        text-align: justify;
    }
    .we-deliver-section {
        padding: 35px 10px;
    }
}


@media (min-width:769px) and (max-width:991px){
    .we-deliver-section .contttt-div .wede-title {
        font-size: 26px;
    }
    .we-deliver-section .contttt-div .wede-cont {
        font-size: 15px;
        line-height: 26px;
    }

}

@media (min-width:992px) and (max-width:1200px){
    .we-deliver-section .contttt-div .wede-title {
        font-size: 28px;
    }
    .we-deliver-section .contttt-div .wede-cont {
        font-size: 15px;
        line-height: 26px;
    }

}