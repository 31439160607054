/* .QuestionsStepFormalumini {
  background-image: url("../../../../../../public/assets/images/stepform/steformbanner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
} */

.QuestionsStepFormalumini .navigation-buttons {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.QuestionsStepFormalumini .navigation-buttons .apply-btn-holder {
  margin: 0px 5px;
}

.QuestionsStepFormalumini .select-option__control {
  width: 200px;
}

.QuestionsStepFormalumini .react-select__menu {
  width: 500px !important;
}

.QuestionsStepFormalumini .navigation-buttons .apply-btn-holder .apply_now_btn {
  padding: 7px 46px;
  background: #ee9723;
  border: 0;
}


.QuestionsStepFormalumini .navigation-buttons .apply-btn-holder .back-btn {
  font-size: 14px;
  font-weight: 500;
  background: #FFFFFF;
  border: 1px solid #EE9723;
  border-radius: 5px;
  color: #05264e;
  padding: 7px 46px;
}



/* .navigation-buttons .apply-btn-holder .back-btn:hover {
  background: #EE9723;
  border: 1px solid #EE9723;
  color: #FFF;
} */

.Questions-sec {
  margin-top: 5%;
  margin-bottom: 5%;
}

.Questions-sec .aluminiselect .css-b62m3t-container {
  width: 200px;
}

.QuestionsStepFrom .StepForm .shadow-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 20px #00000029;
  border-radius: 0px 0px 10px 10px;
  opacity: 1;
  padding: 0px 5% 3% 5%;
}

.QuestionsStepFrom .StepForm .shadow-container .progress-indicator {
  background-color: #E9E9E9;
}


.Questions-sec .form-control {
  border-bottom: 1px solid #707070;
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0%;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  box-shadow: none;
}

.Questions-sec label.form-label {
  font-size: 15px;
  font-weight: 500;
  color: #131313;
}

.Questions-sec h6 {
  margin: 3% 0% 0% 0%;
  font-size: 20px;
  font-weight: bold;
}

.Questions-sec .react-datepicker-wrapper {
  width: 100%;
}

.Questions-sec .react-datepicker-wrapper input {
  width: 100%;
  border: 1px solid #BFBFBFB3;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  padding: .375rem .75rem;
}

.selected-course h5 {
  font-size: 15px;
  font-weight: 500;
  color: #131313;
}

.selected-course p {
  border-bottom: 1px solid #BFBFBFB3;
  border-top: 1px solid #BFBFBFB3;
  border-right: 1px solid #BFBFBFB3;
  border-left: 1px solid #BFBFBFB3;
  border-radius: 5px;
  font-size: 15px;
  /* font-weight: 500; */
  color: #000;
  box-shadow: none;
  padding: 6px;
}