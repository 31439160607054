.master-management {
    margin: 5% 0;
}

.master-management .card {
    border: none;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px #00000029;
    border-radius: 23px;
}

.master-management .card .management-holder {
    padding: 20px 0px 1px 45px;
    ;
}

.master-management .card .management-holder .main-heading h2 {
    color: #F99903;
    font-size: 20px;
    font-weight: 500;
}

.master-management .card .management-holder .main-heading h3 {
    color: #000;
    font-size: 18px;
    font-weight: 500;
}

.master-management .card .management-holder p {
    color: #000;
    font-size: 14px;

}

.master-management .card .management-holder ul {
    padding-left: 13px;
}

.master-management .card .management-holder ul li {
    font-size: 14px;
    font-weight: 500;
}

.master-management .card .management-img {
    overflow: hidden;
    height: 100%;
    width: 100%;
    /* box-shadow: 0px 3px 6px #00000029; */
    border-radius: 23px;
    text-align: right;
}

.master-management .card .management-img .girl-img {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 23px;
    height: 100%;
    width: 100%;
    transition: 0.3s;
}

.master-management .card .management-img:hover .girl-img {
    transform: scale(1.1);
    transition: 0.3s;
}

@media(min-width:0px) and (max-width:767.98px) {
    .master-management .card .management-holder {
        padding: 20px 0px 1px 15px;
    }

    .master-management .card .management-holder .main-heading h2 {
        font-size: 16px;
    }

    .master-management .card .management-holder p {
        font-size: 12px;
    }

    .master-management .card .management-holder ul li {
        font-size: 13px;

    }

    .master-management .card .management-holder .main-heading h3 {
        font-size: 15px;

    }
}

@media(min-width:0px) and (max-width:991px) {
    .master-management .card .management-img {
        text-align: center;
    }
}