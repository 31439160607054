.semister-table .semister-heading h5 {
    font-size: 22px;
    text-align: center;
    color: #000;
}

.semister-table .semister-heading {
    background-color: #ffe0a8;
    padding: 10px;
}

.semister-table th {
    font-size: 17px;
    font-weight: bold;
}

.semister-table td {
    font-size: 15px;
    font-weight: 400;
}

.semister-table p {
    font-size: 15px;
    font-weight: 400;
}
@media(min-width:0px) and (max-width:768px)
{
    .semister-table .semister-heading h5 {
        font-size: 16px;  
    }
    .semister-table p {
        font-size: 13px;
    }
    .semister-table td {
        font-size: 13px;
    }
    .semister-table th {
        font-size: 14px;
    }
}