.addvertisement-section {
    margin-bottom: 5% 0;
}

.addvertisement-section .banner-slide-div .image-div .banner-img {
    width: 100%;
    aspect-ratio: 1/1;
}

.addvertisement-section .swiper-pagination-bullet-active {
    background: #ee9723;
}

.addvertisement-section .swiper-button-next:after,
.online-learning .swiper-button-prev:after {
    font-size: 25px;
    filter: invert(1);
    display: none;
}