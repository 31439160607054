.e-brochure {
    position: relative;
}

.e-brochure .text-holder-brochure h2 {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    color: #000000;
}

.e-brochure .text-holder-brochure p {
    font-size: 16px;
    text-align: center;
    color: #000000;
}

.e-brochure .brochure-banner-img {
    width: 100%;
    margin-top: 2%;
}

.e-brochure .error-msg {
    font-size: 12px;
}

.e-brochure .text-danger {
    margin-bottom: 0px;
    font-size: 12px;
}


/* Form CSS */
.e-brochure .enquiry-form {
    position: absolute;
    top: 23%;
    left: 68%;
}

.e-brochure .enquiry-form .apply-online-title h2 {
    color: #FFF;
    font-size: 25px;
    font-weight: bold;
}

.e-brochure .enquiry-form .apply-online-title h2 span {
    text-decoration: underline;
}

/* .e-brochure .enquiry-form input {
    width: 352px;
    background: #FFFFFF;
    border-radius: 5px;
    margin-top: 3%;
    border: none;
    outline: none;
}

.e-brochure input[type=number]::-webkit-inner-spin-button,
.e-brochure input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.e-brochure .enquiry-form input::placeholder {
    font-size: 14px;
    color: #000000B2;
    font-weight: 500;
    padding-left: 4%;
}

.e-brochure .enquiry-form select {
    width: 352px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 5px;
    margin-top: 3%;
    border: none;
    color: #000000B2;
    font-size: 14px;
    font-weight: 500;
    padding-left: 2%;
} */

.e-brochure .enquiry-form .submit-btn-enquiry-form {
    margin-top: 3%;
    background: #FF8600;
    border-radius: 5px;
    color: #FFF;
    border: none;
    font-size: 16px;
    padding: 5px 15px;
}

.e-brochure .enquiry-form .resend-otp span {
    font-size: 10px;
    color: #FF2424;
}

.e-brochure .enquiry-form .resend-otp {
    font-size: 10px;
    color: #FFF;

}

.e-brochure .enquiry-form .input-group {
    display: flex;
    align-items: center;
    padding: .25rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #FFF;
}

.get-otp-btn {
    font-size: 10px;
    background: #FF860059;
    border-radius: 5px;
    border: none;
    padding: 3px 8px;
    position: absolute;
    right: 10px;
    top: 10px;
}

.e-brochure .enquiry-form .black-border-input {
    width: 100%;
    border-radius: 6px;
}

.e-brochure .enquiry-form .black-border-input input {
    border: 1px solid;
    width: 352px;

    background: #FFFFFF;
    border-radius: 5px;
    border: none;
    padding-bottom: 8px;
    outline: none;
}

.e-brochure .enquiry-form .send-otp-text {
    position: relative;
}

.e-brochure .enquiry-form .send-otp-text .resend-otp {
    position: absolute;
    right: 0px;
}

.getotop-main {
    position: relative;
}

/* Media */

@media(min-width:0px) and (max-width:320px) {
    .e-brochure .brochure-banner-img {
        display: none;
    }

    .e-brochure .enquiry-form {
        position: unset;
        padding: 0% 4%;
    }

    .e-brochure .enquiry-form input {
        border: 1px solid #ccc;
        width: 100%;
    }

    .e-brochure .enquiry-form select {
        width: 80%;
        border: 1px solid;
        margin: 2% 8%;
    }



    .e-brochure .enquiry-form .black-border-input input {
        width: 163px;
        padding: 2px;
        margin-left: 5px;
    }

    .e-brochure .enquiry-form .black-border-input {
        border: 1px solid #ccc;
        width: 100%;
        margin-top: 5%;
    }

    .e-brochure .enquiry-form .resend-otp {
        color: #000;
    }

    .e-brochure .enquiry-form .send-otp-text .resend-otp {
        right: 41px;
    }

    .e-brochure .error-msg {
        margin-left: 8%;
    }
}

@media(min-width:321px) and (max-width:375px) {
    .e-brochure .brochure-banner-img {
        display: none;
    }

    .e-brochure .enquiry-form {
        position: unset;
        padding: 0% 4%;
    }

    .e-brochure .enquiry-form input {
        border: 1px solid #ccc;
        width: 100%;
    }

    .e-brochure .enquiry-form .black-border-input input {
        width: 175px;
        padding: 2px;
    }

    .e-brochure .enquiry-form .black-border-input {
        border: 1px solid #ccc;
        width: 100%;
        margin-top: 5%;
    }



    .e-brochure .enquiry-form .resend-otp {
        color: #000;
    }

    .e-brochure .enquiry-form .send-otp-text .resend-otp {
        right: 61px;
    }

    .e-brochure .error-msg {
        margin-left: 8%;
    }
}

@media(min-width:376px) and (max-width:485px) {
    .e-brochure .enquiry-form .black-border-input {
        border: 1px solid #ccc;
        width: 100%;
        margin-top: 5%;
    }

    .e-brochure .brochure-banner-img {
        display: none;
    }

    .e-brochure .enquiry-form {
        position: unset;
        padding: 0% 4%;
    }

    .e-brochure .enquiry-form input {
        border: 1px solid #ccc;
        width: 100%;
    }

    .e-brochure .enquiry-form select {
        width: 80%;
        border: 1px solid;
        margin: 2% 8%;
    }

    .e-brochure .enquiry-form .black-border-input input {
        width: 206px;
        padding: 2px;
    }


    .e-brochure .enquiry-form .resend-otp {
        color: #000;
    }

    .e-brochure .enquiry-form .send-otp-text .resend-otp {
        right: 61px;
    }

    .e-brochure .error-msg {
        margin-left: 8%;
    }
}

@media(min-width:486px) and (max-width:576px) {
    .e-brochure .brochure-banner-img {
        display: none;
    }

    .e-brochure .enquiry-form {
        position: unset;
        padding: 0% 4%;
    }

    .e-brochure .enquiry-form input {
        border: 1px solid #ccc;
        width: 100%;
    }

    .e-brochure .enquiry-form select {
        width: 80%;
        border: 1px solid;
        margin: 2% 8%;
    }

    .e-brochure .enquiry-form .black-border-input input {
        width: 234px;
        margin: 0px !important;
    }

    .e-brochure .enquiry-form .black-border-input {
        border: 1px solid #ccc;
        width: 100%;
        margin-top: 5%;
    }

    .e-brochure .enquiry-form .resend-otp {
        color: #000;
    }

    .e-brochure .enquiry-form .send-otp-text .resend-otp {
        right: 72px;
    }

    .e-brochure .error-msg {
        margin-left: 8%;
    }
}

@media(min-width:577px) and (max-width:768px) {
    .e-brochure .brochure-banner-img {
        display: none;
    }

    .e-brochure .enquiry-form {
        position: unset;
        padding: 0% 4%;
    }

    .e-brochure .enquiry-form input {
        border: 1px solid #ccc;
        width: 100%;
    }

    .e-brochure .enquiry-form select {
        width: 80%;
        border: 1px solid;
        margin: 2% 8%;
    }

    .e-brochure .enquiry-form .black-border-input input {
        width: 71%;
        height: 17px;
    }

    .e-brochure .enquiry-form .black-border-input {
        border: 1px solid #ccc;
        width: 100%;
        margin-top: 5%;
    }

    .e-brochure .enquiry-form .resend-otp {
        color: #000;
    }

    .e-brochure .enquiry-form .send-otp-text .resend-otp {
        right: 122px;
    }

    /* .e-brochure .enquiry-form .black-border-input input {
        width: 78%;
        padding: 2px;
        height: 16px;
        margin-left: 1%;
    } */

    .e-brochure .error-msg {
        margin-left: 8%;
    }

}

@media(min-width:769px) and (max-width:992px) {
    .e-brochure .brochure-banner-img {
        display: none;
    }

    .e-brochure .enquiry-form {
        position: unset;
    }


    .e-brochure .enquiry-form select {
        width: 80%;
        border: 1px solid;
        margin: 2% 8%;
    }


    .e-brochure .enquiry-form .black-border-input input {
        width: 82%;
        padding: 2px;
        height: 16px;
        margin-left: 1%;
    }

    .e-brochure .enquiry-form .black-border-input {
        border: 1px solid;
        width: 80%;
        margin-left: 8%;
        height: 46px;
    }

    .e-brochure .enquiry-form .resend-otp {
        color: #000;
    }

    .e-brochure .enquiry-form .send-otp-text .resend-otp {
        right: 122px;
    }

    .e-brochure .error-msg {
        margin-left: 8%;
    }

}

@media(min-width:0px) and (max-width:991px) {
    .e-brochure .text-holder-brochure h2 {
        font-size: 20px;
    }

    .e-brochure .text-holder-brochure p {
        font-size: 14px;
    }

    .e-brochure .enquiry-form .apply-online-title h2 {
        color: #000;
        text-align: center;
        font-size: 20px;
    }

    .e-brochure .enquiry-form .submit-btn-enquiry-form {
        font-size: 14px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .e-brochure .enquiry-form {
        position: absolute;
        top: 23%;
        left: 61%;
    }

    .e-brochure .text-holder-brochure h2 {
        font-size: 25px;
    }

    .e-brochure .brochure-banner-img {
        aspect-ratio: 3/2;
    }
}

@media(min-width:1201px) and (max-width:1440px) {
    .e-brochure .enquiry-form {
        position: absolute;
        top: 23%;
        left: 61%;
    }

    .e-brochure .text-holder-brochure h2 {
        font-size: 25px;
    }

    .e-brochure .brochure-banner-img {
        aspect-ratio: 4/2;
    }
}