.Madiscover-power {
    margin: 6% 0;
}

.Madiscover-power .heading-holder .title {
    font-size: 32px;
    font-weight: bold;

}

.Madiscover-power .heading-holder p {
    font-size: 16px;
}

.Madiscover-power .swiper-pagination-bullet {
    width: 14px;
    height: 5px;
    border-radius: 5px;
    opacity: 1;
    background-color: #CECECE;
}

.Madiscover-power .swiper-pagination-bullet-active {
    width: 25px;
    background: #000;
}

.Madiscover-power .Diffrent-Card {
    border: 0;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    /* box-shadow: 0px 3px 6px #00000029; */
    border-radius: 15px;
    margin-top: -61px;
    margin-left: 10px;
    margin-right: 17px;
}

.Madiscover-power .card-title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2;
}

.Madiscover-power .card-wrapper {
    border-radius: 6px;
    overflow: hidden;
    transition: max-height 500ms ease-in-out;
    max-height: 0px;
}

.Madiscover-power .Diffrent-Card:hover .card-wrapper {
    cursor: pointer;
    max-height: 300px;

}

.Madiscover-power .Diffrent-Card:hover {
    transition-delay: 0.4s;
    box-shadow: 0px 3px 6px #00000029;
}

.Madiscover-power .Diffrent-Card:hover .card-title {
    color: #FF8600;
}

.Madiscover-power .main-img-class {
    position: relative;
}

.Madiscover-power .main-img-class .diffrent-img {
    /* width: 296px; */
    width: 100%;
    height: 192px;
    border: 0.5px solid #FF8600BF;
    border-radius: 15px;
}

.Madiscover-power .differencecardText p {
    margin-top: 10px;
    font-size: 14px;
    color: #383838;
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5;
    line-clamp: 5;
}

@media(min-width:0px) and (max-width:567px) {
    .Madiscover-power .heading-holder .title {
        font-size: 20px;
    }

    .Madiscover-power .card-title {
        font-size: 15px;
    }

    .Madiscover-power .differencecardText p {
        font-size: 12px;
    }

    .Madiscover-power .Diffrent-Card {
        margin-top: -58px;
    }
}

@media(min-width:567px) and (max-width:767px) {
    .Madiscover-power .heading-holder .title {
        font-size: 20px;
    }

    .Madiscover-power .card-title {
        font-size: 15px;
    }

    .Madiscover-power .differencecardText p {
        font-size: 12px;
    }

    .Madiscover-power .Diffrent-Card {
        margin-top: -58px;
    }
}

@media(min-width:768px) and (max-width:991px) {
    .Madiscover-power .heading-holder .title {
        font-size: 20px;
    }

    .Madiscover-power .card-title {
        font-size: 17px;
    }

    .Madiscover-power .differencecardText p {
        font-size: 13px;
    }

    .Madiscover-power .Diffrent-Card {
        margin-top: -58px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .Madiscover-power .heading-holder .title {
        font-size: 25px;
    }

    .Madiscover-power .card-title {
        font-size: 17px;
    }

    .Madiscover-power .differencecardText p {
        font-size: 13px;
    }
}