.key-program {
    margin: 5% 0;
}

.key-program .heading-holder h5 {
    font-size: 32px;
    font-weight: bold;
    font-family: 'Open Sans';
}

.key-program .heading-holder .border-line {
    border-bottom: 3px solid #FF8600;
    margin: auto;
    width: 170px;
}

.key-program .heading-holder p {
    font-size: 16px;
    margin-bottom: 0%;
}

.key-program .card {
    background: #FFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #00000029;
    border: none;
    border-radius: 0%;
    min-height: 100%;
    height: 250px;
    position: relative;
    overflow: hidden;
}

.key-program .card .image-holder .amity-img {
    width: 51%;
    height: auto;
}

.key-program .card .name-holder h4 {
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    line-clamp: 1;
}

.key-program .card .name-holder {
    position: absolute;
    bottom: 6%;
    left: 0;
    right: 0;
    z-index: 1;
}

/* .key-program .card:hover .name-holder {
    top: 10%;
    animation: scale-up-ver-center 1s ease-in-out;
} */

.key-program .card:hover .name-holder h4 {
    color: #FF8600;
}

.key-program .card:hover .image-holder {
    opacity: 0.1;
}

.key-program .card .text {
    color: black;
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    text-align: justify;
    transition: 0.5s;
    position: absolute;
    top: 0px;
}

.key-program .card .midlle .text {
    opacity: 0;
    /* transition: opacity 0.5s ease, transform 0.5s ease; */
    transform: translateY(-30%);
    transition: transform 0.4s ease-in-out;
}

.key-program .card:hover .midlle .text {
    position: absolute;
    display: block;
    top: 70px;
    transition: opacity 0.5s ease, transform 0.5s ease;
    transform: translateY(0%);
    opacity: 1;
}

.key-program .swiper-pagination-bullet {
    width: 10px;
    height: 6px;
    border-radius: 5px;
    opacity: 1;
    background-color: #CECECE;
}

.key-program .swiper-pagination-bullet-active {
    width: 18px;
    background: #000;
}

.key-program .card .name-holder {
    transition: top 0.5s ease;
    top: 100%;
}

.key-program .card:hover .name-holder {
    top: 10%;
    animation: slide-up 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

@keyframes slide-up {
    0% {
        top: 100%;
    }

    100% {
        top: 10%;
    }
}


.key-program .card:not(:hover) .name-holder {
    animation: slide-down 0.3s ease-in-out forwards;
}

@keyframes slide-down {
    0% {
        top: 10%;

    }

    100% {
        top: 80%;

    }
}




@media(min-width:0px) and (max-width:767px) {
    .key-program .heading-holder h5 {
        font-size: 20px;

    }

    .key-program .heading-holder p {
        font-size: 14px;
    }

    .key-program .card .name-holder h4 {
        font-size: 15px;
    }

    .key-program .card .text {
        font-size: 12px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .key-program .heading-holder h5 {
        font-size: 20px;

    }

    .key-program .heading-holder p {
        font-size: 14px;
    }

    .key-program .card .name-holder h4 {
        font-size: 18px;
    }

    .key-program .card .text {
        font-size: 12px;
    }
}

@media(min-width:991px) and (max-width:1200px) {
    .key-program .heading-holder h5 {
        font-size: 25px;

    }

    .key-program .heading-holder p {
        font-size: 14px;
    }

    .key-program .card .name-holder h4 {
        font-size: 20px;
    }

    .key-program .card .text {
        font-size: 12px;
    }
}