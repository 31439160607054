.programm-featured .title-programm-features h3 {
    font-size: 32px;
    font-weight: bold;
    color: #000000;
}

.programm-featured .title-programm-features p {
    font-size: 16px;
    color: #383838;
}

.programm-featured .title-programm-features h3 span {
    border-bottom: 3px solid #FF8600;
}

.programm-featured .grey-box {
    width: 100px;
    height: 100px;
    background: transparent linear-gradient(134deg, #FFFFFF 0%, #D8D8D8 50%, #FDFDFD 88%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 2px #00000066;
    border-radius: 10px;
    transform: rotate(45deg);
    position: relative;
    margin-left: 22%;
}

.programm-featured .grey-box .features-name {
    transform: rotate(-45deg);
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    position: absolute;
    top: 36%;
    left: 0;
    text-align: center;
}

.programm-featured .grey-box-main:hover .grey-box .features-name {
    font-weight: 700;
}

.programm-featured .grey-box .features-name-problem {
    transform: rotate(-45deg);
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    position: absolute;
    top: 10%;
    left: 10%;
}

.programm-featured .grey-box-main:hover .grey-box .features-name-problem {
    font-weight: 700;
}

.programm-featured .grey-box .features-name-analyses {
    transform: rotate(-45deg);
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    position: absolute;
    top: 37%;
    left: 14%;
}

.programm-featured .grey-box-main:hover .grey-box .features-name-analyses {
    font-weight: 700;
}

.programm-featured .orange-box-bottom-border {
    border-bottom: 5px solid #FF8600;
    margin-top: 9%;
    width: 100px;
    display: block;
    margin-left: 22%;
}

.programm-featured .grey-box-main:hover .orange-box-bottom-border {
    display: none;
}

.programm-featured .grey-box-main .strategising-text {
    display: none;
}

.programm-featured .grey-box-main:hover .strategising-text {
    display: block;
}

.programm-featured .grey-box-main .slide-bottom {
    -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
    }
}

@keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
    }
}


.programm-featured .strategising-text p p {
    font-size: 14px !important;
    color: #383838 !important;
    border: 1px solid #FF8600 !important;
    text-align: center !important;
    margin-top: 7% !important;
    margin-bottom: 0px;
}



/* Media query */

@media(min-width:0px) and (max-width:320px) {
    .programm-featured .grey-box {
        margin-left: 33%;
    }

    .programm-featured .orange-box-bottom-border {
        display: none;
    }

    .programm-featured .title-programm-features h3 {
        font-size: 20px;
    }

    .programm-featured .title-programm-features p {
        font-size: 14px;
    }

    .programm-featured .strategising-text p {
        margin-top: 6%;
    }
    .programm-featured .grey-box-main {
        margin-bottom: 18%;
    }
}

@media(min-width:321px) and (max-width:485px) {
    .programm-featured .grey-box {
        margin-left: 38%;
    }

    .programm-featured .orange-box-bottom-border {
        display: none;
    }

    .programm-featured .title-programm-features h3 {
        font-size: 20px;
    }

    .programm-featured .title-programm-features p {
        font-size: 14px;
    }

    .programm-featured .strategising-text p {
        margin-top: 6%;
    }
    .programm-featured .grey-box-main {
        margin-bottom: 14%;
    }
   
}

@media(min-width:486px) and (max-width:576px) {
    .programm-featured .grey-box {
        margin-left: 39%;
    }

    .programm-featured .orange-box-bottom-border {
        display: none;
    }

    .programm-featured .title-programm-features h3 {
        font-size: 20px;
    }

    .programm-featured .title-programm-features p {
        font-size: 14px;
    }

    .programm-featured .strategising-text p {
        margin-top: 6%;
    }
    .programm-featured .grey-box-main {
        margin-bottom: 10%;
    }
}

@media(min-width:577px) and (max-width:766px) {
    .programm-featured .grey-box {
        margin-left: 39%;
    }

    .programm-featured .orange-box-bottom-border {
        display: none;
    }

    .programm-featured .title-programm-features h3 {
        font-size: 20px;
    }

    .programm-featured .title-programm-features p {
        font-size: 14px;
    }

    .programm-featured .strategising-text p {
        margin-top: 5%;
    }
    .programm-featured .grey-box-main {
        margin-bottom: 9%;
    }
}

@media(min-width:0px) and (max-width:767px) {
    .programm-featured .grey-box .features-name {
        font-size: 16px;
        top: 36%;
        left: 8px;
    }

    .programm-featured .grey-box .features-name-problem {
        font-size: 16px;
        top: 14%;
        left: 17%;
    }
}

@media(min-width:767px) and (max-width:768px) {
    .programm-featured .grey-box {
        width: 75px;
        height: 75px;
        margin-left: 12%;
    }

    .programm-featured .grey-box .features-name {
        font-size: 13px;
    }

    .programm-featured .grey-box .features-name-analyses {
        font-size: 14px;
    }

    .programm-featured .grey-box .features-name-problem {
        font-size: 13px;
    }

    .programm-featured .orange-box-bottom-border {
        border-bottom: 3px solid #FF8600;
        margin-top: 12%;
        margin-left: 0;
    }

    .programm-featured .title-programm-features h3 {
        font-size: 20px;
    }

    .programm-featured .title-programm-features p {
        font-size: 15px;
    }

    .programm-featured .strategising-text p {
        font-size: 13px;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .programm-featured .grey-box {
        width: 75px;
        height: 75px;
        margin-left: 12%;
    }

    .programm-featured .grey-box .features-name {
        font-size: 14px;
    }

    .programm-featured .grey-box .features-name-analyses {
        font-size: 14px;
    }

    .programm-featured .grey-box .features-name-problem {
        font-size: 14px;
    }

    .programm-featured .orange-box-bottom-border {
        border-bottom: 3px solid #FF8600;
        margin-top: 12%;
        margin-left: 0;
    }

    .programm-featured .title-programm-features h3 {
        font-size: 20px;
    }

    .programm-featured .title-programm-features p {
        font-size: 15px;
    }

    .programm-featured .strategising-text p {
        font-size: 13px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .programm-featured .grey-box {
        width: 75px;
        height: 75px;
        margin-left: 12%;
    }

    .programm-featured .grey-box .features-name {
        font-size: 14px;
    }

    .programm-featured .grey-box .features-name-analyses {
        font-size: 14px;
    }

    .programm-featured .grey-box .features-name-problem {
        font-size: 14px;
    }

    .programm-featured .orange-box-bottom-border {
        border-bottom: 3px solid #FF8600;
        margin-top: 12%;
        margin-left: 0;
    }

    .programm-featured .title-programm-features h3 {
        font-size: 25px;
    }

    .programm-featured .title-programm-features p {
        font-size: 15px;
    }

    .programm-featured .strategising-text p {
        font-size: 13px;
        margin-left: -18%
    }
    
}