.university-slider-section {
    margin: 0% 0% 5% 0%;
}

.university-slider-section .cont-div .uni-p {
    font-weight: 500;
    font-size: 18px;
    color: #ff8600;
    margin-bottom: 0px;
}

.university-slider-section .cont-div .uni-title {
    font-weight: bold;
    font-size: 32px;
    color: #000;
}

.university-slider-section .cont-div .uni-cont {
    font-size: 16px;
    color: #383838;
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.university-slider-section .slider-div .uni-slider {
    width: 100%;
    height: 230px;
    background: #fff;
    box-shadow: 0px 0px 24px 0px #f1f1f1;
    padding: 15px;
}

.university-slider-section .slider-div .uni-slider::after {
    content: "";
    border-bottom: 4px solid #FF8600;
    bottom: 30px;
    position: absolute;
    left: 8px;
    width: 0;
    padding: 0px;
    transition: 0.2s ease;
}

.main-card-div:hover .uni-slider::after {
    width: 96%;
    transition: 0.2s ease;
}

.university-slider-section .slider-div .swiper-slide {
    padding: 10px 10px 35px 10px;
}

.university-slider-section .slider-div .swiper-slide .logo-div {
    width: 100%;
    height: 90px;
    text-align: center;
}

.university-slider-section .slider-div .swiper-slide .logo-div .uni-img {
    width: auto;
    height: 100%;
    object-fit: contain;
    aspect-ratio: 3/1;
}

.university-slider-section .slider-div .swiper-slide .slide-content {
    text-align: center;
    margin-top: 25px;
}

.university-slider-section .slider-div .swiper-slide .slide-content .name {
    font-weight: bold;
    font-size: 18px;
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

}

.university-slider-section .slider-div .swiper-slide .slide-content .discri {
    font-weight: normal;
    font-size: 14px;
    color: #383838;
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.university-slider-section .slider-div .swiper-pagination-clickable .swiper-pagination-bullet-active {
    background: #000;
    width: 20px !important;
    height: 4px;
    border-radius: 5px;
    margin: 2px !important;
}

.university-slider-section .slider-div .swiper-pagination-clickable .swiper-pagination-bullet {
    background: #000;
    width: 10px;
    height: 4px;
    border-radius: 5px;
    margin: 2px !important;
}

.university-slider-section .slider-div .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -5px;
}

@media(min-width:0px) and (max-width:767px) {
    .university-slider-section .cont-div .uni-p {
        font-size: 12px;
    }
}

@media (min-width:0px) and (max-width:768px) {
    .university-slider-section .slider-div .uni-slider {
        width: 100%;
        margin: auto;
        height: auto;
        padding: 15px;
    }



    /* .university-slider-section .slider-div .swiper-slide .logo-div .uni-img {
        width: 70%;
        height: 50px;
    }

    .university-slider-section .slider-div .swiper-slide .logo-div {
        height: 50px;
    } */

    .university-slider-section .slider-div .swiper-slide .slide-content {
        margin-top: 15px;
    }

    .university-slider-section .slider-div .swiper-slide .slide-content .name {
        font-size: 16px;
    }

    .university-slider-section .slider-div .swiper-slide .slide-content .discri {
        font-size: 13px;
    }

    .university-slider-section .cont-div {
        text-align: center;
        margin-top: 20px;
    }

    .university-slider-section .cont-div .uni-title {
        font-size: 20px;
    }

    .university-slider-section .cont-div .uni-cont {
        font-size: 14px;
    }

}




@media (min-width:769px) and (max-width:1200px) {
    .university-slider-section .cont-div .uni-p {
        font-size: 14px;
    }

    .university-slider-section .cont-div .uni-title {
        font-size: 25px;
    }

    .university-slider-section .cont-div .uni-cont {
        font-size: 15px;
    }

}

@media (min-width:992px) and (max-width:1200px) {
    .main-card-div:hover .uni-slider::after {
        width: 94%;
    }
}