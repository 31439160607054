.online-learning {
    margin: 0 0 5% 0;
}

.online-learning .heading-holder h5 {
    color: #FF8600;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
}

.online-learning .heading-holder .title {
    font-size: 32px;
    font-weight: bold;
    margin: auto;
    margin-bottom: 15px;
}

.online-learning .heading-holder p {
    color: #383838;
    font-size: 16px;
    margin: auto;
}


.online-learning .learning-heading {
    position: relative;
}

.online-learning .swiper-pagination-bullet-active {
    background: #ee9723;
}

.online-learning .swiper-button-next:after,
.online-learning .swiper-button-prev:after {
    font-size: 25px;
    filter: invert(1);
    display: none;
}

.online-learning .learning-heading .back_img {
    height: 329px;
}

.online-learning .learning-heading .text-overlay {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.online-learning .learning-heading .text-overlay .title {
    font-size: 24px;
    font-weight: bold;
    color: #FF8600;
    /* display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    line-clamp: 1; */
}

.online-learning .learning-heading .text-overlay p {
    font-size: 16px;
    max-width: 256px;
    color: #000;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5;
    line-clamp: 5;
}

.online-learning .lepi-image {
    width: 673px;
    height: 422px;
    object-fit: cover;
}

.online-learning .slider-started .laptop-image {
    position: absolute;
    top: 50px;
    left: 32%;
    z-index: 1;
}



.online-learning .online-learning-slider .online-girl {
    width: 100%;
}

.online-learning .online-learning-slider {
    margin-top: 40px;
}

@media (min-width: 0px) and (max-width: 767px) {
    .online-learning .heading-holder .title {
        font-size: 20px;
    }

    .online-learning .heading-holder p {
        font-size: 14px;
    }

    .online-learning .heading-holder h5 {
        font-size: 13px;

    }



}

@media(min-width:0px) and (max-width:359px) {
    .online-learning .main-slider .arrow-back {
        content: url("/public/assets/images/online-learning/prev1.png");
        position: absolute;
        top: 43%;
        left: 3%;
        z-index: 2;
        cursor: pointer;
    }


    .online-learning .main-slider .arrow-next {
        content: url("/public/assets/images/online-learning/next1.png");
        position: absolute;
        top: 43%;
        right: 3%;
        z-index: 2;
        cursor: pointer;
    }

    /* .online-learning .learning-heading .text-overlay {
        top: 53%;
        left: 36px
    } */

    /* 
    .online-learning .swiper-slide.swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-next {
        margin-left: -114px;
    }

    .online-learning .lepi-image {
        width: 314px;
        height: 467px;
    }

    .online-learning .swiper-slide img {
        display: block;
        width: 260px;
        height: 349px;
        margin: auto;
    } */

    .online-learning .slider-started .laptop-image {
        top: -47px;
        left: 0%;
    }

    .online-learning .learning-heading .text-overlay .title {
        font-size: 13px;
    }

    .online-learning .learning-heading .text-overlay p {
        font-size: 11px;
        max-width: 155px;
    }

    /* .online-learning .swiper-pagination {
        bottom: 67px !important;
    }

    .online-learning .swiper {
        width: 100%;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .online-learning .heading-holder .title {
      

    } */

    .online-learning .heading-holder p {
        font-size: 13px;
        max-width: 466px;

    }

    /* .online-learning .swiper-backface-hidden .swiper-slide {
        transform: translateZ(0);
        backface-visibility: hidden;
        margin-left: 80px;
    } */



}

@media(min-width:360px) and (max-width:420px) {
    .online-learning .main-slider .arrow-back {
        content: url("/public/assets/images/online-learning/prev1.png");
        top: 43%;
        left: 6%;
        z-index: 2;
    }


    .online-learning .main-slider .arrow-next {
        content: url("/public/assets/images/online-learning/next1.png");
        top: 43%;
        right: 20px;
        z-index: 2;
    }

    /* .online-learning .learning-heading .text-overlay {
        top: 53%;
        left: 58px
    } */

    /* .online-learning .swiper-slide.swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-next {
        margin-left: -114px;
    }

    .online-learning .lepi-image {
        width: 344px;
        height: 467px;
    }

    .online-learning .swiper-slide img {
        display: block;
        width: 270px;
        height: 349px;

        margin: auto;
    } */

    .online-learning .slider-started .laptop-image {
        top: -47px;
        left: 4%;
    }

    .online-learning .learning-heading .text-overlay .title {
        font-size: 14px;
    }

    .online-learning .learning-heading .text-overlay p {
        font-size: 12px;
        max-width: 155px;
    }

    /* 
    .online-learning .swiper-pagination {
        bottom: 67px !important;
    }

    .online-learning .swiper {
        width: 100%;
        padding-top: 0px;
        padding-bottom: 0px;
    } */

    .online-learning .heading-holder p {
        font-size: 13px;
        max-width: 466px;

    }

    /* 
    .online-learning .swiper-backface-hidden .swiper-slide {
        transform: translateZ(0);
        backface-visibility: hidden;
        margin-left: 80px;
    } */

}

@media(min-width:420px) and (max-width:567px) {
    .online-learning .main-slider .arrow-back {
        content: url("/public/assets/images/online-learning/prev1.png");
        top: 43%;
        left: 8px;
        z-index: 2;
    }


    .online-learning .main-slider .arrow-next {
        content: url("/public/assets/images/online-learning/next1.png");
        top: 43%;
        right: 8px;
        z-index: 2;
    }

    /* .online-learning .learning-heading .text-overlay {
        top: 53%;
        left: 85px
    } */

    /* .online-learning .swiper-slide.swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-next {
        margin-left: -114px;
    } */

    .online-learning .lepi-image {
        width: 100%;
        height: 467px;
    }

    /* .online-learning .swiper-slide img {
        display: block;
        width: 270px;
        height: 349px;
        margin: auto;
    } */

    .online-learning .slider-started .laptop-image {
        top: -41px;
        left: 0%;
    }

    /* 
    .online-learning .swiper {
        width: 100%;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .online-learning .swiper-pagination {
        bottom: 67px !important;
    } */

    .online-learning .learning-heading .text-overlay .title {
        font-size: 14px;
    }

    .online-learning .learning-heading .text-overlay p {
        font-size: 12px;
        max-width: 155px;
    }

    /* .online-learning .swiper-pagination {
        bottom: 67px !important;
    } */

    .online-learning .heading-holder p {
        font-size: 13px;
        max-width: 466px;

    }

    /* .online-learning .swiper-backface-hidden .swiper-slide {
        transform: translateZ(0);
        backface-visibility: hidden;
        margin-left: 80px;
    } */


}

@media(min-width:500px) and (max-width:570px) {
    /* .online-learning .slider-started .laptop-image {
        left: 19% !important;
    } */

    .online-learning .learning-heading .text-overlay {

        left: 147px !important;
    }
}

@media(min-width:567px) and (max-width:676px) {
    /* .online-learning .main-slider .arrow-back {
        content: url("/public/assets/images/online-learning/prev1.png");
        top: 43%;
        left: 29%;
        z-index: 2;
    }


    .online-learning .main-slider .arrow-next {
        content: url("/public/assets/images/online-learning/next1.png");
        top: 43%;
        right: 25%;
        z-index: 2;
    } */

    /* .online-learning .learning-heading .text-overlay {
        top: 53%;
        left: 11px;
    } */

    /* .online-learning .swiper-slide.swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-next {
        padding-left: 13px;
    } */

    .online-learning .lepi-image {
        width: 257px;
        height: 465px;
    }

    /* .online-learning .swiper-slide img {
        display: block;
        width: 209px;
        height: 339px;
    } */

    .online-learning .slider-started .laptop-image {
        top: 2px;
        left: 30%;
    }

    .online-learning .learning-heading .text-overlay .title {
        font-size: 12px;
    }

    .online-learning .learning-heading .text-overlay p {
        font-size: 10px;
        max-width: 145px;
    }

    /* .online-learning .swiper-pagination {
        bottom: 110px !important;
    } */
}

@media(min-width:677px) and (max-width:767px) {
    .online-learning .main-slider .arrow-next {
        right: 29%;
    }

    .online-learning .main-slider .arrow-back {
        left: 27%;
    }


    /* .online-learning .learning-heading .text-overlay {
        top: 53%;
        left: 24px;
    } */

    /* .online-learning .swiper-slide.swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-next {
        padding-left: 13px;
    } */

    .online-learning .lepi-image {
        width: 328px;
        height: 465px;
    }

    /* 
    .online-learning .swiper-slide img {
        display: block;
        width: 253px;
        height: 339px;
    } */

    .online-learning .slider-started .laptop-image {
        top: 2px;
        left: 27%;
    }

    .online-learning .learning-heading .text-overlay .title {
        font-size: 14px;
    }

    .online-learning .learning-heading .text-overlay p {
        font-size: 12px;
        max-width: 155px;
    }

    /* 
    .online-learning .swiper-pagination {
        bottom: 110px !important;
    } */
}

@media(min-width:768px) and (max-width:991px) {
    .online-learning .main-slider .arrow-next {
        right: 26%;
    }

    .online-learning .main-slider .arrow-back {

        left: 30%;
    }

    .online-learning .heading-holder .title {
        font-size: 20px;
    }

    .online-learning .heading-holder p {
        font-size: 14px;
    }

    /* .online-learning .learning-heading .text-overlay {
        top: 53%;
        left: 24px;
    } */

    /* .online-learning .swiper-slide.swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-next {
        padding-left: 39px;
    }

    .online-learning .lepi-image {
        width: 317px;
        height: 457px;
    }

    .online-learning .swiper-slide img {
        display: block;
        width: 253px;
        height: 339px;
    } */

    /* .online-learning .slider-started .laptop-image {
        top: 8px;
        left: 31%;
    } */

    .online-learning .learning-heading .text-overlay .title {
        font-size: 14px;
    }

    .online-learning .learning-heading .text-overlay p {
        font-size: 12px;
        max-width: 155px;
    }

    /* .online-learning .swiper-pagination {
        bottom: 110px !important;
    } */

    /* .online-learning .main-slider .arrow-back {
        top: 43%;
        left: 32%;
        width: 19px;
    }

    .online-learning .main-slider .arrow-next {
        top: 43%;
        right: 29%;
        width: 19px;
    } */
}

@media(min-width:992px) and (max-width:1199px) {
    .online-learning .main-slider .arrow-next {
        top: 43%;
        right: 272px;
    }

    /* .online-learning .learning-heading .text-overlay {
        top: 53%;
        left: 24px;
    } */

    /* .online-learning .swiper-slide.swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-next {
        padding-left: 39px;
    }

    .online-learning .lepi-image {
        width: 431px;
        height: 460px;
    }

    .online-learning .swiper-slide img {
        display: block;
        width: 343px;
        height: 352px;
    }

    .online-learning .slider-started .laptop-image {
        top: 19px;
        left: 32%;

    } */

    .online-learning .learning-heading .text-overlay .title {
        font-size: 14px;
    }

    .online-learning .learning-heading .text-overlay p {
        font-size: 13px;
        max-width: 198px;
    }

    .online-learning .heading-holder .title {
        font-size: 25px;

    }

    .online-learning .heading-holder p {
        font-size: 14px;

    }

    /* 
    .online-learning .swiper-pagination {
        bottom: 57px !important;
    }

    .online-learning .main-slider .arrow-back {
        top: 43%;
        left: 33%;
        width: 24px;
    }

    .online-learning .main-slider .arrow-next {
        top: 43%;
        right: 27%;
        width: 24px;
    } */
}

@media(min-width:1200px) and (max-width:1357px) {
    .online-learning .main-slider .arrow-next {
        top: 43%;
        right: 21% !important;
    }

    /* .online-learning .learning-heading .text-overlay {
        top: 53%;
        left: 30px;

    } */

    /* .online-learning .swiper-slide.swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-next {
        padding-left: 39px;
    }

    .online-learning .lepi-image {
        width: 594px;
        height: 460px;
    }

    .online-learning .swiper-slide img {
        display: block;
        width: 463px;
        height: 339px;
    }

    .online-learning .slider-started .laptop-image {
        top: 19px;
        left: 32%;

    } */

}

@media(min-width:1200px) and (max-width:1399px) {
    .online-learning .main-slider .arrow-next {
        top: 43%;
        right: 26%;

    }

    /* .online-learning .learning-heading .text-overlay {
        top: 53%;
        left: 30px;

    } */

    /* .online-learning .swiper-slide.swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-next {
        padding-left: 39px;
    }

    .online-learning .lepi-image {
        width: 594px;
        height: 460px;
    }

    .online-learning .swiper-slide img {
        display: block;
        width: 463px;
        height: 339px;
    }

    .online-learning .slider-started .laptop-image {
        top: 19px;
        left: 32%;

    } */
}

@media(min-width:1400px) and (max-width:1500px) {
    /* .online-learning .main-slider .arrow-next {
        top: 43%;
        right: 25%;

    }

    .online-learning .main-slider .arrow-back {
        top: 43%;
        left: 33%;
    } */

    /* .online-learning .learning-heading .text-overlay {
        top: 54%;
        left: 28px;

    } */

    /* .online-learning .swiper-slide.swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-next {
        margin-left: -43px;
    }

    .online-learning .lepi-image {
        width: 628px;
        height: 458px;
    }

    .online-learning .swiper-slide img {
        display: block;
        width: 480px;
        height: 339px;
    } */

    /* .online-learning .slider-started .laptop-image {
        top: 19px;
        left: 460px;

    } */
    .online-learning .learning-heading .text-overlay .title {
        font-size: 18px;
    }

    .online-learning .learning-heading .text-overlay p {
        font-size: 14px;
    }
}

@media(min-width:1501px) and (max-width:1599px) {
    .online-learning .main-slider .arrow-next {
        top: 43%;
        right: 26%;
    }

    /* .online-learning .learning-heading .text-overlay {
        top: 54%;
        left: 28px;

    } */

    /* .online-learning .swiper-slide.swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-next {
        margin-left: -43px;
    }

    .online-learning .lepi-image {
        width: 628px;
        height: 458px;
    }

    .online-learning .swiper-slide img {
        display: block;
        width: 480px;
        height: 339px;
    }

    .online-learning .slider-started .laptop-image {
        top: 19px;
        left: 497px;

    } */
}

@media(min-width:1600px) and (max-width:1800px) {
    /* .online-learning .main-slider .arrow-next {
        top: 43%;
        right: 27%;

    }

    .online-learning .learning-heading .text-overlay {
        top: 54%;
        left: 55px;

    }

    .online-learning .swiper-slide.swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-next {
        margin-left: -69px;
    } */
}

@media(min-width:1801px) and (max-width:2000px) {
    /* .online-learning .main-slider .arrow-next {
        top: 43%;
        right: 33%;

    }

    .online-learning .main-slider .arrow-back {
        top: 43%;
        left: 33%;
        width: 24px;
    } */
}

@media(min-width:2300px) and (max-width:2560px) {
    /* .online-learning .slider-started .laptop-image {
        left: 37%;

    }

    .online-learning .main-slider .arrow-next {
        right: 33%;
    }

    .online-learning .main-slider .arrow-back {
        top: 43%;
        left: 33%;
    }

    .online-learning .swiper-slide.swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-next {
        transform: translate3d(0px, 0px, -13.4779px) rotateX(0deg) rotateY(0deg) scale(1) !important;
        z-index: 1;
        margin-left: 0px;
    } */
}