.upgrade-section .ugrade-image {
    width: 100%;
    height: 354px;
}

.upgrade-section {
    height:auto;
    padding: 50px 0px;
    background: #FFF8F0 0% 0% no-repeat padding-box;
    opacity: 1;
}

.upgrade-section .upgrade-contents h2 {
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    padding-bottom: 20px;
}
.upgrade-section .upgrade-contents p {
    margin-bottom: 0%;
    text-align: left;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 8;
}

@media(min-width:0px) and (max-width:320px){
    .upgrade-section .upgrade-contents h2 {
        font-size: 19px;
      text-align: center;
      padding-bottom: 10px;
    }
    .upgrade-section .ugrade-image {
       
        height: 250px;
    }
    .upgrade-section .upgrade-contents p {
        text-align: center;
        font-size: 12px;
        line-height: 20px;
    }
}

@media(min-width:321px) and (max-width:485px){
    .upgrade-section .upgrade-contents h2 {
        font-size: 19px;
      text-align: center;
      padding-bottom: 10px;
    }
    .upgrade-section .ugrade-image {
       
        height: 260px;
    }
    .upgrade-section .upgrade-contents p {
        text-align: center;
        font-size: 12px;
        line-height: 20px;
    }
}

@media(min-width:486px) and (max-width:575px){
    .upgrade-section .upgrade-contents h2 {
        font-size: 23px;
      text-align: center;
      padding-bottom: 10px;
    }
    .upgrade-section .ugrade-image {
       
        height: 295px;
    }
    .upgrade-section .upgrade-contents p {
        text-align: center;
        font-size: 13px;
        line-height: 20px;
    }
}

@media(min-width:576px) and (max-width:767px){
    .upgrade-section  .upgrade-contents h2 {
        font-size: 25px;
        text-align: center;
        padding-bottom: 10px;
    }
    .upgrade-section  .ugrade-image {
       
        height: 330px;
    }
    .upgrade-section .upgrade-contents p {
        text-align: center;
        font-size: 15px;
        line-height: 20px;
    }
}

@media(min-width:768px) and (max-width:991px){
    .upgrade-section  .upgrade-contents h2 {
        font-size: 19px;
      padding-bottom: 15px;

    }
    .upgrade-section  .ugrade-image {
       
        height: 250px;
    }
    .upgrade-section .upgrade-contents p {
      
        font-size: 12px;
        line-height: 20px;
    }
}
@media(min-width:992px) and (max-width:1200px){
    .upgrade-section  .upgrade-contents h2 {
        font-size: 22px;
      
      padding-bottom: 17px;

    }
    .upgrade-section .ugrade-image {
       
        height: 280px;
    }
    .upgrade-section .upgrade-contents p {
       
        font-size: 14px;
        line-height: 20px;
    }
}
