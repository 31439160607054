.program-sec
{
    margin: 50px 0px;
}
.program-sec h1
{
font-size: 25px;
color: #ff8600;
font-weight: bold;
}
.program-sec .side-img
{
width: 100%;
height: 350px;
object-fit: cover;
}
.program-sec .content h3
{
font-size: 20px;
font-weight: bold;
color: #000;
}
.program-sec .content p
{ 
font-size: 18px;
font-weight: 400;
color: #000;
}
.program-sec .career li
{
position: relative;
font-size: 18px;
}
@media(min-width:0px) and (max-width:768px)
{
    .program-sec h1 {
        font-size: 20px;
    }
    .program-sec .content h3 {
        font-size: 18px;
    }
    .program-sec .content p {
        font-size: 14px;
    }
    .program-sec .career li {
        font-size: 15px;
    }
}