.onlineoffline-section {
    margin: 100px 0;
}

.onlineoffline-section .off-on-main .off-on-contents {

    text-align: center;
}

.onlineoffline-section .off-on-main .off-on-contents h2 {
    font: normal normal bold 32px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    padding-bottom: 10px;
}

.onlineoffline-section .cardmain-div {
    margin-bottom: 7%;
}

.onlineoffline-section .off-on-main .off-on-contents p {
    font: normal normal normal 16px Roboto;
    line-height: 21px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 6;
}

.onlineoffline-section .off-on-main .nav-item {
    margin: 100px 20px;
}

.onlineoffline-section .off-on-main .nav-link {
    display: flex;
    align-items: center;
    width: 295px;
    height: 87px;
    font: normal normal normal 24px Roboto;
    letter-spacing: 0px;
    color: #000000;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #00000029;
    opacity: 1;
    justify-content: space-around;
    border-radius: 2px;
}

.onlineoffline-section .off-on-main .nav-link:hover {
    background: #FF860059 0% 0% no-repeat padding-box;

}

.onlineoffline-section .off-on-main .nav-link.active {
    color: #000000;
    background: #FF860059 0% 0% no-repeat padding-box;
}

.onlineoffline-section .off-on-main .button1 {
    width: 70px;
    height: 70px;
    background: #000000B2 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 5px #00000029;
    opacity: 1;
    border-radius: 50%;
}

.onlineoffline-section .off-on-main .buttonspan {
    font: normal normal bold 32px Roboto;
    letter-spacing: 0px;
    color: #000000;
}

.onlineoffline-section .off-on-main .button1 .button1-logo {
    width: 49px;
    margin-top: 15%;
    height: 50px;
    opacity: 1;
    justify-content: space-between;
}

.onlineoffline-section .mainconts .conts .conts-heading {
    text-align: center;
    padding-bottom: 50px;
}

.onlineoffline-section .mainconts .conts .conts-heading h3 {
    font: normal normal bold 32px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.onlineoffline-section .mainconts .card {
    min-height: 200px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border: none;
}

.onlineoffline-section .mainconts .card-body {
    margin: 0px;
    padding: 0px;
}

.onlineoffline-section .mainconts .card-title {
    text-align: center;
    height: 55px;
    padding-top: 15px;
    background: #000000ad 0% 0% no-repeat padding-box;
    /* opacity: 0.67; */
}

.onlineoffline-section .mainconts .card:hover .card-title {
    position: relative;
    background: #ff86009c 0% 0% no-repeat padding-box;
    /* opacity: 0.67; */
    display: flex;
    justify-content: center;
    transition: transform 300ms ease-in-out;
}

.onlineoffline-section .mainconts .cardmain-div .card:hover h5 {
    color: #000;
}

.onlineoffline-section .mainconts .cardmain-div .card h5:after {
    display: block;
    content: '';
    border-bottom: solid 2px #383838bd;
    transform: scaleX(0);
    transition: transform 0.5s ease-in-out;
}

.onlineoffline-section .mainconts .cardmain-div .card:hover h5:after {
    transform: scaleX(1);
    transition: transform 300ms ease-in-out;
}

.onlineoffline-section .mainconts .cardmain-div .card-title h5 {
    font: normal normal bold 18px Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

.onlineoffline-section .mainconts .cardmain-div .card-text {
    padding: 3px 10px;
}

.onlineoffline-section .mainconts .cardmain-div .card-text p {
    text-align: center;
    font: normal normal normal 14px Roboto;
    line-height: 21px;
    letter-spacing: 0px;
    color: #000000;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5;
}

@media(min-width:0px) and (max-width:320px) {
    .onlineoffline-section {
        margin: 25px 0;
    }

    .onlineoffline-section .off-on-main .off-on-contents h2 {
        font-size: 14px;

        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        padding-bottom: 10px;
    }

    .onlineoffline-section .off-on-main .off-on-contents p {
        font-size: 11px;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
    }

    .onlineoffline-section .off-on-main .nav-item {
        margin: 5px 4px;
    }

    .onlineoffline-section .off-on-main .nav-link {
        width: 169px;
        height: 55px;
        font-size: 13px;
    }

    .onlineoffline-section .off-on-main .buttonspan {
        font-size: 18px;
    }

    .onlineoffline-section .off-on-main .button1 {
        width: 45px;
        height: 45px;
    }

    .onlineoffline-section .off-on-main .button1 .button1-logo {
        width: 22px;
        height: 23px;
        margin-top: 9px;
    }

    .onlineoffline-section .mainconts .conts .conts-heading h3 {
        font-size: 16px;
    }

    .onlineoffline-section .mainconts .card-title {
        height: 40px;
        padding-top: 9px;
    }

    .onlineoffline-section .mainconts .card-title h5 {
        font-size: 16px;
    }

    .onlineoffline-section .mainconts .cardmain-div .card-text p {
        font-size: 12px;
    }

    .onlineoffline-section .mainconts .card {
        min-height: 150px;
    }

    .onlineoffline-section .mainconts .conts .conts-heading {
        text-align: center;
        padding-bottom: 10px;
    }

    .onlineoffline-section .mainconts .cardmain-div .card-title h5 {
        font: normal normal bold 16px Roboto;
    }

}

@media(min-width:321px) and (max-width:485px) {
    .onlineoffline-section {
        margin: 25px 0;
    }

    .onlineoffline-section .off-on-main .off-on-contents h2 {
        font-size: 14px;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        padding-bottom: 10px;
    }

    .onlineoffline-section .off-on-main .off-on-contents p {
        font-size: 11px;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
    }

    .onlineoffline-section .off-on-main .nav-item {
        margin: 5px 4px;
    }

    .onlineoffline-section .off-on-main .nav-link {
        width: 169px;
        height: 55px;
        font-size: 13px;
    }

    .onlineoffline-section .off-on-main .buttonspan {
        font-size: 18px;
    }

    .onlineoffline-section .off-on-main .button1 {
        width: 45px;
        height: 45px;
    }

    .onlineoffline-section .off-on-main .button1 .button1-logo {
        width: 22px;
        height: 23px;
        margin-top: 9px;
    }

    .onlineoffline-section .mainconts .conts .conts-heading h3 {
        font-size: 16px;
    }

    .onlineoffline-section .mainconts .card-title {
        height: 40px;
        padding-top: 9px;
    }

    .onlineoffline-section .mainconts .cardmain-div .card-title h5 {
        font-size: 16px;
    }

    .onlineoffline-section .mainconts .cardmain-div .card-text p {
        font-size: 12px;
    }

    .onlineoffline-section .mainconts .card {
        min-height: 145px;
    }

    .onlineoffline-section .mainconts .conts .conts-heading {
        text-align: center;
        padding-bottom: 10px;
    }


}

@media(min-width:486px) and (max-width:575px) {
    .onlineoffline-section {
        margin: 25px 0;
    }

    .onlineoffline-section .off-on-main .off-on-contents h2 {
        font-size: 18px;
        padding-bottom: 10px;
    }

    .onlineoffline-section .off-on-main .off-on-contents p {
        font-size: 13px;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
    }

    .onlineoffline-section .off-on-main .nav-item {
        margin: 5px 4px;
    }

    .onlineoffline-section .off-on-main .nav-link {
        width: 169px;
        height: 55px;
        font-size: 13px;
    }

    .onlineoffline-section .off-on-main .buttonspan {
        font-size: 18px;
    }

    .onlineoffline-section .off-on-main .button1 {
        width: 45px;
        height: 45px;
    }

    .onlineoffline-section .off-on-main .button1 .button1-logo {
        width: 22px;
        height: 23px;
        margin-top: 9px;
    }

    .onlineoffline-section .mainconts .conts .conts-heading h3 {
        font-size: 18px;
    }

    .onlineoffline-section .mainconts .card-title {
        height: 40px;
        padding-top: 9px;
    }

    .onlineoffline-section .mainconts .cardmain-div .card-title h5 {
        font-size: 16px;
    }

    .onlineoffline-section .mainconts .cardmain-div .card-text p {
        font-size: 13px;
    }

    .onlineoffline-section .mainconts .card {
        min-height: 145px;
    }

    .onlineoffline-section .mainconts .conts .conts-heading {
        text-align: center;
        padding-bottom: 10px;
    }


}

@media(min-width:576px) and (max-width:767px) {
    .onlineoffline-section {
        margin: 25px 0;
    }

    .onlineoffline-section .off-on-main .off-on-contents h2 {
        font-size: 21px;
        padding-bottom: 10px;
    }

    .onlineoffline-section .off-on-main .off-on-contents p {
        font-size: 14px;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
    }

    .onlineoffline-section .off-on-main .nav-item {
        margin: 5px 4px;
    }

    .onlineoffline-section .off-on-main .nav-link {
        width: 169px;
        height: 55px;
        font-size: 13px;
    }

    .onlineoffline-section .off-on-main .buttonspan {
        font-size: 18px;
    }

    .onlineoffline-section .off-on-main .button1 {
        width: 45px;
        height: 45px;
    }

    .onlineoffline-section .off-on-main .button1 .button1-logo {
        width: 22px;
        height: 23px;
        margin-top: 9px;
    }

    .onlineoffline-section .mainconts .conts .conts-heading h3 {
        font-size: 20px;
    }

    .onlineoffline-section .mainconts .card-title {
        height: 40px;
        padding-top: 9px;
    }

    .onlineoffline-section .mainconts .cardmain-div .card-title h5 {
        font-size: 17px;
    }

    .onlineoffline-section .mainconts .cardmain-div .card-text p {
        font-size: 14px;
    }

    .onlineoffline-section .mainconts .card {
        min-height: 155px;
    }

    .onlineoffline-section .mainconts .conts .conts-heading {
        text-align: center;
        padding-bottom: 10px;
    }

}

@media(min-width:768px) and (max-width:991px) {
    .onlineoffline-section {
        margin: 25px 0;
    }

    .onlineoffline-section .off-on-main .off-on-contents h2 {
        font-size: 21px;
        padding-bottom: 10px;
    }

    .onlineoffline-section .off-on-main .off-on-contents p {
        font-size: 14px;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
    }

    .onlineoffline-section .off-on-main .nav-item {
        margin: 5px 4px;
    }

    .onlineoffline-section .off-on-main .nav-link {
        width: 169px;
        height: 55px;
        font-size: 13px;
    }

    .onlineoffline-section .off-on-main .buttonspan {
        font-size: 18px;
    }

    .onlineoffline-section .off-on-main .button1 {
        width: 45px;
        height: 45px;
    }

    .onlineoffline-section .off-on-main .button1 .button1-logo {
        width: 22px;
        height: 23px;
        margin-top: 9px;
    }

    .onlineoffline-section .mainconts .conts .conts-heading h3 {
        font-size: 20px;
    }

    .onlineoffline-section .mainconts .card-title {
        height: 40px;
        padding-top: 9px;
    }

    .onlineoffline-section .mainconts .cardmain-div .card-title h5 {
        font-size: 17px;
    }

    .onlineoffline-section .mainconts .cardmain-div .card-text p {
        font-size: 14px;
    }

    .onlineoffline-section .mainconts .card {
        min-height: 155px;
    }

    .onlineoffline-section .mainconts .conts .conts-heading {
        text-align: center;
        padding-bottom: 10px;
    }

}

@media(min-width:992px) and (max-width:1200px) {
    .onlineoffline-section .off-on-main .off-on-contents p {
        font: normal normal normal 14px Roboto;
        line-height: 21px;
    }

    .onlineoffline-section .off-on-main .off-on-contents h2 {
        font: 29px;
    }

    .onlineoffline-section .mainconts .conts .conts-heading h3 {
        font: 27px;
    }
}