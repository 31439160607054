
.head-partner-slider h1 {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 30px;
}

.head-partner-slider .become-slider img {
    width: 100%;
    height: auto;
    aspect-ratio: 2/1;
}
.head-partner-slider .swiper-wrapper{
    justify-content: center;
} 

@media (min-width:0px) and (max-width:768px) {
    .head-partner-slider h1 {
        font-size: 20px;
    }
}

@media (min-width:769px) and (max-width:991px) {
    .head-partner-slider h1 {
        font-size: 23px;
    }
}

@media (min-width:992px) and (max-width:1200px) {
    .head-partner-slider h1 {
        font-size: 25px;
    }
}