.advantages-admission {
    margin: 4% 0;
}

.advantages-admission .heading-holder h5 {
    font-size: 32px;
    font-weight: bold;
    font-family: 'Open Sans';
}

.advantages-admission .circle-holder {
    background: #FF8600 0% 0% no-repeat padding-box;
    width: 15px;
    height: 15px;
    border-radius: 100px;
    border: 1px solid #000;
    position: relative;

}

.advantages-admission .circle-holder::before {
    content: "";
    display: block;
    width: 100px;
    height: 1px;
    background-color: #707070;
    position: absolute;
    top: 50%;
    z-index: -2;
}

.advantages-admission .vl-line {
    border-left: 1px solid #707070;
    height: 95px;
    margin-left: 6px;

}

.advantages-admission .triangle {
    top: -39px;
    width: 0;
    right: -3px;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 8px solid black;
    position: relative;
}

.advantages-admission .triangle::before {
    content: '';
    border-left: 1px solid #707070;
    position: absolute;
    height: 39px;
    margin-left: -1px;
    top: 8px;
}

.advantages-admission .triangle2 {
    margin-top: 39px;
    margin-right: 45px;
    position: relative;
    width: 0;
    margin-left: 4px;
    height: 0px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid black;
}

.advantages-admission .triangle2::after {
    content: '';
    border-left: 1px solid #707070;
    position: absolute;
    height: 39px;
    margin-left: -1px;
    top: -47px;
    z-index: 1;
    /* bottom: -11px; */
}

.advantages-admission .box-holder {
    position: relative;
}

.advantages-admission .curve-image {
    position: absolute;
    top: -33px;
    left: 98px;
}

.advantages-admission .curve-image .logo-holder {
    position: absolute;
    top: 20px;
    left: 38px;
    z-index: 2;
}

.advantages-admission .curve-image .curve-img {
    width: 116px;
    height: 85px;
}

.advantages-admission .logo-holder .logo-img {
    width: 40px;
    height: 38px;
}

.advantages-admission .heading-holder h6 {
    font-size: 20px;
    font-weight: 600;
    padding-top: 8px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2;

}

.advantages-admission .heading-holder p {
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    text-align: justify;
}

@media(min-width:0px) and (max-width:340px) {
    .advantages-admission .vl-line {
        height: 117px !important;
    }
}

@media(min-width:0px) and (max-width:420px) {
    .advantages-admission .heading-holder h6 {
        font-size: 13px;
        font-weight: 600;
        padding-top: 0px;
    }

    .advantages-admission .heading-holder p {
        font-size: 11px;
        -webkit-line-clamp: 6;
    }

    .advantages-admission .circle-holder::before {
        width: 67px;
    }

    .advantages-admission .curve-image {
        left: 32px;
        top: -26px;
    }

    .advantages-admission .curve-image .curve-img {
        width: 85px;
        height: 65px;
    }

    .advantages-admission .logo-holder .logo-img {
        width: 28px;
        height: 29px;
        margin-left: -3px;
    }

    .advantages-admission .curve-image .logo-holder {
        top: 17px;
        left: 33px;
    }

    .advantages-admission .heading-holder h5 {
        font-size: 20px;
    }

    .advantages-admission .vl-line {
        height: 110px;
    }
}

@media(min-width:421px) and (max-width:466px) {
    .advantages-admission .heading-holder h6 {
        font-size: 13px;
        font-weight: 600;
        padding-top: 0px;
    }

    .advantages-admission .heading-holder p {
        font-size: 11px;
        -webkit-line-clamp: 5;
    }

    .advantages-admission .circle-holder::before {
        width: 67px;
    }

    .advantages-admission .curve-image {
        left: 56px;
        top: -31px;
    }

    .advantages-admission .curve-image .curve-img {
        width: 109px;
        height: 81px;
    }

    .advantages-admission .logo-holder .logo-img {
        width: 37px;
        height: 36px;
    }

    .advantages-admission .curve-image .logo-holder {
        top: 21px;
        left: 36px;

    }

    .advantages-admission .heading-holder h5 {
        font-size: 20px;
    }
}

@media(min-width:467px) and (max-width:577px) {
    .advantages-admission .heading-holder h6 {
        font-size: 13px;
        font-weight: 600;
        padding-top: 6px;
    }

    .advantages-admission .heading-holder p {
        font-size: 11px;
        -webkit-line-clamp: 5;
    }

    .advantages-admission .circle-holder::before {
        width: 67px;
    }

    .advantages-admission .curve-image {
        left: 56px;
        top: -31px;
    }

    .advantages-admission .curve-image .curve-img {
        width: 109px;
        height: 81px;
    }

    .advantages-admission .logo-holder .logo-img {
        width: 37px;
        height: 36px;
    }

    .advantages-admission .curve-image .logo-holder {
        top: 21px;
        left: 36px;

    }

    .advantages-admission .heading-holder h5 {
        font-size: 20px;
    }

    .advantages-admission .vl-line {
        height: 84px;
    }
}

@media(min-width:578px) and (max-width:767px) {
    .advantages-admission .heading-holder h6 {
        font-size: 14px;
        font-weight: 600;
        padding-top: 0px;
    }

    .advantages-admission .heading-holder p {
        font-size: 11px;
        -webkit-line-clamp: 5;
    }

    .advantages-admission .heading-holder h5 {
        font-size: 20px;
    }
}

@media(min-width:768px) and (max-width:991px) {
    .advantages-admission .heading-holder h6 {
        font-size: 16px;
        font-weight: 600;
        padding-top: 2px;
    }

    .advantages-admission .heading-holder p {
        font-size: 13px;
        -webkit-line-clamp: 3;
    }

    .advantages-admission .heading-holder h5 {
        font-size: 20px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .advantages-admission .heading-holder h6 {
        font-size: 17px;
        font-weight: 600;
        padding-top: 6px;
    }

    .advantages-admission .heading-holder h5 {
        font-size: 25px;
    }
}