.conclusion-section
{
    background: #FFF8F0 0% 0% no-repeat padding-box;
opacity: 1;
padding: 3% 0% 8% 0%;
}
.conclusion-section h1.headingss
{
    font-size: 32px;
    font-weight: bold;
    color: #000;
    text-align: center;
    margin-bottom: 15px;
}
.conclusion-section p.conclusion-text {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    
}


@media(min-width:0px) and (max-width:485px)
{
    
    .conclusion-section p.conclusion-text {

        font-size: 12px;
    }
    .conclusion-section h1.headingss {
        font-size: 20px;
    }
}
@media(min-width:486px) and (max-width:575px)
{
    
    .conclusion-section p.conclusion-text {

        font-size: 12px;
    }
    .conclusion-section h1.headingss {
        font-size: 22px;
    }
}
@media(min-width:576px) and (max-width:768px)
{
     
    .conclusion-section p.conclusion-text {
        font-size: 13px;
    }
    .conclusion-section h1.headingss {
        font-size: 23px;
    }
}

@media(min-width:769px) and (max-width:991px)
{
     
    .conclusion-section p.conclusion-text {

        font-size: 14px;
    }
    .conclusion-section h1.headingss {
        font-size: 24px;
    }
}
@media(min-width:992px) and (max-width:1200px)
{
     
   .conclusion-section p.conclusion-text {

        font-size: 14px;
    }
    .conclusion-section h1.headingss{
        font-size: 26px;
    }
}
