.Dy-partner-section {
    background: #FFF8F0 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 2% 0px;
    margin: 3% 0px 5% 0%;
}

.Dy-partner-section .heading {
    text-align: center;
}

.Dy-partner-section .heading h4 {
    font-size: 32px;
    font-weight: bold;
    color: #000;
    margin-bottom: 3px;
}

.Dy-partner-section .heading p {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    margin-top: 10px;
}

.Dy-partner-section .heading .text-border {
    border-bottom: 3px solid #FF8600;
    width: 170px;
    margin: auto;
}

.Dy-partner-section .dy-partner-slider .company-images {
    width: 100%;
}

.Dy-partner-section .dy-partner-slider .images-div {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #FF86004D;
    opacity: 1;
}

.Dy-partner-section .dy-partner-slider .my-swiper {
    padding: 0px 10px;
}

.Dy-partner-section .dy-partner-slider .swiper-pagination-bullet-active {
    background-color: black;
    width: 16px;
    height: 4px;
    border-radius: 5px;
}

.Dy-partner-section .dy-partner-slider .swiper-pagination-clickable .swiper-pagination-bullet {
    height: 4px;
    border-radius: 5px;
    background-color: black;
}

@media (min-width:0px) and (max-width:576px) {

    .Dy-partner-section .heading .text-border {
        width: 135px;
    }

    .Dy-partner-section .heading p {
        font-size: 14px;
    }
}

@media (min-width: 0px) and (max-width: 991px) {
    .Dy-partner-section .heading h4 {
        font-size: 20px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .Dy-partner-section .heading h4 {
        font-size: 25px;
    }
}