.BlogBanner-section {
    margin: 30px 0 5% 0;
}

.BlogBanner-section .blogbanner-image .blog-img {
    width: 100%;    
    height: auto;
    background: no-repeat; 
 }

 .BlogBanner-section .swiper-pagination-bullet-active {
    background: #ff8600;
}