.testimonial-section {
    margin-top: 3%;
}

.testimonial-section .heading {
    text-align: center;
}

.testimonial-section .heading h4 {
    font-size: 32px;
    font-weight: bold;
    color: #000;
    margin-bottom: 3px;
}

.testimonial-section .swiper-slide {
    height: auto !important ;
}

.testimonial-section .heading p {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    margin-top: 10px;
}

.testimonial-section .bvimgsec {
    height: 68vh;
    /* overflow-y: auto */
}

.testimonial-section .heading .text-border {
    border-bottom: 3px solid #FF8600;
    width: 170px;
    margin: auto;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}


::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.testimonial-section .swiper-content .swiper-video-div .videos {
    width: 100%;
    border-radius: 30px;
    margin-top: 3%;
}

.testimonial-section .swiper-content .swiper-video-div {
    margin-bottom: 31px;
    margin-top: 1%;
}

.testimonial-section .swiper-content .swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
    display: none;
}

.testimonial-section .swiper-content .swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    display: none;
}

.testimonial-section .swiper-content .swiper-pagination-bullet-active {
    background-color: black;
    width: 16px;
    height: 4px;
    border-radius: 5px;
}

.testimonial-section .swiper-content .swiper-pagination-clickable .swiper-pagination-bullet {
    height: 4px;
    border-radius: 5px;
}

.testimonial-section .mySwiper-verti {
    width: 100%;
    height: 51%;
}



.testimonial-section .animated-slider {
    display: block;
    width: 100%;
    height: 300px !important;
    object-fit: cover;
}

.testimonial-section .right-swiper-div .bvUniversityImg1 {
    width: 100%;
    height: auto;
}
.testimonial-section .right-swiper-div .swiper-wrapper {
    height: 50vh;
}
.testimonial-section .right-swiper-div .swiper-pagination-bullets {
    display: none;
    }

@media (min-width:0px) and (max-width:576px) {


    .testimonial-section .heading .text-border {
        width: 135px;
    }

    .testimonial-section .heading p {
        font-size: 14px;
    }

    .testimonial-section {
        margin-top: 9%;
    }
    .testimonial-section .right-swiper-div  .swiper-wrapper {
        height: 50vh;
    }
    .testimonial-section .right-swiper-div  .bvUniversityImg1 {
        width: 100%;
        height: 300px;
    }
}
@media (min-width:577px) and (max-width:767px)
{
    .testimonial-section .right-swiper-div  .swiper-wrapper {
        height: 50vh;
    }
    .testimonial-section .right-swiper-div  .bvUniversityImg1 {
        width: 100%;
        height: 300px;
    }
}
@media (min-width:768px) and (max-width:991px)
{
    /* .testimonial-section .right-swiper-div .swiper-wrapper {
        height: 110vh;
    } */
}


@media (min-width:0px) and (max-width:991px) {
    .testimonial-section .heading h4 {
        font-size: 20px;
    }
}

@media (min-width:992px) and (max-width:1200px) {
    .testimonial-section .heading h4 {
        font-size: 25px;
    }
}