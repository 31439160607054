.blog-degree-value-conclusion
{
    background: #FFF8F0 0% 0% no-repeat padding-box;
opacity: 1;
padding: 3% 0% 8% 0%;
}
.blog-degree-value-conclusion h1.heading
{
    font-size: 32px;
    font-weight: bold;
    color: #000;
    text-align: center;
    margin-bottom: 15px;
}
.blog-degree-value-conclusion p.concl-text {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    
}












@media(min-width:0px) and (max-width:575px)
{
    
    .blog-degree-value-conclusion p.concl-text {

        font-size: 13px;
    }
    .blog-degree-value-conclusion h1.heading {
        font-size: 18px;
    }
}
@media(min-width:576px) and (max-width:768px)
{
     
    .blog-degree-value-conclusion p.concl-text {
        font-size: 14px;
    }
    .blog-degree-value-conclusion h1.heading {
        font-size: 24px;
    }
}

@media(min-width:769px) and (max-width:991px)
{
     
    .blog-degree-value-conclusion p.concl-text {

        font-size: 14px;
    }
    .blog-degree-value-conclusion h1.heading {
        font-size: 24px;
    }
}
@media(min-width:992px) and (max-width:1200px)
{
     
   .blog-degree-value-conclusion p.concl-text {

        font-size: 14px;
    }
    .blog-degree-value-conclusion h1.heading{
        font-size: 26px;
    }
}
@media(min-width:1201px) and (max-width:1400px)
{
     
    .blog-degree-value-conclusion p.concl-text {

        font-size: 15px;
    }
    .blog-degree-value-conclusion h1.heading {
        font-size: 30px;
    }
}