.top-cource {
    background: #FFF8F0;
    margin:0% 0% 5% 0%;
}

.top-cource .main-img-class {
    position: relative;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.top-cource .main-img-class .cource-img {
    position: relative;
    background: no-repeat;
    display: block;
    transition: .2s ease;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
}

.top-cource .main-img-class:hover .cource-img {
    transform: scale(1.1);
    cursor: pointer;
    transition: .2s ease;
}

.top-cource .main-img-class .cource-name {
    position: absolute;
    top: 75%;
    left: 5%;
    z-index: 999;
}

.top-cource .main-img-class .cource-name h5 {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 600;
}


.top-cource .main-img-class .black-overlay {
    background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%);
    position: absolute;
    transition: .2s ease;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    /* opacity: 0.75; */
    z-index: 99;
}

.top-cource .main-img-class .black-overlay:hover {
    width: 100%;
    height: 50%;
}

.top-cource .main-img-class:hover .black-overlay {
    width: 100%;
    height: 50%;
}

.top-cource .swiper-pagination-bullet {
    width: 7px;
    height: 4px;
    border-radius: 5px;
    opacity: 1;
    background-color: #CECECE;
}

.top-cource .swiper-pagination-bullet-active {
    width: 18px;
    background: #000;
}

@media(min-width:0px) and (max-width:320px) {
    .top-cource .main-img-class .cource-name h5 {
        font-size: 13px;
    }

    .top-cource .main-img-class .cource-name {
        top: 81%;
    }
}

@media(min-width:321px) and (max-width:485px) {
    .top-cource .main-img-class .cource-name h5 {
        font-size: 17px;
    }

    .top-cource .main-img-class .cource-name {
        top: 81%;
    }

    .top-cource .main-img-class .cource-img {
        height: 300px;
    }
}

@media(min-width:486px) and (max-width:576px) {
    .top-cource .main-img-class .cource-name h5 {
        font-size: 17px;
    }

    .top-cource .main-img-class .cource-name {
        top: 81%;
    }

    .top-cource .main-img-class .cource-img {
        height: 300px;
    }
}

@media(min-width:577px) and (max-width:768px) {
    .top-cource .main-img-class .cource-name h5 {
        font-size: 16px;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .top-cource .main-img-class .cource-name h5 {
        font-size: 16px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .top-cource .main-img-class .cource-name h5 {
        font-size: 16px;
    }
}