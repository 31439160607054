.frequently-question {
    margin: 5% 0;
}

.frequently-question .heading {
    text-align: center;
}

.frequently-question .heading h4 {
    font-size: 32px;
    font-weight: bold;
    color: #000;
    margin-bottom: 3px;
}

.frequently-question .heading .text-border {
    border-bottom: 3px solid #FF8600;
    width: 170px;
    margin: auto;
}

.frequently-question .questions {
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border-radius: 20px;
    padding: 3%;
    margin-top: 3%;
}

.frequently-question .questions .accordion-button:not(.collapsed)::after {
    background-image: url('/public/assets/images/icons/minus-icon.png');
    width: 10px;
    height: 14px;
    background-size: auto;
}

.frequently-question .questions .accordion-button::after {
    background-image: url('/public/assets/images/icons/plus-icon.png');
    width: 14px;
    height: 14px;
    background-size: auto;
}

.frequently-question .questions .accordion-item {
    background: #FFEEDB 0% 0% no-repeat padding-box;
    padding: 5px;
    border: none;
    border-radius: 0%;
}

.frequently-question .questions .accordion-button:not(.collapsed) {
    background: #FFEEDB 0% 0% no-repeat padding-box;
    box-shadow: none;
    border-bottom: 1px solid #FF8600;
    color: #000;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Open Sans', 'sans-serif' !important;
}

.frequently-question .questions .accordion-button {
    background: #FFEEDB 0% 0% no-repeat padding-box;
    box-shadow: none;
    color: #000;
    font-size: 20px;
    font-weight: bold;
}

.frequently-question .questions .accordion-body p {
    font-size: 14px;
    font-weight: 400;
    color: #000;
}


@media (min-width: 0px) and (max-width: 991px) {
    .frequently-question .heading h4 {
        font-size: 20px;
    }

    .frequently-question .questions .accordion-button:not(.collapsed) {
        font-size: 16px;
    }

    .frequently-question .questions .accordion-body p {
        font-size: 12px;
    }

    .frequently-question .questions .accordion-button {
        font-size: 16px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .frequently-question .heading h4 {
        font-size: 25px;
    }
}