.drdypatilbanner-section {
    background-color: #FFF8F0;
    padding: 3% 0%;
    margin: 5% 0%;
}
.drdypatilbanner-section .banner-div .banner-img{
    width: 100%;
    height: 100%;    
    border-radius: 20px;
}


.drdypatilbanner-section .title-sec{
    text-align: center;
}
.drdypatilbanner-section .title-sec .abt-title{
    font-weight: bold;
    font-size: 32px;
    color: #000; 
    margin-bottom: 5px; 
}
.drdypatilbanner-section .title-sec .underline {   
    width: 170px;
    height: 3px;
    background: #ff8600;
    margin: auto;    
}
.drdypatilbanner-section .title-sec .sub-title{
    font-size: 16px;
    color: #383838;
    margin:15px 0px 15px 0px;
}






@media (min-width:0px) and (max-width:768px){

    .drdypatilbanner-section .title-sec .abt-title {
        font-size: 20px;
    }
    .drdypatilbanner-section .title-sec .sub-title {
        font-size: 14px;
    }
    .drdypatilbanner-section .title-sec .underline {
        width: 125px;
    }
}

@media (min-width:769px) and (max-width:991px){

    .drdypatilbanner-section .title-sec .abt-title {
        font-size: 26px;
    }
    .drdypatilbanner-section .title-sec .sub-title {
        font-size: 15px;
    }
}

@media (min-width:992px) and (max-width:1200px){
    .drdypatilbanner-section .title-sec .abt-title {
        font-size: 28px;
    } 
}

@media (min-width:1201px) and (max-width:1400px){
    .drdypatilbanner-section .title-sec .abt-title {
        font-size: 30px;
    }
}