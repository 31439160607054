.contact-map{
    margin: 4% 0;
}
.contact-map .gmap_canvas{
    height: 400px;
    width: 100%;
  }
 



