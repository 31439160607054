.Resetmodal .modal-body {
    background: #191919 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 20px;
}

.Resetmodal .resetpasstxt {
    color: #fff;
    text-align: center;
}

.Resetmodal .modal-content {
    border-radius: 30px;
}

.Resetmodal .modal-backdrop.show {
    background-color: #FFFFFF 0% 0% no-repeat padding-box !important;
    opacity: 1 !important;
    backdrop-filter: blur(8px) !important;
    -webkit-backdrop-filter: blur(8px);
}