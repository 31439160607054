.about-unidpu-sec {
    margin-bottom: 5%;
}

.about-unidpu-sec .title-sec {
    text-align: center;
}

.about-unidpu-sec .title-sec .abt-title {
    font-weight: bold;
    font-size: 32px;
    color: #000;
    margin-bottom: 5px;
}

.about-unidpu-sec .title-sec .underline {
    width: 170px;
    height: 3px;
    background: #ff8600;
    margin: auto;
}

.about-unidpu-sec .title-sec .sub-title {
    font-size: 16px;
    color: #383838;
    margin: 15px 0px 0px 0px;
}

.about-unidpu-sec .abtsec1 .cont-text {
    font-weight: normal;
    font-size: 16px;
    color: #383838;
    margin-bottom: 0px;
    margin-bottom: 25px;
    width: 90%;
    text-align: justify;
}

.about-unidpu-sec .abtsec1 .cont-text span {
    font-weight: 500;
}

.about-unidpu-sec .row-topp {
    margin-top: 40px;
}

.about-unidpu-sec .abtsec2 {
    position: relative;
}

.about-unidpu-sec .abtsec2 .laptop-img {
    width: 100%;
    /* position: absolute; */
    /* top: -100px;
    right: 0px; */
}


@media (min-width:0px) and (max-width:768px) {
    .about-unidpu-sec .abtsec2 .laptop-img {
        width: 75%;
        position: unset;
    }

    .about-unidpu-sec .abtsec2 {
        text-align: center;
    }

    .about-unidpu-sec .abtsec1 .cont-text {
        font-size: 14px;
        width: 100%;
        text-align: justify;
    }

    .about-unidpu-sec .title-sec .abt-title {
        font-size: 20px;
    }

    .about-unidpu-sec .title-sec .sub-title {
        font-size: 14px;
    }

    .about-unidpu-sec .title-sec .underline {
        width: 125px;
    }
}

@media (min-width:769px) and (max-width:991px) {
    .about-unidpu-sec .abtsec2 .laptop-img {
        width: 75%;
        position: unset;
    }

    .about-unidpu-sec .abtsec2 {
        text-align: center;
    }

    .about-unidpu-sec .abtsec1 .cont-text {
        font-size: 15px;
        width: 100%;
        text-align: justify;
    }

    .about-unidpu-sec .title-sec .abt-title {
        font-size: 26px;
    }

    .about-unidpu-sec .title-sec .sub-title {
        font-size: 15px;
    }
}

@media (min-width:992px) and (max-width:1200px) {
    .about-unidpu-sec .title-sec .abt-title {
        font-size: 28px;
    }

    .about-unidpu-sec .abtsec1 .cont-text {
        font-size: 15px;
    }

    .about-unidpu-sec .abtsec2 .laptop-img {
        top: 0px;
    }
}

@media (min-width:1201px) and (max-width:1400px) {
    .about-unidpu-sec .title-sec .abt-title {
        font-size: 30px;
    }

    .about-unidpu-sec .abtsec1 .cont-text {
        font-size: 15px;
    }
}