.Applybanner .applybanner {
    width: 100%;
    height: auto;
    margin-bottom: 3%;
    cursor: pointer;
}

.Applybanner .Applyimgmain {
    position: relative;
}

.Applybanner .Applyimgmain .applypersonimg {
    width: 100%;
}

.Applybanner .Applyimgmain  .applyNowtxt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Applybanner .Applyimgmain .applymainrow {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0%, -50%);
}

.Applybanner .Applyimgmain .applyNowtxt p {
    color: #fff;
    font-size: 30px;
    font-weight: bold;
}

.Applybanner .Applyimgmain .applybtn {
    background-color: #FF8600;
    border: 0;
    padding: 5px 34px;
}

@media (min-width:0px) and (max-width:767.98px){
    .Applybanner .Applyimgmain .applyNowtxt p {
        font-size: 17px;
        padding-left: 30px;
    }
    .Applybanner .Applyimgmain .applybtn {
        padding: 5px 20px;
        font-size: 12px;
    }   
}