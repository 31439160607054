.ProgramOffer .programofferheadtxt h3 {
    text-align: center;
    font-size: 32px;
    letter-spacing: 0px;
    color: #000000;
    font-family: 'Open Sans';
    font-weight: bold;
}

.ProgramOffer {
    margin-bottom: 4%;
}
.ProgramOffer .content_mainHeading {
    padding-left: 35px;
}
.ProgramOffer .programofferheadtxt {
    margin-bottom: 4%;
}

.ProgramOffer .programofferheadtxt p {
    text-align: center;
    font: normal normal normal 16px/24px Roboto;
    letter-spacing: 0.16px;
    color: #383838;
    opacity: 1;
}

.ProgramOffer .programofferheadtxt .border-line {
    border-bottom: 3px solid #FF8600;
    margin: auto;
    width: 170px;
}

.ProgramOffer .swiper-pagination-bullet-active {
    background: #FF8600;
}

.ProgramOffer .programcard {}

.ProgramOffer .offerImg_1 {
    width: 100%;
    /* transform: rotate(45deg); */
}

.ProgramOffer .losange,
.ProgramOffer .losange div {
    margin: 0 10%;
    transform-origin: 50% 50%;
    overflow: hidden;
    width: 250px;
    height: 250px;
}

.ProgramOffer .losange {
    transform: rotate(45deg) translateY(10px);
    position: relative;
}

.ProgramOffer .losange .los1 {
    width: 100%;
    /* height: 306px; */
    transform: rotate(-45deg) translateY(-35px);
}

.ProgramOffer .losange .los1 img {
    width: 100%;
    height: auto;
}

.ProgramOffer .part {
    border-left: 2px solid #FF860099;

}

.ProgramOffer .content_mainHeading .content_heading {
    text-align: center;
    font: normal normal bold 32px/45px Open Sans;
    color: #000000;
    text-decoration: underline #FF860099;

}

.ProgramOffer .content_mainHeading .content_para {
    text-align: center;
    font: normal normal normal 16px/24px Roboto;
    letter-spacing: 0.16px;
    color: #383838;
}

.ProgramOffer .losange .offerImg_2 {
    position: absolute;
    top: 9%;
    left: 15%;
    width: 62px !important;
    height: 62px !important;
}

@media screen and (min-width:0px) and (max-width: 320px) {
    .ProgramOffer .part {
        border-left: none;
        display: flex;
        justify-content: center;
        /* align-items: center; */
    }

    .ProgramOffer .losange,
    .ProgramOffer .losange div {
        width: 215px;
        height: 215px;
    }

    .ProgramOffer .programofferheadtxt h3 {
        font-size: 20px;
    }

    .ProgramOffer .programofferheadtxt p {
        font: normal normal normal 13px/24px Roboto;
    }

    .ProgramOffer .content_mainHeading .content_heading {
        font: normal normal bold 20px/22px Open Sans;
    }

    .ProgramOffer .content_mainHeading .content_para {
        font: normal normal normal 14px/24px Roboto;
    }

}

@media screen and (min-width:321px) and (max-width: 485px) {
    .ProgramOffer .part {
        border-left: none;
        display: flex;
        justify-content: center;
        /* align-items: center; */
    }

    .ProgramOffer .losange,
    .ProgramOffer .losange div {

        width: 215px;
        height: 215px;
    }

    .ProgramOffer .programofferheadtxt h3 {
        font-size: 20px;
    }

    .ProgramOffer .programofferheadtxt p {
        font: normal normal normal 13px/24px Roboto;
    }

    .ProgramOffer .content_mainHeading .content_heading {
        font: normal normal bold 24px/29px Open Sans;
    }

    .ProgramOffer .content_mainHeading .content_para {
        font: normal normal normal 14px/17px Roboto;
    }

}

@media screen and (min-width:486px) and (max-width: 767px) {
    .ProgramOffer .part {
        border-left: none;
        display: flex;
        justify-content: center;
        /* align-items: center; */
    }

    .ProgramOffer .losange,
    .ProgramOffer .losange div {

        width: 215px;
        height: 215px;
    }

    .ProgramOffer .programofferheadtxt h3 {
        font-size: 20px;
    }

    .ProgramOffer .programofferheadtxt p {
        font: normal normal normal 13px/24px Roboto;
    }

    .ProgramOffer .content_mainHeading .content_heading {
        font: normal normal bold 24px/29px Open Sans;
    }

    .ProgramOffer .content_mainHeading .content_para {
        font: normal normal normal 14px/17px Roboto;
    }

}

@media screen and (min-width:576px) and (max-width: 767px) {
    .ProgramOffer .part {
        border-left: none;
        display: flex;
        justify-content: center;
        /* align-items: center; */
    }

    .ProgramOffer .losange,
    .ProgramOffer .losange div {

        width: 215px;
        height: 215px;
    }

    .ProgramOffer .programofferheadtxt h3 {
        font-size: 20px;
    }

    .ProgramOffer .programofferheadtxt p {
        font: normal normal normal 13px/24px Roboto;
    }

    .ProgramOffer .content_mainHeading .content_heading {
        font: normal normal bold 24px/29px Open Sans;
    }

    .ProgramOffer .content_mainHeading .content_para {
        font: normal normal normal 14px/17px Roboto;
    }

}

@media(min-width:0px) and (max-width:767px) {
    .ProgramOffer .part-1 {
        border-left: none;
        display: flex;
        justify-content: center;
        /* align-items: center; */
    }
}

@media screen and (min-width:768px) and (max-width: 991px) {
    .ProgramOffer .programofferheadtxt h3 {
        font-size: 20px;
    }

    .ProgramOffer .content_mainHeading .content_heading {
        font: normal normal bold 20px/30px Open Sans;
    }

    .ProgramOffer .programofferheadtxt p {
        font-size: 14px;
    }
}

@media screen and (min-width:992px) and (max-width: 1200px) {
    .ProgramOffer .losange {
        transform: rotate(45deg) translate(23px, 3px);
        position: relative;
        margin: 0;
        width: 100%;
        height: 200px;
    }

    .ProgramOffer .losange .offerImg_2 {
        position: absolute;
        top: 9%;
        left: 19%;
    }

    .ProgramOffer .programofferheadtxt h3 {
        font-size: 25px;
    }

    .ProgramOffer .content_mainHeading .content_heading {
        font: normal normal bold 25px/30px Open Sans;
    }

    .ProgramOffer .programofferheadtxt p {
        font-size: 14px;
    }
}