.Header-section .courses-dropdown-main .nav-link.active {
    background-color: #FFF;
    border-bottom: 2px solid #ff8600;
    border-radius: 0px;
}

.Header-section .courses-dropdown-main .inner-tab-sec {
    margin: 20px 20px 10px 20px;
}

.Header-section .courses-dropdown-main .inner-tab-sec .card-row {
    white-space: pre-wrap;
}

.Header-section .courses-dropdown-main .inner-tab-sec h6 {
    color: #000;
    font-size: 13px;
    font-weight: 500;
    margin: 10px;
}

.Header-section .courses-dropdown-main .inner-tab-sec .uni-card-div p {
    color: #000;
    font-size: 13px;
    font-weight: 500;
}

.Header-section .courses-dropdown-main .inner-tab-sec .uni-card-div {
    background: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 0 3px 10px #ebe8e8;
    margin: 8px 8px;
    padding: 10px;
}

.Header-section .courses-dropdown-main .inner-tab-sec .uni-card-div:hover {
    box-shadow: 0 3px 10px #ff86008a;
}

.Header-section .courses-dropdown-main .inner-tab-sec .logo {
    width: 75px;
    height: auto;
    object-fit: contain;
    margin-right: 6%;
}

.Header-section .courses-dropdown-main .inner-tab-sec a {
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.Header-section .courses-dropdown-main .link-div:hover .arrow-icon {
    margin-left: 20px;
}

.Header-section .courses-dropdown-main .arrow-icon {
    transition: 0.4s ease-in-out;
}

.Header-section .courses-dropdown-main .inner-tab-sec .img-div {
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
}

.Header-section .courses-dropdown-main .university-tabs {
    margin: 20px 0px;
}

.Header-section .courses-dropdown-main .university-tabs .nav-link.active {
    background-color: #FFF;
    /* border: 2px solid #ff8600; */
    border-radius: 9px;
    width: 200px;
    margin-bottom: 10px;
    background-color: #fbefd8;
    border-bottom: none;
}

.Header-section .courses-dropdown-main .university-tabs .nav-link {
    width: 200px;
    margin-bottom: 10px;
}

.Header-section .courses-dropdown-main .university-tabs .nav-link:hover {
    background-color: #fbefd8;
}

.Header-section .courses-dropdown-main .specilization-card {
    text-align: center;
    border-radius: 5px;
    margin: 20px 5px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px 0px #c1c1c1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 110px;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 10px;
}

.Header-section .courses-dropdown-main .specilization-card:hover {
    border: 1px solid transparent;
    border-image: linear-gradient(to right, red, purple);
    border-image-slice: 1;
    border-radius: 5px;
}

.Header-section .courses-dropdown-main .specilization-card p {
    font-size: 14px;
    font-weight: 400;
    margin: 0px;
}

.Header-section .courses-dropdown-main .universitycontents {
    max-height: 300px;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.Header-section .pg-course-tab ::-webkit-scrollbar {
    /* display: none; */
}

.Header-section .courses-dropdown-main .silder-btn {
    position: relative;
}

.Header-section .courses-dropdown-main .silder-btn .back-btn {
    content: url("../../../../public/assets/images/icons/preCourse.png");
    width: 19px;
    height: 19px;
    margin: 10px;
    cursor: pointer;
    position: absolute;
    bottom: 29px;
    z-index: 999;
    left: -673px;
}

.Header-section .courses-dropdown-main .silder-btn .next-btn {
    content: url("../../../../public/assets/images/icons/nextCourse.png");
    width: 19px;
    height: 19px;
    margin: 10px;
    cursor: pointer;
    position: absolute;
    bottom: 29px;
    z-index: 999;
    right: -20px;
}

.Header-section .courses-dropdown-main .swiper-pagination-bullets {

    display: none;
}

.Header-section .swiper {
    width: 100%;
}

.Header-section .swiper-button-prev:after, .Header-section .swiper-button-next:after {
    font-size: 9px;
    color: #fff;
    background: #000;
    border-radius: 50%;
    padding: 7px 9px;
}



@media (min-width: 992px) and (max-width: 1200px) {
    .Header-section .courses-dropdown-main .silder-btn .back-btn {
        left: -479px;
    }
}

@media (min-height: 575px) and (max-height: 768px) {
    .Header-section .courses-dropdown-main .universitycontents {
        max-height: 200px;
    }
}