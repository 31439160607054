.DY-benifits-section {
    margin: 4% 0;
}

.DY-benifits-section .heading {
    text-align: center;
}

.DY-benifits-section .heading h4 {
    font-size: 32px;
    font-weight: bold;
    color: #000;
    margin-bottom: 3px;
}

.DY-benifits-section .benifit-slider {
    margin-top: 3%;
}

.DY-benifits-section .heading .text-border {
    border-bottom: 3px solid #FF8600;
    width: 170px;
    margin: auto;
}

.DY-benifits-section .swiper-video .benifit-videos {
    width: 100%;
    border-radius: 20px;
}
.DY-benifits-section .mySwiper .swiper-slide-active .benifit-videos
{
    position: relative;
    z-index: 99999;
}

.DY-benifits-section .swiper-video .video-border {
    width: 100%;
    height: 8px;
    background: #00000036 0% 0% no-repeat padding-box;
    opacity: 1;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.DY-benifits-section .benifit-slider .swiper-slide {
    overflow: visible;
    transform: translate3d(0px, 150px, -194px) rotateX(0deg) rotateY(0deg) scale(1.5) !important;

}

.DY-benifits-section .benifit-slider .swiper-slide-active {
    transform: scale(1.8) !important;
    padding: 12% 0%;

}

.DY-benifits-section .mySwiper .swiper-slide .swiper-video::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 94%;
    border-radius: 20px;
    background: rgb(238 238 238 / 50%);
}

.DY-benifits-section .mySwiper .swiper-slide-active .swiper-video::after {

    background: none;
}

.DY-benifits-section .benifit-slider {
    position: relative;
}

.DY-benifits-section .benifit-slider .arrow-back,
.DY-benifits-section .benifit-slider .arrow-next {
    cursor: pointer;
}


.DY-benifits-section .benifit-slider .arrow-back::after {
    content: url("/public/assets/images/online-learning/prev-icon.png");
    position: absolute;
    top: 43%;
    left: 118px;
    z-index: 2;
    cursor: pointer;
}

.DY-benifits-section .benifit-slider .arrow-next::before {
    content: url("/public/assets/images/online-learning/next-icon.png");
    position: absolute;
    top: 43%;
    right: 118px;
    z-index: 2;
    cursor: pointer;
}

.DY-benifits-section .benifit-slider .swiper-pagination-bullets {
    display: none;
}

@media(min-width:0px) and (max-width:576px) {
    .DY-benifits-section .benifit-slider .swiper-slide-active {
        transform: scale(1) !important;
        padding: 0% 0%;
    }

    .DY-benifits-section .benifit-slider .swiper-slide {
        transform: scale(1) !important;
    }

    .DY-benifits-section .benifit-slider .arrow-next::before {
        top: 35%;
        right: 5px;
    }

    .DY-benifits-section .benifit-slider .arrow-back::after {
        top: 35%;
        left: 5px;
    }

    .DY-benifits-section .heading h4 {
        font-size: 18px;
    }

    .DY-benifits-section .heading .text-border {
        width: 135px;
    }
    .DY-benifits-section .heading h4 {
        font-size: 20px;
    }
    .DY-benifits-section {
        margin: 9% 0;
    }
}

@media(min-width:577px) and (max-width:768px) {
    .DY-benifits-section .benifit-slider .swiper-slide-active {
        transform: scale(1) !important;
        padding: 0% 0%;
    }

    .DY-benifits-section .benifit-slider .swiper-slide {
        transform: scale(1) !important;
    }

    .DY-benifits-section .benifit-slider .arrow-next::before {
        top: 40%;
        right: 5px;
    }

    .DY-benifits-section .benifit-slider .arrow-back::after {
        top: 40%;
        left: 5px;
    }
    .DY-benifits-section .heading h4 {
        font-size: 20px;
    }
}

@media(min-width:769px) and (max-width:991px) {
    .DY-benifits-section .benifit-slider .swiper-slide {
        transform: translate3d(0px, 75px, -194px) rotateX(0deg) rotateY(0deg) scale(1.5) !important;
    }

    .DY-benifits-section .benifit-slider .swiper-slide-active {
        transform: scale(1.8) !important;
    }

    .DY-benifits-section .swiper-video .video-border {
        height: 4px;
    }

    .DY-benifits-section .benifit-slider .arrow-next::before {
        right: 57px;
    }

    .DY-benifits-section .benifit-slider .arrow-back::after {
        left: 57px;

    }
    .DY-benifits-section .heading h4 {
        font-size: 20px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .DY-benifits-section .benifit-slider .swiper-slide {
        transform: translate3d(0px, 102px, -194px) rotateX(0deg) rotateY(0deg) scale(1.5) !important;
    }

    .DY-benifits-section .benifit-slider .swiper-slide-active {
        transform: scale(1.8) !important;
    }

    .DY-benifits-section .swiper-video .video-border {
        height: 6px;

    }

    .DY-benifits-section .benifit-slider .arrow-next::before {
        right: 82px;
    }

    .DY-benifits-section .benifit-slider .arrow-back::after {
        left: 82px;
    }
    .DY-benifits-section .heading h4 {
        font-size: 25px;
    }
}