.about-text {
    background: #FFF8F0;
    margin: 0 0 5% 0;
}

.about-text .text-holder-about .sub-line {
    font-size: 19px;
    color: #383838;
    font-weight: 600;
}

.about-text .text-holder-about .main-text {
    font-size: 16px;
    color: #383838;
    text-align: justify;
}

.about-text .text-holder-about {

        width: 80%;
        text-align: justify;
        margin: auto;

}

/* Media Query */

@media(min-width:0px) and (max-width:320px) {
    .about-text .text-holder-about .sub-line {
        font-size: 13px;
    }

    .about-text .text-holder-about .main-text {
        font-size: 13px;
    }
}

@media(min-width:321px) and (max-width:576px) {
    .about-text .text-holder-about .sub-line {
        font-size: 14px;
    }

    .about-text .text-holder-about .main-text {
        font-size: 13px;
    }
}

@media(min-width:577px) and (max-width:768px) {
    .about-text .text-holder-about .sub-line {
        font-size: 16px;
    }

    .about-text .text-holder-about .main-text {
        font-size: 15px;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .about-text .text-holder-about .sub-line {
        font-size: 16px;
    }

    .about-text .text-holder-about .main-text {
        font-size: 15px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .about-text .text-holder-about .sub-line {
        font-size: 17px;
    }

    .about-text .text-holder-about .main-text {
        font-size: 16px;
    }
}