.media-proficiency {
  background: #fff8f0 0% 0% no-repeat padding-box;
  margin: 0% 0% 5% 0px;
  padding: 3% 0;
}

.media-proficiency .heading-holder {
  margin-top: 20%;
  padding-left: 15px;
}

.media-proficiency .heading-holder h5 {
  color: #ff8600;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}

.media-proficiency .heading-holder .title {
  font-size: 32px;
  font-weight: bold;

  margin-bottom: 15px;
}

.media-proficiency .heading-holder p {
  color: #383838;
  font-size: 16px;
}

.media-proficiency .paper-imgs {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 3px solid #ff8600;
  border-radius: 10px;
}

.media-proficiency .paper-imgs .award-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.media-proficiency .media_proficiency_slider .mySwiper {
  width: 101%;
}

.media-proficiency .media_proficiency_slider {
  position: relative;
  overflow: hidden;
}

.media-proficiency .media_proficiency_slider .overlay_layer {
  position: absolute;
  top: 0;
  background: transparent linear-gradient(90deg, #fff8f000 0%, #fff8f0 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  right: 0;
  width: 50px;
  height: 100%;
  z-index: 1;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .media-proficiency .heading-holder .title {
    font-size: 25px;
  }

  .media-proficiency .heading-holder p {
    font-size: 14px;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .media-proficiency .heading-holder .title {
    font-size: 20px;
    text-align: center;
  }

  .media-proficiency .heading-holder p {
    font-size: 14px;
    text-align: center;
  }

  .media-proficiency .heading-holder h5 {
    font-size: 13px;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .media-proficiency .heading-holder .title {
    font-size: 20px;
  }

  .media-proficiency .heading-holder {
    margin-top: 0%;
    padding-left: 15px;
  }

  .media-proficiency .heading-holder p {
    font-size: 14px;
  }

  .media-proficiency .paper-imgs .award-img {
    /* width: auto; */
    height: 250px;
  }
}

@media (min-width: 0px) and (max-width: 359px) {
  .media-proficiency .paper-imgs {
    text-align: center;
  }

  .media-proficiency .paper-imgs .award-img {
    /* width: 250px; */
    height: 285px;
  }

  .media-proficiency {
    padding-top: 10%;
  }

  .media-proficiency .heading-holder {
    margin-top: 0%;
  }
}

@media (min-width: 360px) and (max-width: 420px) {
  .media-proficiency .paper-imgs .award-img {
    /* width: 224px; */
    height: 255px;
  }

  .media-proficiency {
    padding-top: 10%;
  }

  .media-proficiency .heading-holder {
    margin-top: 0%;
  }

  .media-proficiency .paper-imgs {
    text-align: center;
  }
}

@media (min-width: 421px) and (max-width: 577px) {
  .media-proficiency .paper-imgs {
    text-align: center;
  }

  .media-proficiency .paper-imgs .award-img {
    /* width: 250px; */
    height: 250px;
  }

  .media-proficiency {
    padding-top: 6%;
  }

  .media-proficiency .heading-holder {
    margin-top: 0%;
  }
}

@media (min-width: 578px) and (max-width: 767px) {
  .media-proficiency .paper-imgs .award-img {
    /* width: 285px; */
    height: 250px;
  }

  .media-proficiency {
    padding-top: 5%;
  }

  .media-proficiency .heading-holder {
    margin-top: 0%;
  }
}

@media (min-width: 991px) and (max-width: 1023px) {
  .media-proficiency .paper-imgs .award-img {
    /* width: 285px; */
    height: 250px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .media-proficiency .paper-imgs .award-img {
    /* width: 285px; */
    height: 250px;
  }
}
