.amityOnlineCourse {
    background: #FFF8F0 0% 0% no-repeat padding-box;
    padding: 2%;
    margin-bottom: 4%;
}

.amityOCheadtext h3 {
    text-align: center;
    font: normal normal bold 32px/45px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.amityOCheadtext p {
    text-align: center;
    font: normal normal normal 16px/30px Roboto;
    letter-spacing: 0.16px;
    color: #383838;
    opacity: 1;
    margin-top: 3%;
}


@media screen and (min-width: 0px) and (max-width: 320px) {
    .amityOCheadtext h3 {
        text-align: center;
        font: normal normal bold 20px/29px Open Sans;
        /* font-size: 20px; */
    }

    .amityOCheadtext p {
        text-align: justify;
        font: normal normal normal 13px/22px Roboto;
    }
}

@media screen and (min-width: 321px) and (max-width: 991px) {
    .amityOCheadtext h3 {
        font: normal normal bold 20px/34px Open Sans;
    }

    .amityOCheadtext p {
        text-align: justify;
        font: 13px/20px Roboto;
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .amityOCheadtext p {
        text-align: justify;
        font: normal normal normal 16px/24px Roboto;
    }

    .amityOCheadtext h3 {
        text-align: center;
        font: normal normal bold 25px/45px Open Sans;
    }
   
}
