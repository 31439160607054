/* .inquiry-form-modal .modal-header .btn-close {
    background-image: url('../../../../../public/assets/Finance/Image/icons/Iconcancel.png');
    background-repeat: no-repeat;
    opacity: 1;
    font-size: 20px;
} */
.inquiry-form-modal {
    z-index: 999999 !important;

}

.inquiry-form-modal .modal-content {
    border: none;
}

.inquiry-form-modal .modal-header .modal-title {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    margin-left: auto;
}

.inquiry-form-modal .form-sec-start span {
    color: #383838;
    font-size: 11px;
    opacity: 0.8;
    padding-left: 42px;
    margin-bottom: 13px;
}

.inquiry-form-modal .date-picker-holder input[type="text"] {
    width: 100%;
    font-size: 14px;
}

.inquiry-form-modal .date-picker-holder input:focus-visible {
    outline: none;
}

.inquiry-form-modal .date-picker-holder .react-datepicker-wrapper {
    width: 100%;
}

.inquiry-form-modal .form-sec-start .form-label {
    font-size: 13px;
    margin-bottom: 0px;
}

.inquiry-form-modal .form-sec-start .form-control {
    border-radius: 5px;
    font-size: 14px;

}

.inquiry-form-modal .form-sec-start .form-select {
    border-radius: 5px;
    font-size: 14px;
}

.inquiry-form-modal .form-sec-start .year-text {
    border: 0.5px solid #707070;
    border-radius: 5px;
}

.inquiry-form-modal .form-sec-start .button-holder .apply-btn {
    background: #000;
    border-radius: 5px;
    width: 157px;
    height: 37px;
    border: #ff860059;
    color: #fff;
    font-size: 14px;
}

.inquiry-form-modal .form-sec-start .button-holder .btn-cancle {
    background: transparent;
    border-radius: 5px;
    width: 157px;
    height: 37px;
    border: 0.5px solid #000000;
    color: #000;
    font-size: 14px;
}

.inquiry-form-modal .form-sec-start .form-detail {
    padding-left: 30px;
    padding-right: 30px;
}

.inquiry-form-modal .form-sec-start .form-check-label {
    font-size: 16px;
}

.inquiry-form-modal .modal-backdrop {
    --bs-backdrop-zindex: 1050;
    --bs-backdrop-bg: #000;
    --bs-backdrop-opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999 !important;
}

.inquiry-form-modal .fade.modal-backdrop.show {
    z-index: 999999 !important;
}


@media(min-width:0px) and (max-width:767px) {
    .inquiry-form-modal .modal-header .modal-title {
        font-size: 18px;
    }
}