/* header css start */

.Header-section {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 99999;
}

.Header-section .all-header-content .upper-head {
    background-color: #FF8600;
    padding: 7px;
}

.Header-section .all-header-content .upper-head .mail-icon {
    color: #FFFF;
    font-size: 23px;
}

.Header-section .all-header-content .upper-head .icons {
    width: 25px;
    height: 25px;
    margin-left: 13px;
}

.Header-section .all-header-content .upper-head .mail-text {
    color: #FFFF;
    font-size: 14px;
    font-weight: 400;
    margin-left: 15px;
    margin-bottom: 0px;
}

.Header-section .all-header-content .main-header .nav-bar {
    margin-top: 20px;
}

.Header-section .all-header-content .main-header {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 37px #00000026;
    opacity: 1;
}

.Header-section .all-header-content .main-header .headlogo {
    width: 110px;
}

.Header-section .all-header-content .main-header .nav-link {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    margin: 0px 21px;
    width: -moz-fit-content;
    width: fit-content;
}

.Header-section .all-header-content .main-header .drop-nav-link {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    margin: 0px 10px;
}

.Header-section .all-header-content .main-header .nav-link.active {
    font-weight: 600;
}

.Header-section .nav-link.active .border_effect {
    width: 8px;
    height: 8px;
    margin: auto;
    background: #FF8600 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 50px;
    margin-top: 7px;
}


.Header-section .all-header-content .main-header .btn-div .contact-btn {
    background-color: #000;
    color: #FFFF;
    border: 1px solid #000;
    font-size: 14px;
    padding: 8px 25px;
    margin-left: 54px;
    font-weight: 400;
}

/* .Header-section .all-header-content .main-header .btn-div .contact-btn:focus {
    background: #FF860059 0% 0% no-repeat padding-box;
    border: 1px solid #8F8F8F;
    color: #000;
    border-radius: 5px;
    font-size: 14px;
    padding: 8px 25px;
    font-weight: 500;
} */

/* .Header-section .all-header-content .main-header .btn-div .contact-btn:active {
    background: #FF860059 0% 0% no-repeat padding-box;
    border: 1px solid #8F8F8F;
    color: #000;
    border-radius: 5px;
    font-size: 14px;
    padding: 8px 25px;
    font-weight: 500;
} */

.Header-section .all-header-content .main-header .btn-div .contact-btn:hover {
    background-color: #FFF;
    color: #000;
    border: 1px solid #000;
    font-weight: 600;
}

.Header-section .all-header-content .dropdown-item.active {
    font-weight: 400;
    /* background-color: #FFF !important; */
    color: #000;
}

.Header-section .all-header-content .dropdown-item:focus,
.dropdown-item:hover {

    /* background-color: #FFF !important; */
}

.Header-section .all-header-content .dropdown-item:hover {
    /* background-color: #FFF !important; */
}

.Header-section .all-header-content .navbar-nav .dropdown-menu {
    background: #fff 0 0 no-repeat padding-box;
    border: none;
    border-radius: 0;
    box-shadow: 0 40px 37px #00000026;
    left: 17%;
    opacity: 1;
    padding: 15px;
    top: 55px;
    width: 450px;
}


.Header-section .all-header-content .dropdown-toggle::after {
    display: none !important;
}

.Header-section .all-header-content .dropdown-item {
    padding: 15px 15px;
    background: #fff;
}

.Header-section .all-header-content .main-dropdown {
    position: relative;
}

/* .Header-section .all-header-content .dropdown-submenu .dropdown-menu-two {
    top: 0px;
    left: 99%;
    border-radius: 1%;
    position: absolute;
    display: none;
}

.Header-section .all-header-content .dropdown-submenu:hover .dropdown-menu-two {
    display: block;
    width: 130px;

} */

.Header-section .all-header-content .diploma-menu {
    height: 100%;
    min-height: 100px;
}

.Header-section .all-header-content .dropdown-menu-two {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 37px #00000026;
    opacity: 1;
}

.Header-section .all-header-content .dropdown-menu-two .pg-links li {
    font-size: 16px;
    color: black;
    padding: 7px 10px 5px 13px;
}

.Header-section .all-header-content .dropdown-menu-two .pg-links li:hover {
    background-color: #00000029;
}

.Header-section .all-header-content .mobile-view-dropdown {
    display: none;
}

.Header-section .all-header-content .desktop-view-dropdown {
    display: block;
}

.Header-section .becomeee-text {
    font-weight: 400 !important;
}

.Header-section .show.dropdown .svg-inline--fa {
    transform: rotate(180deg);
}

/* dropdown tab css */
.Header-section .courses-dropdown-main .nav-link.active {
    background-color: #FFF;
    border-bottom: 2px solid #ff8600;
    border-radius: 0px;
}

.Header-section .courses-dropdown-main .inner-tab-sec {
    margin: 20px 10px 10px 10px;
}

.Header-section .all-header-content .navbar-nav .courses-dropdown-main .dropdown-menu {
    border: none;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 40px 37px #00000026;
    opacity: 1;
    border-radius: 0px;
    padding: 0px;
    top: 58px;
    width: 1000px;
    left: 0px;
    transform: translateX(-25%);
}

.Header-section .all-header-content .navbar-nav .courses-dropdown-main .main-tab-content {
    border-left: 1px solid black;
}

.pg-course-tab .navbar-nav {
    width: 100%;
    overflow-x: scroll;
    padding-bottom: 10px;
}


.Header-section .logo-uni {
    width: 100%;
    height: auto;
    object-fit: contain;
    aspect-ratio: 4/1;
}



.Header-section .nav-item.show.dropdown .border_effect {
    width: 8px;
    height: 8px;
    margin: auto;
    background: #FF8600 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 50px;
    margin-top: 7px;
    font-weight: 600;
}

.Header-section .dropdown-item {
    border: 1px solid #FFF !important;
}

.Header-section .dropdown-item:hover {
    border: 1px solid #FF8600 !important;
}

.Header-section .active-dropdown-item {
    border: 1px solid #FF8600 !important;
}

.Header-section .navbar-toggler:focus {
    box-shadow: none;
}

/* media query start */

/* dropdown mediaquery css */

@media (min-width:0px) and (max-width:991px) {
    .Header-section .all-header-content .desktop-view-dropdown {
        display: none !important;
    }

    .Header-section .all-header-content .mobile-view-dropdown {
        width: 170px;
        display: block;
    }

    .Header-section .all-header-content .mobile-view-dropdown .inner-accordian button.accordion-button.collapsed {
        background-color: #ff860033;
        border-radius: 0px;
    }

    .Header-section .all-header-content .mobile-view-dropdown .inner-accordian button.accordion-button:not(.collapsed) {
        background-color: #ff860033;
        border-radius: 0px;
    }

    .Header-section .all-header-content .mobile-view-dropdown .accordion-item {
        border: none;
    }

    .Header-section .all-header-content .mobile-view-dropdown .accordion-body {

        padding: 0px;
    }

    .Header-section .all-header-content .dropdown-item {
        padding: 7px 0px;
        margin: 0px 19px;
    }

    .Header-section .all-header-content .mobile-view-dropdown .accordion-item {
        border: none;
    }

    .Header-section .all-header-content .mobile-view-dropdown .accordion-body {

        padding: 0px;
    }

    .Header-section .all-header-content .dropdown-item {
        padding: 7px 14px;
        margin: 0px 0px;
    }

    .Header-section .all-header-content .mobile-view-dropdown .accordion-button:not(.collapsed)::after {
        color: #000;
        background-image: var(--bs-accordion-btn-icon);
        background-size: 15px;
    }

    .Header-section .all-header-content .mobile-view-dropdown .accordion-button::after {
        background-size: 15px;
    }
}


@media (min-width:0px) and (max-width:768px) {
    .Header-section .all-header-content .upper-head .mail-text {
        font-size: 12px;
    }

    .Header-section .all-header-content .upper-head .mail-icon {
        font-size: 16px;
        margin-top: 5px;
    }

    .Header-section .all-header-content .main-header .nav-link {
        font-size: 14px;
    }

    .Header-section .all-header-content .main-header .btn-div .contact-btn {
        font-size: 12px;
        padding: 5px 15px;
        margin-left: 17px;
    }

    .Header-section .all-header-content .main-header .btn-div .contact-btn:focus {
        font-size: 12px;
        padding: 5px 15px;
        margin-left: 17px;
    }

    .Header-section .all-header-content .main-header .btn-div .contact-btn:active {
        font-size: 12px;
        padding: 5px 15px;
        margin-left: 17px;
    }

    .Header-section .all-header-content .navbar-toggler-icon {
        width: 22px;
        height: 22px;
    }



    .Header-section .all-header-content .main-header .headlogo {
        width: 85px;
    }

    /* .Header-section .all-header-content .navbar-toggler:focus {
        padding: 5px;
    } */

    .Header-section .all-header-content .upper-head .icons {
        width: 19px;
        height: 19px;
        margin-left: 9px;
    }

    .Header-section .all-header-content .main-header .drop-nav-link {
        font-size: 12px;
    }

    /* dropdown css */

    .Header-section .all-header-content .mobile-view-dropdown button.accordion-button {
        font-size: 14px;
        color: black;
        font-weight: 400;
    }

    .Header-section .all-header-content .mobile-view-dropdown button.accordion-button.collapsed {
        font-size: 14px;
        color: black;
        font-weight: 400;
        box-shadow: none;
        padding: 7px 16px 7px 25px;
    }

    .Header-section .all-header-content .mobile-view-dropdown .accordion-button:not(.collapsed) {
        color: black;
        background-color: #FFF;
        box-shadow: none;
        font-size: 14px;
        padding: 7px 16px 7px 25px;
    }

    .Header-section .all-header-content .main-header .drop-nav-link {
        font-size: 14px;
    }

}

@media (min-width:769px) and (max-width:991px) {
    .Header-section .all-header-content .main-header .btn-div .contact-btn {
        margin-left: 17px;
    }

    .Header-section .all-header-content .main-header .btn-div .contact-btn:focus {
        margin-left: 17px;
    }

    .Header-section .all-header-content .main-header .btn-div .contact-btn:active {
        margin-left: 17px;
    }

    /* dropdown css */

    .Header-section .all-header-content .mobile-view-dropdown button.accordion-button {
        font-size: 16px;
        color: black;
        font-weight: 400;
    }

    .Header-section .all-header-content .mobile-view-dropdown button.accordion-button.collapsed {
        font-size: 16px;
        color: black;
        font-weight: 400;
        box-shadow: none;
        padding: 7px 16px 7px 25px;
    }

    .Header-section .all-header-content .mobile-view-dropdown .accordion-button:not(.collapsed) {
        color: black;
        background-color: #FFF;
        box-shadow: none;
        font-size: 16px;
        padding: 7px 16px 7px 25px;
    }



}

@media (min-width:992px) and (max-width:1200px) {
    .Header-section .all-header-content .main-header .nav-link {
        margin: 0px 5px;
        font-size: 13px;
    }

    .Header-section .all-header-content .main-header .btn-div .contact-btn {
        margin-left: 40px;
    }

    .Header-section .all-header-content .main-header .btn-div .contact-btn:focus {
        margin-left: 40px;
    }

    .Header-section .all-header-content .main-header .btn-div .contact-btn:active {
        margin-left: 40px;
    }

    .Header-section .all-header-content .navbar-nav .courses-dropdown-main .dropdown-menu {
        width: 900px;
        left: 100px;
    }

    /* .Header-section .all-header-content .navbar-nav .dropdown-menu {
        left: 4%;
    } */
}

@media (min-width:1201px) and (max-width:1400px) {
    .Header-section .all-header-content .main-header .nav-link {
        margin: 0px 10px;
        font-size: 13px;
    }

    .Header-section .all-header-content .main-header .btn-div .contact-btn {
        margin-left: 60px;
    }

    .Header-section .all-header-content .main-header .btn-div .contact-btn:focus {
        margin-left: 60px;
    }

    .Header-section .all-header-content .main-header .btn-div .contact-btn:active {
        margin-left: 60px;
    }

    .Header-section .all-header-content .navbar-nav .courses-dropdown-main .dropdown-menu {
        width: 982px;
        transform: translateX(-35%);
        left: 110px;
    }

    /* .Header-section .all-header-content .navbar-nav .dropdown-menu {
        left: 10%;
    } */
}


/* Modal */
.become-partner-modal {
    z-index: 99999 !important;
}

.become-partner-modal .btn-close {
    background-image: url('../../../../public/assets/images/icons/Icon-cancel.png');
    width: 19px !important;
    height: 19px !important;
    border-radius: 100px;
    box-shadow: none;
    /* background-color: black;
    background-repeat: no-repeat; */
    opacity: inherit;
}

.become-partner-modal .btn-close:focus {
    box-shadow: none;
}

/* .become-partner-modal .modal-header {
    border-bottom: none;
} */

.become-partner-modal .modal-title {
    font-size: 18px;

}

.become-partner-modal .modal-content {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
}

@media(min-width:0px) and (max-width:991px) {
    .become-partner-modal .modal-title {
        font-size: 20px;

    }

}