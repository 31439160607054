.admission-policy .policy-content {
    margin-top: 3%;
}

.admission-policy {
    margin-top: 5%;
    margin-bottom: 7%;
}

.admission-policy .text-holder-policy {
    text-align: center;
    color: #000000;
}

.admission-policy .text-holder-policy h2 {
    font-size: 32px;
    font-weight: bold;
}

.admission-policy .text-holder-policy p {
    font-size: 16px;
}

.admission-policy .policy-content ul li{
    list-style: none;
    line-height: 19px;
    font-size: 16px;
}

.admission-policy .policy-content ul li::before {
    content: "";
    border-color: transparent #111;
    border-style: solid;
    border-width: 0.55em 0 0.55em 0.65em;
    display: block;
    height: 0;
    width: 0;
    left: -26px;
    top: 13.9px;
    position: relative;
    border-radius: 3px;
}

/* Media */

@media(min-width:0px) and (max-width:320px) {
    .admission-policy .text-holder-policy h2 {
        font-size: 20px;
    }
    .admission-policy .policy-content ul li {
        font-size: 12px;
    }
}

@media(min-width:0px) and (max-width:375px) {
    .admission-policy .text-holder-policy h2 {
        font-size: 20px;
    }
    .admission-policy .policy-content ul li {
        font-size: 13px;
    }
}


@media(min-width:376px) and (max-width:485px) {
    .admission-policy .text-holder-policy h2 {
        font-size: 20px;
    }
    .admission-policy .policy-content ul li {
        font-size: 13px;
    }
}

@media(min-width:486px) and (max-width:576px) {
    .admission-policy .text-holder-policy h2 {
        font-size: 20px;
    }
    .admission-policy .policy-content ul li {
        font-size: 13px;
    }
}

@media(min-width:577px) and (max-width:768px) {
    .admission-policy .text-holder-policy h2 {
        font-size: 20px;
    }
    .admission-policy .policy-content ul li {
        font-size: 14px;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .admission-policy .text-holder-policy h2 {
        font-size: 20px;
    }
    .admission-policy .policy-content ul li {
        font-size: 14px;
    }
}

@media(min-width:993px) and (max-width:1200px) {
    .admission-policy .text-holder-policy h2 {
        font-size: 25px;
    }
    .admission-policy .policy-content ul li {
        font-size: 14px;
    }
}