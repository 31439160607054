.video-profcyma {
    margin: 0 0 5% 0;
}

.video-profcyma .video-class-grid {
    height: 100%;
    width: 100%;
    border-radius: 30px;
    object-fit: cover;
}

@media(min-width:0px) and (max-width:320px) {
    .video-profcyma .video-class-grid {
        height: 100%;
        object-fit: cover;
    }
}


@media(min-width:320px) and (max-width:578px) {
    .video-profcyma .video-class-grid {
        height: 100%;
        object-fit: cover;
    }
}