.Fee-structure {
    margin: 4% 2%;
    padding: 60px 34px 54px 20px;
    background-image: url('../../../../../../../public/assets/images/Courses/fee-struture1.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 15px;
}

/* .Fee-structure .heading-holder {
    margin-top: 165px;
} */

.Fee-structure .heading-holder h2 {
    font-size: 32px;
    font-weight: bold;
    color: #fff;

}

.Fee-structure .heading-holder p {
    color: #fff;
    font-size: 14px;
}

.Fee-structure .table tr {
    /* border: 1px dashed #000; */
}

.Fee-structure .table {
    /* box-shadow: 0px 3px 6px #f8f9fa; */
    /* background-color: #FFF; */
}

.Fee-structure .table th {
    background-color: #FF8600;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    padding: 10px;
    /* border: 2px dashed #fff; */
    text-wrap: nowrap;
}

.Fee-structure .table td {
    font-size: 14px;
    /* color: #FFF; */
    /* padding: 15px; */
    /* border: 1px dashed #000; */
    /* text-wrap: nowrap; */
}


@media(min-width:0px) and (max-width:991px) {
    .Fee-structure .heading-holder h2 {
        font-size: 20px;
    }

    .Fee-structure .table td {
        font-size: 12px;
    }

    .Fee-structure .table th {
        font-size: 14px;
    }

    .Fee-structure .heading-holder {
        margin-top: 5px;
        text-align: center;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .Fee-structure .heading-holder h2 {
        font-size: 25px;
    }

    .Fee-structure .heading-holder {
        margin-top: 0px;
        text-align: center;
    }
}