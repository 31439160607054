.partnerbanner-sec {
    margin: 30px 0px 5% 0px;
}


.partnerbanner-sec  .banner-img
{
    width: 100%;
}
.head-partner-slider .become-slider
{
    padding: 20px;
    margin: 4% 10%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    text-align: center;
}
.head-partner-slider .become-slider .logos
{
width: 100px;
height: 100%;
object-fit: contain;
}
.head-partner-slider .swiper-wrapper {
   
    padding: 1% 0px 6% 0px;
}

.partnerbanner-sec .mobile-view-banner{
    display: none !important;
}