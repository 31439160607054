.who-we-section {
    margin: 0% 0% 5% 0%;
    background: #fff8f0;
    padding: 2% 0%;
}

.who-we-section .sec-1 .who-p {
    font-weight: 500;
    font-size: 18px;
    color: #ff8600;
    margin-bottom: 5px;
}

.who-we-section .sec-1 .who-title {
    font-weight: bold;
    font-size: 32px;
    color: #FF8600;
}

.who-we-section .sec-1 .subtitile {
    font-weight: 500;
    font-size: 20px;
    color: #000;
}

.who-we-section .sec-1 .content {
    font-weight: normal;
    font-size: 16px;
    color: #383838;
    width: 90%;
    text-align: justify;
}

.who-we-section .sec-2 .video-div .video {
    width: 100%;
}

@media(min-width:0px) and (max-width:768px) {
    .who-we-section .sec-1 .who-p {
        font-size: 12px;
        text-align: center;
    }

}

@media (min-width:0px) and (max-width:768px) {

    .who-we-section .sec-1 .who-title {
        font-size: 20px;
        text-align: center;
    }

    .who-we-section .sec-1 .subtitile {
        font-size: 16px;
        text-align: center;
    }

    .who-we-section .sec-1 .content {
        font-size: 14px;
        width: 100%;
        text-align: justify;
    }
}

@media (min-width:769px) and (max-width:991px) {
    .who-we-section .sec-1 .who-p {
        font-size: 14px;
    }

    .who-we-section .sec-1 .who-title {
        font-size: 25px;
    }

    .who-we-section .sec-1 .subtitile {
        font-size: 18px;
    }

    .who-we-section .sec-1 .content {
        font-size: 15px;
        width: 100%;
    }
}

@media (min-width:992px) and (max-width:1200px) {
    .who-we-section .sec-1 .who-p {
        font-size: 16px;
    }

    .who-we-section .sec-1 .who-title {
        font-size: 25px;
    }

    .who-we-section .sec-1 .subtitile {
        font-size: 18px;
    }

    .who-we-section .sec-1 .content {
        font-size: 14px;
    }
}

@media (min-width:1201px) and (max-width:1400px) {
    .who-we-section .sec-1 .content {
        font-size: 15px;
    }

    .who-we-section .sec-1 .content {
        font-size: 15px;
    }
}