.Degree-value-introduction {
    margin: 3% 0px 3% 0px;
}

.Degree-value-introduction .top-div h1.main-title {
    font-size: 32px;
    font-weight: bold;
    color: #000;
    text-align: center;
}

.Degree-value-introduction .top-div h3.intro {
    font-size: 32px;
    font-weight: bold;
    color: #000;
    text-align: center;
    margin-top: 3%;
    margin-bottom: 1%;
}

.Degree-value-introduction .top-div p.descriptn {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    text-align: center;
}

.Degree-value-introduction .bottom-div-descript {
    background: #FFF8F0 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 3% 0% 3% 0%;
}

.Degree-value-introduction .bottom-div-descript h1.heading {
    font-size: 32px;
    font-weight: bold;
    color: #000;
    text-decoration: underline;
}
.Degree-value-introduction .bottom-div-descript h1.heading:hover
{
    color: #FF8600;
    cursor: pointer;
}

.Degree-value-introduction .bottom-div-descript p.bottom-desc {
    font-size: 16px;
    font-weight: 400;
    color: #000;
}

.Degree-value-introduction .bottom-div-descript .head-div {
    margin-top: 5%;
}


@media(min-width:0px) and (max-width:575px)
{
    .Degree-value-introduction .top-div h1.main-title {
        font-size: 18px;
    }
    .Degree-value-introduction .top-div h3.intro {
        font-size: 18px;
    }
    .Degree-value-introduction .top-div p.descriptn {
        font-size: 13px;
    }
    .Degree-value-introduction .bottom-div-descript h1.heading {
        font-size: 18px;
    }
    .Degree-value-introduction .bottom-div-descript p.bottom-desc {
        font-size: 13px;
    }
}
@media(min-width:576px) and (max-width:768px)
{
    .Degree-value-introduction .top-div h1.main-title {
        font-size: 24px;
    }
    .Degree-value-introduction .top-div h3.intro {
        font-size: 24px;
    }
    .Degree-value-introduction .top-div p.descriptn {
        font-size: 14px;
    }
    .Degree-value-introduction .bottom-div-descript h1.heading {
        font-size: 24px;
    }
    .Degree-value-introduction .bottom-div-descript p.bottom-desc {
        font-size: 14px;
    }
}
@media(min-width:769px) and (max-width:991px)
{
    .Degree-value-introduction .top-div h1.main-title {
        font-size: 24px;
    }
    .Degree-value-introduction .top-div h3.intro {
        font-size: 24px;
    }
    .Degree-value-introduction .top-div p.descriptn {
        font-size: 14px;
    }
    .Degree-value-introduction .bottom-div-descript h1.heading {
        font-size: 24px;
    }
    .Degree-value-introduction .bottom-div-descript p.bottom-desc {
        font-size: 14px;
    }
}
@media(min-width:992px) and (max-width:1200px)
{
    .Degree-value-introduction .top-div h1.main-title {
        font-size: 26px;
    }
    .Degree-value-introduction .top-div h3.intro {
        font-size: 26px;
    }
    .Degree-value-introduction .top-div p.descriptn {
        font-size: 14px;
    }
    .Degree-value-introduction .bottom-div-descript h1.heading {
        font-size: 26px;
    }
    .Degree-value-introduction .bottom-div-descript p.bottom-desc {
        font-size: 14px;
    }
}
@media(min-width:1201px) and (max-width:1400px)
{
    .Degree-value-introduction .top-div h1.main-title {
        font-size: 30px;
    }
    .Degree-value-introduction .top-div h3.intro {
        font-size: 30px;
    }
    .Degree-value-introduction .top-div p.descriptn {
        font-size: 15px;
    }
    .Degree-value-introduction .bottom-div-descript h1.heading {
        font-size: 30px;
    }
    .Degree-value-introduction .bottom-div-descript p.bottom-desc {
        font-size: 15px;
    }
}