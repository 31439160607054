.specilization-banner .ban-img {
    width: 100%;
    height: 320px;
    object-fit: cover;
}

.specilization-banner {
    position: relative;
}

.specilization-banner .overlay-title {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.specilization-banner .overlay-title .main-text {
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    width: 100%;
}

.specilization-banner .overlay-title .main-text h1 {
    font-size: 30px;
    color: #ffff;
    font-weight: bold;
}

@media(min-width:0px) and (max-width:320px)
{
    .specilization-banner .overlay-title .main-text h1 {
        font-size: 18px;
    }
    .specilization-banner .ban-img {
        height: 190px;
    }
}
@media(min-width:321px) and (max-width:576px)
{
    .specilization-banner .overlay-title .main-text h1 {
        font-size: 20px;
    }
    .specilization-banner .ban-img {
        height: 190px;
    }
}
@media(min-width:577px) and (max-width:768px)
{
    .specilization-banner .overlay-title .main-text h1 {
        font-size: 22px;
    }
    .specilization-banner .ban-img {
        height: 190px;
    }
}
@media(min-width:769px) and (max-width:991px)
{
    .specilization-banner .overlay-title .main-text h1 {
        font-size: 24px;
    }
}
@media(min-width:992px) and (max-width:1200px)
{
    .specilization-banner .overlay-title .main-text h1 {
        font-size: 26px;
    }
}