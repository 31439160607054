/* Difference Css starts */
.Difference {
    margin-top: 8%;
}

.Difference .difference_heading {
    text-align: center;
    font: normal normal bold 28px/45px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    margin: 4%;
}

.Difference .differenceCard {
    background: #FFEEDC 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px 10px 0px 0px;
    border: 0;
    margin-bottom: 22px;
    height: 222px;
}

.Difference .differenceCard .cardBelowtxt {
    background: #fff;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    /* height: 72px; */
    text-align: center;
    font: normal normal bold 18px/23px Roboto;
    letter-spacing: 0px;
    color: #363636;
    padding: 15px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.Difference .differenceCard p {
    /* background-color: #FFEEDC; */
    font-size: 15px;
    padding: 15px;
}

.Difference .differenceCard .card-body {
    padding: 0;
}

@media screen and (min-width:0px) and (max-width: 991px) {
    .Difference .differenceCard {
        height: 192px;
    }

}


@media screen and (min-width:0px) and (max-width: 991px) {
    .Difference .difference_heading {
        font: normal normal bold 20px/28px Open Sans;
    }

    .Difference .differenceCard p {
        font-size: 12px;
    }

    .Difference .differenceCard .cardBelowtxt {
        font: normal normal bold 15px/23px Roboto;

    }

    .Qualified .qualifiedlefttxt p {
        font-size: 16px;
        margin-top: 4px;
    }


}

@media screen and (min-width:992px) and (max-width: 1200px) {
    .Difference .differenceCard .cardBelowtxt {
        font: normal normal bold 16px/23px Roboto;
    }

    .Difference .difference_heading {
        font: normal normal bold 25px/45px Open Sans;
    }

    .Difference .differenceCard p {
        font-size: 14px;
    }

    .Difference .differenceCard {
        height: 266px;
    }
}
@media screen and (min-width:1201px) and (max-width: 1400px)
{
    .Difference .differenceCard {
        height: 261px;
    }
}

/* Difference Css ends */