.who-qualified-ma {
    background-color: #FFF8F0;
    margin: 5% 0;
    padding-bottom: 5%;
}

.who-qualified-ma .heading-holder .title {
    font-size: 32px;
    font-weight: bold;
}

.who-qualified-ma .heading-holder p {
    font-size: 16px;
}

.who-qualified-ma .border-circle {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #000000B2;
    width: 86px;
    height: 86px;
    border-radius: 50%;
}

.who-qualified-ma .orange-circle {
    background: #FF8600BF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 2px #00000029;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    text-align: center;
    text-align: center;
    margin-left: 7px;
    margin-top: 7px;
}

.who-qualified-ma .orange-circle .icon-img {
    width: 42px;
    height: 42px;
    margin-top: 9px;
}

.who-qualified-ma .border-effect-ma .border-img {
    width: 412px;
    height: 125px;
}

.who-qualified-ma .border-effect-ma {
    position: relative;
}

.who-qualified-ma .border-effect-ma .text-heading {
    position: absolute;
    top: 18px;
    /* left: -14px; */
    padding: 21px;
}

.who-qualified-ma .border-effect-ma .text-heading h4 {
    font-size: 18px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2;
}

.who-qualified-ma .border-effect-ma .text-heading p {
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5;
    line-clamp: 5;

}

.who-qualified-ma .card {
    background-color: transparent;
    border: none;
}

.who-qualified-ma .card:hover .border-circle {
    border: 1px solid #FF8600BF;
}

.who-qualified-ma .card:hover .text-heading h4 {
    color: #FF8600BF;
}

@media(min-width:0px) and (max-width:359px) {
    .who-qualified-ma .border-effect-ma .border-img {
        width: 275px;
        height: 125px;
    }

    .who-qualified-ma .border-effect-ma .text-heading p {
        padding: 0px 0px !important;
    }

    .who-qualified-ma .border-effect-ma .text-heading {
        padding: 13px;
    }
}

@media(min-width:360px) and (max-width:420px) {
    .who-qualified-ma .border-effect-ma .border-img {
        width: 306px;
        height: 125px;
    }

    .who-qualified-ma .border-effect-ma .text-heading p {
        padding: 0px 8px !important;
    }


}

@media(min-width:421px) and (max-width:466px) {
    .who-qualified-ma .border-effect-ma .border-img {
        width: 358px;
        height: 125px;
    }

    .who-qualified-ma .border-effect-ma .text-heading p {
        padding: 0px 8px !important;
    }


}

@media(min-width:0px) and (max-width:767px) {
    .who-qualified-ma .border-effect-ma {
        text-align: center;
    }

    .who-qualified-ma .heading-holder .title {
        font-size: 20px;
    }

    .who-qualified-ma .border-effect-ma .text-heading p {
        font-size: 12px;
        padding: 0px 45px;
    }

    .who-qualified-ma .border-effect-ma .text-heading h4 {
        font-size: 15px;
    }

    .who-qualified-ma .heading-holder p {
        font-size: 14px;
    }
}

@media(min-width:768px) and (max-width:991px) {
    .who-qualified-ma .border-effect-ma .border-img {
        width: 327px;
        height: 147px;

    }

    .who-qualified-ma .border-effect-ma .text-heading p {
        font-size: 13px;
    }

    .who-qualified-ma .border-effect-ma .text-heading h4 {
        font-size: 16px;
    }

    .who-qualified-ma .heading-holder .title {
        font-size: 20px;
    }

    .who-qualified-ma .heading-holder p {
        font-size: 14px;
    }
}

@media(min-width:992px) and (max-width:1199px) {
    .who-qualified-ma .border-effect-ma .border-img {
        width: 286px;
        height: 147px;

    }

    .who-qualified-ma .border-effect-ma .text-heading p {
        font-size: 13px;
    }

    .who-qualified-ma .border-effect-ma .text-heading h4 {
        font-size: 16px;
    }

    .who-qualified-ma .heading-holder .title {
        font-size: 25px;
    }
}

@media(min-width:1200px) and (max-width:1400px) {
    .who-qualified-ma .border-effect-ma .border-img {
        width: 355px;

    }
}