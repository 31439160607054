/* footer css start */
.footer-section {
    background: #000000 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 50px 5px 10px 5px;
}

.footer-section h3 {
    color: #FF8600;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 17px;
}

.footer-section .logo-content {
    padding-right: 23%;
}

.footer-section .activeclass a {
    border-bottom: 1px solid #FF7700;
}

.footer-section .logo-content .footerlogo {
    width: 160px;
}

.footer-section .logo-content p.descript {
    color: #FFFf;
    font-size: 14px;
    font-weight: 300;
    margin-top: 19px;
}

.footer-section .links-sec .foot-menu {
    margin-bottom: 5px;
}

.footer-section .links-sec .foot-menu a {
    color: #FFFf;
    font-size: 14px;
    font-weight: 300;
}

.footer-section .contact-sec .icons-bg {
    background-color: #FF8600;
    height: 30px;
    min-width: 30px;
    border-radius: 50px;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-section .contact-sec p {
    color: #FFFf;
    font-size: 14px;
    font-weight: 300;
}

.footer-section .contact-sec .footer-icon-location {
    font-size: 14px;
    padding: 8px;
}

.footer-section .contact-sec .footer-icon {
    padding: 7px;
    font-size: 16px;
}

.footer-section .contact-sec .mail-text {
    color: #FFFf;
    font-size: 16px;
    font-weight: 300;
}

.footer-section .border {
    border-bottom: 1px solid white;
    width: 100%;
    margin: 35px 0px 25px 0px;
}

.footer-section .copyrights-div .profcyma-text {
    color: #FF7700;
    font-size: 16px;
    font-weight: 300;
}

.footer-section .copyrights-div p {
    color: #FFFf;
    font-size: 16px;
    font-weight: 300;
}

.footer-section .social-icons-div .icons {
    width: 30px;
    margin-right: 10px;
}

.footer-section .footuniname {
    color: #fff;
    margin-bottom: 5px;
    margin-top: 5px;
}

.footer-section .logo-icons {
    width: auto;
    height: 50px;
    object-fit: contain;
    margin-bottom: 10px;
    background: #fff;
    padding: 5px;
}

.footer-section .foot-menu.title .active {
    border-bottom: 1px solid #FF7700;
}

.footer-section .kiranpp{
    font-weight: 600;
}

/* media query start */
@media (min-width:0px) and (max-width:768px) {
    .footer-section h3 {
        font-size: 16px;
    }

    .footer-section .copyrights-div p {
        font-size: 14px;
    }

    .footer-section .copyrights-div .profcyma-text {
        font-size: 14px;
    }

    .footer-section .contact-sec p {
        font-size: 14px
    }

    .footer-section .contact-sec .icons-bg {
        min-height: 25px;
        width: 25px;
    }

    .footer-section .contact-sec .footer-icon {
        font-size: 12px;
    }

    .footer-section .contact-sec .footer-icon-location {
        font-size: 12px;
    }

    .footer-section .logo-content {
        padding-right: 0%;
    }

    .footer-section .logo-content .footerlogo {
        width: 120px;
    }

    .footer-section .logo-content p.descript {
        font-size: 13px;
    }

    .footer-section .links-sec .foot-menu a {
        font-size: 13px;
    }

    .footer-section .contact-sec .mail-text {
        font-size: 14px;
    }

    .footer-section .logo-icons {
        width: 100%;
    }
}



@media (min-width:769px) and (max-width:1200px) {
    .footer-section .links-sec .foot-menu a {
        font-size: 14px;
    }

    .footer-section .contact-sec p {
        font-size: 14px;
    }
}