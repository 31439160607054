@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");

body {
  margin: 0px;
  padding: 0px;
  font-family: "Roboto", "sans-serif" !important;
}

.title {
  font-family: "Open Sans", "sans-serif" !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.fade {
  transition: opacity 0s linear !important;
}

.error_message{
  font-size: 14px;
  margin-top: 3px;
}

.image-div .bca-img {
  width: 100% ;
}
/* .main .modal{
  backdrop-filter: brightness(2);
} */

/* Start Job portal Common Css  */

.jpsect_title {
  font-family: "Poppins", "sans-serif" !important;
  font-weight: 600;
  font-size: 35px;
  color: #000;
}

.jpsect_subtitle {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.heading {
  font-size: 20px;
  font-weight: 600;
}

.addvertisement-div {
  position: relative;
}

.addvertisement-div .add-banner {
  width: 100%;
}
.modal-apply-now {
  background-color: #000;
  color: #FFFF;
  border: 1px solid #000;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 5px;
  font-weight: 400;
  margin: 10px 0px;
}
.addvertisement-div .know-more-bt {
  border-radius: 5px;
  background: #ee9723;
  border: 0px;
  border-radius: 5px;
  padding: 7px 25px;
  color: #fff;
  font-size: 16px;
  position: absolute;
  left: 20px;
  bottom: 20px;
}

.addvertisement-div .know-more-bt:hover {
  background: #fff;
  color: #000;
}

.comp-search-in-div {
  position: relative;
  margin-bottom: 20px;
}

.comp-search-in-div .serch-comp-imppp {
  border-radius: 3px;
  background: #f8f8f8;
  border: 1px solid #8d8d8d;
  font-size: 13px;
  padding: 10px 35px;
}

.comp-search-in-div .ser-iccc {
  width: 15px;
  position: absolute;
  left: 10px;
  bottom: 14px;
}

.react-tel-input .selected-flag {
  background-color: #FFF;
  border: 1px solid #707070 !important;

}

.react-tel-input .form-control {
  width: 100% !important;
  border: 1px solid #707070 !important;

}


@media (min-width: 0px) and (max-width: 768px) {
  .jpsect_title {
    font-size: 20px;
  }

  .jpsect_subtitle {
    font-size: 12px;
  }

  .heading {
    font-size: 15px;
  }

  .addvertisement-div .know-more-bt {
    padding: 7px 10px;
    font-size: 13px;
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  .jpsect_title {
    font-size: 25px;
  }

  .jpsect_subtitle {
    font-size: 15px;
  }

  .heading {
    font-size: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1400px) {
  .jpsect_title {
    font-size: 28px;
  }

  .jpsect_subtitle {
    font-size: 16px;
  }

}

/* End Job portal Common Css  */

.mobile-view-banner {
  display: none;
}

.mobileview-small-banner {
  display: none;
}

@media(min-width:0px) and (max-width:768px) {
  .desktop-view-banner {
    display: none;
  }

  .mobile-view-banner {
    display: block;
  }

  .mobile-view-banner .banner-img1 {
    width: 100%;
    border-radius: 10px;
  }

  .desktopview-small-banner {
    display: none;
  }

  .mobileview-small-banner {
    display: block;
  }
}

@media(min-width:992px) and (max-width:1200px) {
  .addvertisement-div .know-more-bt {
    padding: 5px 19px;
  }
}



/* #j0hf117ve5ng1711978694294.widget-visible iframe{
  top: auto !important;
  right: 20px !important;
  bottom: 115px !important;
  left: 35px!important;
  position: fixed !important;
  border: 0 !important;
} */

.comsonn-title{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}



.pulsate-bck {
	-webkit-animation: pulsate-bck 1s ease-in-out infinite both;
	        animation: pulsate-bck 1s ease-in-out infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-4-11 13:7:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-bck
 * ----------------------------------------
 */
 @-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}



