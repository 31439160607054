.advantage-university {
    margin-top: 7%;
    margin-bottom: 4%;
    -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.advantage-university .title-advantage-university h3 {
    font-size: 32px;
    font-weight: bold;
    color: #000;
}

.advantage-university .advantage-title h5 {
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    margin-left: 30%;
}

.advantage-university .first-orange-box {
    background: #FFEAD5;
    box-shadow: 0px 0px 3px #00000073;
    width: 256px;
    height: 256px;
    transform: rotate(45deg);
    border-top-left-radius: 100px;
    position: relative;
}

.advantage-university .first-orange-box:hover {
    background: #FF8600;
    color: #FFF;
}

.advantage-university .first-orange-box .strategising-text p {
    transform: rotate(-22deg);
    font-size: 14px;
    position: absolute;
    top: 26%;
    left: 1%;
    width: 90%;
    padding: 10px;
    text-align: center;
    /* transition: 0.3s; */
}

.advantage-university .second-orange-box {
    background: #FFDBB7;
    box-shadow: 0px 0px 3px #00000073;
    width: 256px;
    height: 256px;
    transform: rotate(45deg);
    border-bottom-right-radius: 100px;
    margin-top: 150%;
    position: relative;
}

.advantage-university .second-orange-box:hover {
    background: #FF8600;
    color: #FFF;
}

.advantage-university .second-orange-box .strategising-text p {
    transform: rotate(-22deg);
    font-size: 14px;
    position: absolute;
    top: 28%;
    left: 3%;
    width: 87%;
    text-align: center;
    overflow-wrap: anywhere;
    /* transition: 0.3s; */
}

.advantage-university .third-orange-box {
    background: #FFBE6D;
    box-shadow: 0px 0px 3px #00000073;
    width: 256px;
    height: 256px;
    transform: rotate(45deg);
    border-top-left-radius: 100px;
    position: relative;
}

.advantage-university .third-orange-box .strategising-text p {
    transform: rotate(-45deg);
    font-size: 14px;
    position: absolute;
    top: 28%;
    left: 4%;
    width: 95%;
    text-align: center;
}


.advantage-university .four-orange-box {
    background: #FF9C38;
    box-shadow: 0px 0px 3px #00000073;
    width: 256px;
    height: 256px;
    transform: rotate(45deg);
    border-bottom-right-radius: 100px;
    margin-top: 131%;
    position: relative;
}

.advantage-university .four-orange-box .strategising-text p {
    transform: rotate(-45deg);
    font-size: 14px;
    position: absolute;
    top: 28%;
    left: 4%;
    width: 95%;
    text-align: center;
}

.advantage-university .five-orange-box {
    background: #FF8100;
    box-shadow: 0px 0px 3px #00000073;
    width: 256px;
    height: 256px;
    transform: rotate(45deg);
    border-top-left-radius: 100px;
    position: relative;
}

.advantage-university .five-orange-box .strategising-text p {
    transform: rotate(-45deg);
    font-size: 14px;
    position: absolute;
    top: 28%;
    left: 4%;
    width: 95%;
    text-align: center;
}

.advantage-university .black-border {
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: 3px solid #000;
    border-radius: 100px;
    width: 256px;
    height: 256px;
}

.advantage-university .black-img {
    width: 100%;
    margin-left: 17%;
    margin-top: 2%;
}

/* media query */

@media(min-width:0px) and (max-width:320px) {
    .advantage-university .first-orange-box {
        margin-left: 16%;
        margin-top: 14%;
        width: 200px;
        height: 200px;
    }



    .advantage-university .second-orange-box {
        margin-left: 16%;
        margin-top: 34%;
        width: 200px;
        height: 200px;
    }

    .advantage-university .third-orange-box {
        margin-left: 16%;
        margin-top: 14%;
        width: 200px;
        height: 200px;
    }

    .advantage-university .four-orange-box {
        margin-left: 16%;
        margin-top: 37%;
        width: 200px;
        height: 200px;
    }

    .advantage-university .five-orange-box {
        margin-left: 16%;
        margin-top: 10%;
        width: 200px;
        height: 200px;
        margin-bottom: 9%;
        left: 0 !important;
    }

    .advantage-university .black-img {
        display: none;
    }

    .advantage-university .advantage-title h5 {
        margin-left: 2%;
        margin-top: 5%;
    }

    .advantage-university .title-advantage-university h3 {
        font-size: 18px;
    }

    .advantage-university .first-orange-box .strategising-text p {
        font-size: 11px;
    }

    .advantage-university .second-orange-box .strategising-text p {
        font-size: 11px;
        top: 29%;
    }

    .advantage-university .third-orange-box .strategising-text p {
        font-size: 11px;
        top: 34%;
        left: 8%;
    }

    .advantage-university .four-orange-box .strategising-text p {
        font-size: 11px;
        top: 36%;
    }

    .advantage-university .five-orange-box .strategising-text p {
        font-size: 11px;
    }
}

@media(min-width:321px) and (max-width:375px) {
    .advantage-university .first-orange-box {
        margin-left: 21%;
        margin-top: 14%;
        width: 200px;
        height: 200px;
    }

    .advantage-university .second-orange-box {
        margin-left: 21%;
        margin-top: 34%;
        width: 200px;
        height: 200px;
    }

    .advantage-university .third-orange-box {
        margin-left: 21%;
        margin-top: 14%;
        width: 200px;
        height: 200px;
    }

    .advantage-university .four-orange-box {
        margin-left: 21%;
        margin-top: 37%;
        width: 200px;
        height: 200px;
    }

    .advantage-university .five-orange-box {
        margin-left: 22%;
        margin-top: 10%;
        width: 200px;
        height: 200px;
        margin-bottom: 9%;
    }

    .advantage-university .black-img {
        display: none;
    }

    .advantage-university .advantage-title h5 {
        margin-left: 2%;
        margin-top: 5%;
    }

    .advantage-university .title-advantage-university h3 {
        font-size: 20px;
    }

    .advantage-university .first-orange-box .strategising-text p {
        font-size: 11px;
    }

    .advantage-university .second-orange-box .strategising-text p {
        font-size: 11px;
        top: 29%;
    }

    .advantage-university .third-orange-box .strategising-text p {
        font-size: 11px;
        top: 34%;
        left: 8%;
    }

    .advantage-university .four-orange-box .strategising-text p {
        font-size: 11px;
        top: 36%;
    }

    .advantage-university .five-orange-box .strategising-text p {
        font-size: 11px;
        top: 13%;
    }

}

@media(min-width:376px) and (max-width:485px) {
    .advantage-university .first-orange-box {
        margin-left: 17%;
        margin-top: 12%;
    }

    .advantage-university .second-orange-box {
        margin-left: 17%;
        margin-top: 34%;
    }

    .advantage-university .third-orange-box {
        margin-left: 17%;
        margin-top: 11%;
    }

    .advantage-university .four-orange-box {
        margin-left: 17%;
        margin-top: 34%;
    }

    .advantage-university .five-orange-box {
        margin-left: 19%;
        margin-top: 10%;
        margin-bottom: 9%;
    }

    .advantage-university .black-img {
        display: none;
    }

    .advantage-university .advantage-title h5 {
        margin-left: 0%;
        margin-top: 6%;
    }

    .advantage-university .title-advantage-university h3 {
        font-size: 20px;
    }

    .advantage-university .first-orange-box .strategising-text p {
        font-size: 12px;
    }

    .advantage-university .second-orange-box .strategising-text p {
        font-size: 12px;
        top: 38%;
    }

    .advantage-university .third-orange-box .strategising-text p {
        font-size: 12px;
        top: 34%;
        left: 4%;
    }

    .advantage-university .four-orange-box .strategising-text p {
        font-size: 12px;
        top: 36%;
    }

    .advantage-university .five-orange-box .strategising-text p {
        font-size: 12px;
    }
}

@media(min-width:486px) and (max-width:576px) {
    .advantage-university .first-orange-box {
        margin-left: 24%;
        margin-top: 12%;
    }

    .advantage-university .second-orange-box {
        margin-left: 24%;
        margin-top: 26%;
    }

    .advantage-university .third-orange-box {
        margin-left: 24%;
        margin-top: 11%;
    }

    .advantage-university .four-orange-box {
        margin-left: 24%;
        margin-top: 26%;
    }

    .advantage-university .five-orange-box {
        margin-left: 24%;
        margin-top: 7%;
        margin-bottom: 9%;
    }

    .advantage-university .black-img {
        display: none;
    }

    .advantage-university .advantage-title h5 {
        margin-left: 0%;
        margin-top: 6%;
    }

    .advantage-university .title-advantage-university h3 {
        font-size: 20px;
    }

    .advantage-university .first-orange-box .strategising-text p {
        font-size: 12px;
    }

    .advantage-university .second-orange-box .strategising-text p {
        font-size: 12px;
        top: 38%;
    }

    .advantage-university .third-orange-box .strategising-text p {
        font-size: 12px;
        top: 34%;
        left: 4%;
    }

    .advantage-university .four-orange-box .strategising-text p {
        font-size: 12px;
        top: 36%;
    }

    .advantage-university .five-orange-box .strategising-text p {
        font-size: 12px;
    }
}

@media(min-width:577px) and (max-width:766px) {
    .advantage-university .first-orange-box {
        margin-left: 27%;
    }

    .advantage-university .second-orange-box {
        margin-left: 27%;
        margin-top: 25%;
    }

    .advantage-university .third-orange-box {
        margin-left: 27%;
    }

    .advantage-university .four-orange-box {
        margin-left: 27%;
        margin-top: 25%;
    }

    .advantage-university .five-orange-box {
        margin-left: 27%;
        margin-top: 8%;
    }

    .advantage-university .black-img {
        display: none;
    }

    .advantage-university .advantage-title h5 {
        margin-left: 5%;
        margin-bottom: 5%;
        margin-top: 5%;
    }

    .advantage-university .title-advantage-university h3 {
        font-size: 20px;
    }
}

@media(min-width:767px) and (max-width:768px) {
    .advantage-university .first-orange-box {
        margin-left: 34%;
    }

    .advantage-university .second-orange-box {
        margin-left: 34%;
        margin-top: 18%;
    }

    .advantage-university .third-orange-box {
        margin-left: 34%;
    }

    .advantage-university .four-orange-box {
        margin-left: 34%;
        margin-top: 18%;
    }

    .advantage-university .five-orange-box {
        margin-left: 34%;
    }

    .advantage-university .black-img {
        display: none;
    }

    .advantage-university .advantage-title h5 {
        margin-left: 5%;
        margin-bottom: 5%;
        margin-top: 5%;
    }

    .advantage-university .title-advantage-university h3 {
        font-size: 20px;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .advantage-university .first-orange-box {
        margin-left: 34%;
    }

    .advantage-university .second-orange-box {
        margin-left: 34%;
        margin-top: 18%;
    }

    .advantage-university .third-orange-box {
        margin-left: 34%;
    }

    .advantage-university .four-orange-box {
        margin-left: 34%;
        margin-top: 18%;
    }

    .advantage-university .five-orange-box {
        margin-left: 34%;
        margin-bottom: 5%;
    }

    .advantage-university .black-img {
        display: none;
    }

    .advantage-university .advantage-title h5 {
        margin-left: 5%;
        margin-bottom: 5%;
        margin-top: 5%;
    }

    .advantage-university .title-advantage-university h3 {
        font-size: 20px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .advantage-university .title-advantage-university h3 {
        font-size: 25px;
    }

    .advantage-university .first-orange-box {
        margin-left: 0%;
    }

    .advantage-university .black-img {
        display: none;
    }

    .advantage-university .five-orange-box {
        left: 61px !important;
    }

    .advantage-university .advantage-title h5 {
        margin-left: -8%;
        margin-bottom: 11%;
        margin-top: 10%;
    }

    .advantage-university .four-orange-box {
        margin-top: 35%;
    }

    .advantage-university .second-orange-box {
        margin-left: 0%;
        margin-top: 66%;
    }

    .advantage-university .second-orange-box .strategising-text p {
        font-size: 14px;
        left: 3%;
        overflow-wrap: anywhere;
        position: absolute;
        text-align: center;
        top: 24%;
        -webkit-transform: rotate(-22deg);
        transform: rotate(-22deg);
        width: 87%;
    }
}

@media(min-width:1201px) and (max-width:1399px) {
    .advantage-university .first-orange-box {
        margin-left: 16%;
    }

    .advantage-university .black-img {
        display: none;
    }

    .advantage-university .advantage-title h5 {
        margin-left: -8%;
        margin-bottom: 11%;
        margin-top: 10%;
    }

    .advantage-university .four-orange-box {
        margin-top: 35%;
    }

    .advantage-university .second-orange-box {
        margin-top: 57%;
        margin-left: 15%;

    }
}