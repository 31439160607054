.AmityEducation .EducationheadTextsec h3 {
    text-align: center;
    font-size: 32px;
    letter-spacing: 0px;
    color: #000000;
    font-family: 'Open Sans';
    font-weight: bold;
}

.AmityEducation {
    margin-bottom: 5%;
}

.EducationheadTextsec {
    margin: 4%;
}

.AmityEducation .EducationheadTextsec p {
    text-align: center;
    font: normal normal normal 16px/24px Roboto;
    letter-spacing: 0.16px;
    color: #383838;
    opacity: 1;
}

.AmityEducation .amityimg1 {
    width: 335px;
    height: 269px;
}

.AmityEducation .amityimg2 {
    width: 272px;
    height: 217px;
    position: absolute;
    bottom: -30px;
    right: 101px;
}

.AmityEducation .EduimgSec {
    display: flex;
    position: relative;
}

.AmityEducation .border-line {
    border-bottom: 3px solid #FF8600;
    margin: auto;
    width: 170px;
}


@media screen and (min-width:0px) and (max-width:320px) {
    .AmityEducation .amityimg2 {
        width: 100%;
        height: auto;
        position: unset;
        bottom: 0;
        right: 0;
    }

    .AmityEducation .amityimg1 {
        width: 100%;
        height: auto;
    }

    .AmityEducation .amity_eduPara p {
        font-size: 13px;
        margin-top: 5%;
        text-align: justify;
    }

    .AmityEducation .EducationheadTextsec h3 {
        font-size: 20px;
    }

    .AmityEducation .EducationheadTextsec p {
        font: normal normal normal 14px/24px Roboto;
    }
}

@media screen and (min-width:321px) and (max-width:485px) {
    .AmityEducation .amityimg2 {
        width: 100%;
        height: auto;
        position: unset;
        bottom: 0;
        right: 0;
    }

    .AmityEducation .amityimg1 {
        width: 100%;
        height: auto;
    }

    .AmityEducation .amity_eduPara p {
        font-size: 13px;
        margin-top: 5%;
        text-align: justify;
    }

    .AmityEducation .EducationheadTextsec h3 {
        font-size: 20px;
    }

    .AmityEducation .EducationheadTextsec p {
        font: normal normal normal 14px/24px Roboto;
    }
}

@media screen and (min-width:486px) and (max-width:767px) {
    .AmityEducation .amityimg2 {
        width: 100%;
        height: auto;
        position: unset;
        bottom: 0;
        right: 0;
    }

    .AmityEducation .amityimg1 {
        width: 100%;
        height: auto;
    }

    .AmityEducation .amity_eduPara p {
        font-size: 13px;
        margin-top: 5%;
        text-align: justify;
    }

    .AmityEducation .EducationheadTextsec h3 {
        font-size: 20px;
    }

    .AmityEducation .EducationheadTextsec p {
        font: normal normal normal 14px/24px Roboto;
    }
}

@media screen and (min-width:576px) and (max-width:767px) {
    .AmityEducation .amityimg2 {
        width: 100%;
        height: auto;
        position: unset;
        bottom: 0;
        right: 0;
    }

    .AmityEducation .amityimg1 {
        width: 100%;
        height: auto;
    }

    .AmityEducation .amity_eduPara p {
        font-size: 13px;
        margin-top: 5%;
        text-align: justify;
    }

    .AmityEducation .EducationheadTextsec h3 {
        font-size: 20px;
    }

    .AmityEducation .EducationheadTextsec p {
        font: normal normal normal 14px/24px Roboto;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

    .AmityEducation .EduimgSec {
        display: flex;
        position: relative;
        margin-bottom: 4%;
    }

    .AmityEducation .amityimg2 {
        width: 272px;
        height: 217px;
        position: absolute;
        bottom: 0;
        right: 154px;
    }

    .AmityEducation .amity_eduPara p {
        font-size: 15px;
    }

    .AmityEducation .amityOCheadtext h3 {
        font: normal normal bold 26px/38px Open Sans;
    }

    .AmityEducation .EducationheadTextsec h3 {
        font-size: 20px;
    }
}



@media screen and (min-width: 992px) and (max-width: 1200px) {
    .AmityEducation .amityimg2 {
        width: 272px;
        height: 217px;
        position: absolute;
        bottom: -30px;
        right: 0px;
    }

    .AmityEducation .EducationheadTextsec h3 {
        font-size: 25px;
    }
}