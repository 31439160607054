.con-section {
    margin: 70px 0px;
}

.con-section .con-main .content-main {
    text-align: center;
}

.con-section .con-main .content-main h2 {

    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.con-section .con-main .content-main p {
    font-size: 16px;
    line-height: 30px;
    font-weight: 300;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 8;
}

.con-section .con-main .second-content {
    text-align: center;
    margin-top: 100px;
}

.con-section .con-main .second-content h2 {
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.con-section .con-main .second-content p {
    font-size: 16px;
    line-height: 30px;
    font-weight: 300;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;
}

.con-section .con-main .content-table {
    display: flex;
    justify-content: center;
    padding-top: 35px;
}

.con-section .con-main table {
    border: solid 1px #000;
    padding: 10px;
}

.con-section .con-main th {
    border: solid 1px #000;
    padding: 10px;
}

.con-section .con-main td {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    border: solid 1px #000;
    padding: 10px 10px 10px 24px;
}

.con-section .con-main table {
    caption-side: bottom;
}

@media(min-width:0px) and (max-width:320px) {
    .con-section .con-main .content-main h2 {
        font-size: 22px;
    }

    .con-section .con-main .content-main p {
        font-size: 11px;
        line-height: 20px;
    }

    .con-section .con-main .second-content h2 {
        font-size: 22px;
    }

    .con-section .con-main .second-content p {
        font-size: 11px;
        line-height: 20px;
    }

    .con-section .con-main .second-content {
        margin-top: 17px;
    }

    .con-section {
        margin: 25px 0px;
    }

    .con-section .con-main td {
        font-size: 10px;
        padding: 7px 0px 7px 14px;
    }

    .con-section .con-main .content-table {
        padding-top: 20px;
    }

}

/* @media(min-width:0px) and (max-width:320px){
    .con-section .con-main .content-main h2 {
        font-size: 23px;
    }
    .con-section .con-main .content-main p {
        font-size: 11px;
        line-height: 20px;
    }
    .con-section .con-main .second-content h2 {
        font-size: 23px;
    }
    .con-section .con-main .second-content p {
        font-size: 11px;
        line-height: 20px;
    }

  } */
@media(min-width:321px) and (max-width:485px) {
    .con-section .con-main .content-main h2 {
        font-size: 23px;
    }

    .con-section .con-main .content-main p {
        font-size: 11px;
        line-height: 20px;
    }

    .con-section .con-main .second-content h2 {
        font-size: 23px;
    }

    .con-section .con-main .second-content p {
        font-size: 11px;
        line-height: 20px;
    }

    .con-section .con-main .second-content {
        margin-top: 17px;
    }

    .con-section {
        margin: 25px 0px;
    }

    .con-section .con-main td {
        font-size: 10px;
        padding: 8px 0px 8px 16px;
    }

    .con-section .con-main .content-table {
        padding-top: 20px;
    }


}

@media(min-width:486px) and (max-width:575px) {
    .con-section .con-main .content-main h2 {
        font-size: 25px;
    }

    .con-section .con-main .content-main p {
        font-size: 13px;
        line-height: 18px;
    }

    .con-section .con-main .second-content h2 {
        font-size: 25px;
    }

    .con-section .con-main .second-content p {
        font-size: 13px;
        line-height: 18px;
    }

    .con-section .con-main .second-content {
        margin-top: 20px;
    }

    .con-section {
        margin: 35px 0px;
    }

    .con-section .con-main td {
        font-size: 11px;
        padding: 8px 0px 8px 18px;
    }

    .con-section .con-main .content-table {
        padding-top: 25px;
    }

}

@media(min-width:576px) and (max-width:767px) {
    .con-section .con-main .content-main h2 {
        font-size: 25px;
    }

    .con-section .con-main .content-main p {
        font-size: 14px;
        line-height: 18px;
    }

    .con-section .con-main .second-content h2 {
        font-size: 25px;
    }

    .con-section .con-main .second-content p {
        font-size: 14px;
        line-height: 18px;
    }

    .con-section .con-main .second-content {
        margin-top: 25px;
    }

    .con-section {
        margin: 45px 0px;
    }

    .con-section .con-main td {
        font-size: 12px;
        padding: 8px 2px 8px 18px;
    }

    .con-section .con-main .content-table {
        padding-top: 25px;
    }

}

@media(min-width:768px) and (max-width:991px) {
    .con-section .con-main .content-main h2 {
        font-size: 27px;
    }

    .con-section .con-main .content-main p {
        font-size: 15px;
        line-height: 20px;
    }

    .con-section .con-main .second-content h2 {
        font-size: 27px;
    }

    .con-section .con-main .second-content p {
        font-size: 15px;
        line-height: 20px;
    }

    .con-section .con-main .second-content {
        margin-top: 30px;
    }

    .con-section {
        margin: 55px 0px;
    }

    .con-section .con-main td {
        font-size: 13px;
        padding: 8px 2px 8px 18px;
    }

    .con-section .con-main .content-table {
        padding-top: 30px;
    }

}

@media(min-width:992px) and (max-width:1200px) {
    .con-section .con-main .content-main h2 {
        font-size: 27px;
    }

    .con-section .con-main .content-main p {
        font-size: 16px;
        line-height: 19px;
    }

    .con-section .con-main .second-content h2 {
        font-size: 27px;
    }

    .con-section .con-main .second-content p {
        font-size: 16px;
        line-height: 19px;
    }

    .con-section .con-main .second-content {
        margin-top: 40px;
    }

    .con-section {
        margin: 75px 0px;
    }

    .con-section .con-main td {
        font-size: 14px;
        padding: 8px 2px 8px 18px;
    }

    .con-section .con-main .content-table {
        padding-top: 35px;
    }

}