.Mlib-Qualified-section .heading-div h5 {
    font-size: 32px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 15px;
}

.Mlib-Qualified-section {
    background: #FFF8F0 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 3% 0% 5% 0%;
}

.Mlib-Qualified-section .heading-div p {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

/* .Mlib-Qualified-section .mlib-swiper-div .icon-div
{
    transform: translateX(-100%);
  transition: transform 10s;
}
.animate-slide {
    transform: translateX(0);
  } */
.Mlib-Qualified-section .mlib-swiper-div .swiper-slide:nth-child(odd) .icon-div {
    background: #FF8600BF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 2px #00000029;
    border: 2px solid #FFFFFF;
    opacity: 1;
    width: 65px;
    height: 65px;
    border-radius: 100%;
}

.Mlib-Qualified-section .mlib-swiper-div .swiper-slide:nth-child(odd) .icon-div:hover {
    background: #000 0% 0% no-repeat padding-box;
}

.Mlib-Qualified-section .mlib-swiper-div .swiper-slide:nth-child(even) .icon-div {
    background: #FF8600BF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 2px #00000029;
    border: 2px solid #FF8600;
    opacity: 1;
    opacity: 1;
    width: 65px;
    height: 65px;
    border-radius: 100%;
}

.Mlib-Qualified-section .mlib-swiper-div .swiper-slide:nth-child(even) .icon-div:hover {
    background: #000 0% 0% no-repeat padding-box;
}

.Mlib-Qualified-section .mlib-swiper-div .icon-div .icon {
    width: 40px;
}

.Mlib-Qualified-section .mlib-swiper-div .icon-div .center-div {
    text-align: center;
    justify-content: center;
    display: flex;
    height: 100%;
    align-items: center;
}

.Mlib-Qualified-section .mlib-swiper-div .quali-mlib-card {
    margin: 0px 20px;
}

.Mlib-Qualified-section .mlib-swiper-div .quali-mlib-card .arrow-pointer {
    width: 100%;
    height: 52px;
    background: #E6E6E6;
    position: relative;
    margin-top: 8%;


    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 25px solid #FFF8F0;
        border-top: 25px solid transparent;
        border-bottom: 25px solid transparent;
        right: -1px;
        transition: ro;
        rotate: 181deg;
    }

    &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 25px solid #FFF8F0;
        border-top: 25px solid transparent;
        border-bottom: 25px solid transparent;
    }
}

.Mlib-Qualified-section .mlib-swiper-div .quali-mlib-card .arrow-pointer .card-heading h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 0px;
    color: #000000;
    padding: 0px 35px;
}

.Mlib-Qualified-section .mlib-swiper-div .quali-mlib-card .arrow-pointer .card-heading {
    text-align: center;
    justify-content: center;
    display: flex;
    height: 100%;
    align-items: center;
}

.Mlib-Qualified-section .mlib-swiper-div .quali-mlib-card .descript p {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    text-align: center;
    padding: 13px 7px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5;
    line-clamp: 5;
}

.Mlib-Qualified-section .mlib-swiper-div .swiper-pagination-bullet-active {
    background: #000;
    width: 17px !important;
    height: 4px;
    border-radius: 5px;
    margin: 2px !important;
}

.Mlib-Qualified-section .mlib-swiper-div .swiper-pagination-bullet {
    background: #000;
    width: 10px;
    height: 4px;
    border-radius: 5px;
    margin: 2px !important;
}

.Mlib-Qualified-section .mlib-swiper-div .swiper-wrapper {
    padding: 20px 5px;
}

.Mlib-Qualified-section .bottom-title p {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    text-align: center;
}


@media(min-width:0px) and (max-width:768px) {
    .Mlib-Qualified-section .heading-div h5 {
        font-size: 20px;
    }

    .Mlib-Qualified-section .heading-div p {
        font-size: 13px;
    }

    .Mlib-Qualified-section .mlib-swiper-div .quali-mlib-card .arrow-pointer .card-heading h3 {
        font-size: 16px;
    }

    .Mlib-Qualified-section .mlib-swiper-div .quali-mlib-card .descript p {
        font-size: 13px;
    }

    .Mlib-Qualified-section .bottom-title p {
        font-size: 14px;
    }
}