.mbadetails-section {
    margin: 0px 0px 5% 0px;
}

.mbadetails-section .mbadetails-main .leftsection .blog-main-card .img-div {
    overflow: hidden;
}


.mbadetails-section .mbadetails-main .leftsection .blog-main-card .imagemain {
    width: 100%;
    height:100%;
    transition: all 0.3s;
    aspect-ratio: 1/1;
}

.mbadetails-section .mbadetails-main .leftsection .blog-main-card {
    position: relative;
    background: #FFF8F0 0% 0% no-repeat padding-box;
    border-radius: 0px 20px 20px 0px;
    opacity: 1;
    margin-bottom: 70px;
    overflow: hidden;
}

.mbadetails-section .mbadetails-main .leftsection .cont-div {
    padding: 30px 18px 5px 30px;
}

.mbadetails-section .mbadetails-main .leftsection .heading-main .Heading-cards {
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
}

.mbadetails-section .mbadetails-main .leftsection .blog-main-card .readmoreclass {
    position: absolute;
    bottom: 10%;
    left: 0;
}

.mbadetails-section .mbadetails-main .leftsection .blog-main-card .readmoreclass .readMorebutton {
    border: none;
    background: #FF8600BF 0% 0% no-repeat padding-box;
    width: 133px;
    height: 34px;
    border-radius: 0px 8px 8px 0px;
    opacity: 1;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.mbadetails-section .mbadetails-main .leftsection .blog-main-card:hover {
    box-shadow: 0px 3px 6px #00000029;
  transition-delay: 0.3s;
}

.mbadetails-section .mbadetails-main .leftsection .blog-main-card:hover .readmoreclass .readMorebutton {
    background: #FFFFFFBF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    opacity: 1;
}

.mbadetails-section .mbadetails-main .leftsection .blog-main-card .heading-main {
    border-left: 2px solid transparent;
}

.mbadetails-section .mbadetails-main .leftsection .blog-main-card:hover .heading-main {
    border-left: 2px solid #FFA23C;
}

.mbadetails-section .mbadetails-main .leftsection .blog-main-card:hover .imagemain {
    transform: scale(1.2);
    filter: brightness(1);

}

/* scroll section css */
.mbadetails-section .mbadetails-main .rightside .scoll-1 .heads h5 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.mbadetails-section .mbadetails-main .rightside .scoll-1 .scroll-imagediv .scroll-image {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.mbadetails-section .mbadetails-main .rightside .scroll-cont .scroll-top-con .top-p {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #383838;
    opacity: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    margin-bottom: 0px;
}

.mbadetails-section .mbadetails-main .rightside .scroll-cont .scroll-bottom-con{
   
    font-size: 14px;
    font-weight: 400;

}
.mbadetails-section .mbadetails-main .rightside .scroll-cont .scroll-p{
    color: #9A9A9A;;
}
.mbadetails-section .mbadetails-main .rightside .scroll-cont .scroll-p2{
    color: #FF8600BF;
}
.mbadetails-section .mbadetails-main .rightside .scrollbar-div{
    width: 100%;
    height: 250px;
    overflow-y: scroll;
    overflow-x: clip;
}
.scoll-1{
    margin-bottom: 30px;
}

@media screen and (min-width: 0px) and (max-width: 320px) {
    .mbadetails-section {
        margin: 30px 0px 30px 0px;
    }
    .mbadetails-section .mbadetails-main .leftsection .blog-main-card .imagemain {
        height: 130px;
    }
    .mbadetails-section .mbadetails-main .leftsection .blog-main-card .readmoreclass .readMorebutton {
        width: 75px;
        height: 20px;
        font-size: 10px;
    }
    .mbadetails-section .mbadetails-main .leftsection .cont-div {
        padding: 15px 1px 1px 10px;
    }
    .mbadetails-section .mbadetails-main .leftsection .heading-main .Heading-cards {
        font-size: 13px;
    }
    .mbadetails-section .mbadetails-main .leftsection .blog-main-card {
        border-radius: 0px 12px 12px 0px;
        margin-bottom: 15px;
    }
    .mbadetails-section .mbadetails-main .rightside .scroll-cont .scroll-top-con .top-p {
        font-size: 11px;
    }
    .mbadetails-section .mbadetails-main .rightside .scroll-cont .scroll-bottom-con{
        font-size: 11px;
    
    }
}
@media screen and (min-width: 321px) and (max-width: 375px) {
    .mbadetails-section {
        margin: 30px 0px 30px 0px;
    }
    .mbadetails-section .mbadetails-main .leftsection .blog-main-card .imagemain {
        height: 130px;
    }
    .mbadetails-section .mbadetails-main .leftsection .blog-main-card .readmoreclass .readMorebutton {
        width: 75px;
        height: 20px;
        font-size: 10px;
    }
    .mbadetails-section .mbadetails-main .leftsection .cont-div {
        padding: 15px 1px 1px 10px;
    }
    .mbadetails-section .mbadetails-main .leftsection .heading-main .Heading-cards {
        font-size: 13px;
    }
    .mbadetails-section .mbadetails-main .leftsection .blog-main-card {
        border-radius: 0px 12px 12px 0px;
        margin-bottom: 15px;
    }
    .mbadetails-section .mbadetails-main .rightside .scroll-cont .scroll-top-con .top-p {
        font-size: 11px;
    }
    .mbadetails-section .mbadetails-main .rightside .scroll-cont .scroll-bottom-con{
        font-size: 11px;
    
    }
}
@media screen and (min-width: 376px) and (max-width: 485px) {
    .mbadetails-section {
        margin: 30px 0px 30px 0px;
    }
    .mbadetails-section .mbadetails-main .leftsection .blog-main-card .imagemain {
        height: 160px;
    }
    .mbadetails-section .mbadetails-main .leftsection .blog-main-card .readmoreclass .readMorebutton {
        width: 92px;
        height: 23px;
        font-size: 12px;
    }
    .mbadetails-section .mbadetails-main .leftsection .cont-div {
        padding: 20px 1px 0px 10px;
    }
    .mbadetails-section .mbadetails-main .leftsection .heading-main .Heading-cards {
        font-size: 15px;
    }
    .mbadetails-section .mbadetails-main .leftsection .blog-main-card {
        border-radius: 0px 12px 12px 0px;
        margin-bottom: 15px;
    }
    .mbadetails-section .mbadetails-main .rightside .scroll-cont .scroll-top-con .top-p {
        font-size: 13px;
    }
    .mbadetails-section .mbadetails-main .rightside .scroll-cont .scroll-bottom-con{
        font-size: 13px;
    
    }
}
@media screen and (min-width: 486px) and (max-width: 575px) {
    .mbadetails-section {
        margin: 30px 0px 30px 0px;
    }
    .mbadetails-section .mbadetails-main .leftsection .blog-main-card .imagemain {
        height: 190px;
    }
    .mbadetails-section .mbadetails-main .leftsection .blog-main-card .readmoreclass .readMorebutton {
        width: 100px;
        height: 27px;
        font-size: 15px;
    }
    .mbadetails-section .mbadetails-main .leftsection .cont-div {
        padding: 23px 1px 0px 15px;
    }
    .mbadetails-section .mbadetails-main .leftsection .heading-main .Heading-cards {
        font-size: 18px;
    }
    .mbadetails-section .mbadetails-main .leftsection .blog-main-card {
        border-radius: 0px 12px 12px 0px;
        margin-bottom: 15px;
    }
    .mbadetails-section .mbadetails-main .rightside .scroll-cont .scroll-top-con .top-p {
        font-size: 13px;
    }
    .mbadetails-section .mbadetails-main .rightside .scroll-cont .scroll-bottom-con{
        font-size: 13px;
    
    }
}
@media screen and (min-width: 576px) and (max-width: 767px) {

    .mbadetails-section {
        margin: 40px 0px 40px 0px;
    }
    .mbadetails-section .mbadetails-main .leftsection .heading-main .Heading-cards {
        font-size: 19px;
    }
    .mbadetails-section .mbadetails-main .leftsection .blog-main-card .readmoreclass .readMorebutton {
        width: 120px;
        height: 30px;
        font-size: 16px;
    }
    .mbadetails-section .mbadetails-main .leftsection .cont-div {
        padding: 23px 1px 0px 15px;
    }
    .mbadetails-section .mbadetails-main .leftsection .blog-main-card {
        border-radius: 0px 18px 18px 0px;
        margin-bottom: 20px;
    }
    .mbadetails-section .mbadetails-main .rightside .scroll-cont .scroll-top-con .top-p {
        font-size: 11px;
    }
    .mbadetails-section .mbadetails-main .rightside .scroll-cont .scroll-bottom-con{
        font-size: 11px;
    
    }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    .mbadetails-section .mbadetails-main .leftsection .blog-main-card .imagemain {
        height: 280px;
    }
    .mbadetails-section {
        margin: 40px 0px 40px 0px;
    }
    .mbadetails-section .mbadetails-main .leftsection .heading-main .Heading-cards {
        font-size: 24px;
    }
    .mbadetails-section .mbadetails-main .leftsection .blog-main-card .readmoreclass .readMorebutton {
        width: 133px;
        height: 33px;
        font-size: 18px;
    }
    .mbadetails-section .mbadetails-main .leftsection .cont-div {
        padding: 26px 1px 0px 16px;
    }
    .mbadetails-section .mbadetails-main .leftsection .blog-main-card {
        border-radius: 0px 18px 18px 0px;
        margin-bottom: 20px;
    }
    .mbadetails-section .mbadetails-main .rightside .scroll-cont .scroll-top-con .top-p {
        font-size: 12px;
    }
    .mbadetails-section .mbadetails-main .rightside .scroll-cont .scroll-bottom-con{
        font-size: 12px;
    
    }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
    .mbadetails-section .mbadetails-main .leftsection .blog-main-card .readmoreclass .readMorebutton {
        width: 105px;
        height: 24px;
        font-size: 13px;
    }
    .mbadetails-section .mbadetails-main .leftsection .blog-main-card .imagemain {
        height: 180px;
    }
    .mbadetails-section .mbadetails-main .leftsection .heading-main .Heading-cards {
        font-size: 17px;
    }
    .mbadetails-section .mbadetails-main .rightside .scrollbar-div {
        height: 160px;
    }
    .scoll-1 {
        margin-bottom: 53px;
    }
    .mbadetails-section .mbadetails-main .leftsection .cont-div {
        padding: 18px 1px 0px 16px;
    }
    .mbadetails-section .mbadetails-main .rightside .scroll-cont .scroll-top-con p {
        font-size: 10px;
    }
    .mbadetails-section .mbadetails-main .rightside .scroll-cont .scroll-top-con .top-p {
        font-size: 11px;
    }
    .mbadetails-section .mbadetails-main .rightside .scroll-cont .scroll-bottom-con{
        font-size: 11px;
    
    }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
    .mbadetails-section .mbadetails-main .leftsection .blog-main-card .readmoreclass .readMorebutton {
        width: 133px;
        height: 31px; 
    }
    .mbadetails-section .mbadetails-main .leftsection .blog-main-card .imagemain {
        height: 210px;
    }
    .mbadetails-section .mbadetails-main .leftsection .heading-main .Heading-cards {
        font-size: 22px;
    }
    .mbadetails-section .mbadetails-main .rightside .scrollbar-div {
        height: 188px;
    }
    .scoll-1 {
        margin-bottom: 53px;
    }
    .mbadetails-section .mbadetails-main .rightside .scroll-cont .scroll-top-con p {
        font-size: 12px;
    }
    .mbadetails-section .mbadetails-main .rightside .scroll-cont .scroll-top-con .top-p {
        font-size: 11px;
    }
    .mbadetails-section .mbadetails-main .rightside .scroll-cont .scroll-bottom-con{
        font-size: 11px;
    
    }
}
