.Specialization-Syllabus {
    margin: 5% 0;
    background-image: url('../../../../../public/assets/images/spaecialization/abstractbackground.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px 0px;
}

.Specialization-Syllabus .heading-holder h2 {
    font-size: 20px;
    font-weight: 500;
}

.Specialization-Syllabus .heading-holder .border-line {
    border-bottom: 2px solid #FF9900;
    width: 77px;
    margin: auto;
}

.Specialization-Syllabus .nav-pills .nav-link {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 6px;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    width: 300px;
}

.Specialization-Syllabus .nav-pills .nav-link.active {
    color: #000;
    background-color: #FFF;
    border-left: 8px solid #F99903;
    border-radius: 6px 0px 0px 6px;
    width: 100%;
}

.Specialization-Syllabus .accordion-item {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 8px;
    border: none;
}

.Specialization-Syllabus .accordion-item .border-header {
    border-bottom: 1px solid #000;
    width: 60%;
    margin-left: 20px;
}

.Specialization-Syllabus .accordion-button {
    color: #000;
    background-color: #FFF;
    font-size: 14px;
}

.Specialization-Syllabus .accordion-button:not(.collapsed) {
    color: #000;
    background-color: #FFF;
    font-size: 14px;
    box-shadow: none;
}

.Specialization-Syllabus .accordion-button:focus {
    box-shadow: none;
}

.Specialization-Syllabus .accordion-button:not(.collapsed)::after {
    background-image: url('../../../../../public/assets/images/spaecialization/minus-icon.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.Specialization-Syllabus .accordion-button::after {
    background-image: url('../../../../../public/assets/images/spaecialization/plus-icon.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.Specialization-Syllabus .accordion-body .subject-holder ul li {
    font-size: 14px;
}

@media(min-width:0px) and (max-width:767px) {
    .Specialization-Syllabus .nav-pills .nav-link {
        width: auto;
    }

    .Specialization-Syllabus .nav-pills .nav-link.active {
        width: auto;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .Specialization-Syllabus .nav-pills .nav-link {
        width: 213px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .Specialization-Syllabus .nav-pills .nav-link {
        width: 213px;
    }
}

@media(min-width:1200px) and (max-width:1400px) {
    .Specialization-Syllabus .nav-pills .nav-link {
        width: 268px;
    }
}