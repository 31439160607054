.ChooseMba .chooseimgsec {
    position: relative;

}

.ChooseMba .chooseimgsec .banner_img {
    width: 100%;
    height: 721px;
}

.ChooseMba .ChoosembaCards {
    position: absolute;
    top: 50%;
    width: 100%;
    left: 0px;
    padding-left: 5%;
    transform: translate(0px, -50%);
}

.ChooseMba .Choosembanumber {
    background: #FF8600BF 0% 0% no-repeat padding-box;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    font-size: 20px;
    color: #fff;
    padding: 3px;
    text-align: center;
}

.ChooseMba .ChoosembaCards .card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #00000029;
    border-radius: 15px;
    border: 0;
    height: 213px;
    margin-bottom: 5%;
    position: relative;
}

.ChooseMba .ChoosembaCards .card:hover {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #FF860059;
    border-radius: 15px;
    opacity: 1;
    transition: 0.5s ease;
}

.ChooseMba .ChoosembaCards .card-body h3 {
    text-align: left;
    font: normal normal bold 18px/30px Roboto;
    letter-spacing: 0px;
    color: #000000;
    margin: 0px;
}

.ChooseMba .ChoosembaCards .card-body {
    padding: 5px 7px;
}

.ChooseMba .ChoosembaCards .card-body .grey-border {
    width: 0px;
    border-bottom: 1px solid #000000;
    transition: 0.2s ease;
}

.ChooseMba .ChoosembaCards .card:hover .grey-border {
    width: 100%;
    border-bottom: 1px solid #000000;
    transition: 0.5s ease;
}

.ChooseMba .ChoosembaCards .card-body .border-content {
    width: fit-content;
}


.ChooseMba .ChoosembaCards .card-body p {
    text-align: left;
    font: normal normal normal 14px/25px Roboto;
    letter-spacing: 0px;
    color: #383838;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
}

.ChooseMba .choosembaheadersec h3 {
    text-align: left;
    font-size: 32px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 20px;
}

.ChooseMba .choosembaheadersec p {
    text-align: left;
    color: #0D0D0D;
    margin-bottom: 20px;
}

.ChooseMba .chooseimgsec .maChoosecardIcon {
    width: 44px;
    height: 46px;
}

.ChooseMba .ChoosembaCards .card-body .hover-img {
    position: absolute;
    bottom: 3px;
    right: 3px;
    opacity: 0;
    transform: translate(40%, 40%) scale(0);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.ChooseMba .ChoosembaCards .card:hover .hover-img {
    opacity: 0.3;
    transform: translate(0, 0) scale(1);
}

.ChooseMba .ChoosembaCards .card-body .hover-img .hoverIcon {
    width: 40px;

}



@media screen and (min-width: 575px) and (max-width: 767px) {}

@media screen and (min-width: 0px) and (max-width: 768px) {


    .ChooseMba .ChoosembaCards {
        position: unset;
        padding-left: 5%;
        transform: translate(0px, 0%);
    }

    .ChooseMba .chooseimgsec .banner_img {
        width: 100%;
        display: none;
    }

    .ChooseMba .choosembaheadersec h3 {
        text-align: center;
        font-size: 20px;
    }

    .ChooseMba .choosembaheadersec p {
        text-align: justify;
        font-size: 14px;
    }

    .ChooseMba .ChoosembaCards .card-body h3 {
        font: normal normal bold 16px/30px Roboto;
    }

    .ChooseMba .ChoosembaCards .card-body p {
        font: normal normal normal 12px/25px Roboto;
    }
}

@media screen and (min-width: 769px) and (max-width: 991px) {
    .ChooseMba .ChoosembaCards {
        position: unset;
        padding-left: 5%;
        transform: translate(0px, 0%);
    }

    .ChooseMba .chooseimgsec .banner_img {
        width: 100%;
        display: none;
    }

    .ChooseMba .choosembaheadersec h3 {
        text-align: center;
        font-size: 25px;
    }
}


@media screen and (min-width: 992px) and (max-width: 1200px) {
    .ChooseMba .ChoosembaCards .card-body p {
        text-align: justify;
        font: normal normal normal 12px/20px Roboto;
    }

    .ChooseMba .ChoosembaCards .card-body h3 {
        font: normal normal bold 14px/21px Roboto;
    }

    .ChooseMba .choosembaheadersec p {
        font-size: 14px;
    }

    .ChooseMba .choosembaheadersec h3 {
        font-size: 27px;
    }

    .ChooseMba .chooseimgsec .maChoosecardIcon {
        width: 30px;
        height: 30px;
    }

    .ChooseMba .Choosembanumber {
        width: 30px;
        height: 30px;
        padding: 0px;
    }


}