.who-qualified-msc {
    padding: 4% 0;
    background: #FFF8F0;
    padding-bottom: 5%;
    position: relative;
}

.who-qualified-msc .heading-holder .title {
    font-size: 32px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    line-clamp: 1;
}

.who-qualified-msc .heading-holder p {
    font-size: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    line-clamp: 3;
}

.who-qualified-msc .swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.who-qualified-msc .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px !important;
    background-color: #FFF;
    box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2);
    filter: blur(2px);
}

.who-qualified-msc .swiper-slide-active {
    filter: blur(0px);
    background-color: #FFF;
}

.who-qualified-msc .swiper-slide img {
    display: block;
    width: 100%;
}

.who-qualified-msc .card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: none;
    border-radius: 0%;
    height: 100%;
    /* min-height: 363px !important; */

}

.who-qualified-msc .orange-circle {
    background: #FF8600BF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 2px #00000029;
    width: 70px;
    height: 70px;
    border-radius: 50%;

}

.who-qualified-msc .orange-circle .icon-img {
    width: 42px;
    height: 42px;
    margin-top: 13px;
    margin-left: 15px;
}

.who-qualified-msc .text-heading h4 {
    font-size: 18px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2;
}

.who-qualified-msc .text-heading p {
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5;
    line-clamp: 5;

}

.who-qualified-msc .content-holder p {
    font-size: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2;
}

.who-qualified-msc .content-holder span {
    font-size: 18px;
    font-weight: 600;

}

.who-qualified-msc .main-slider {
    position: relative;
}

.who-qualified-msc .main-slider .arrow-back,
.who-qualified-msc .main-slider .arrow-next {
    cursor: pointer;
}

.who-qualified-msc .main-slider .main-arrow-back {
    position: absolute;
    top: -245px;
    left: 37%;
    z-index: 1;
    cursor: pointer;
    background-color: black;
    width: 30px;
    height: 30px;
    border-radius: 50px;

}


.who-qualified-msc .main-slider .main-arrow-back:hover,
.who-qualified-msc .main-slider .main-arrow-next:hover {
    background-color: #ff8600;
    transition-delay: 0.2s;
}

.who-qualified-msc .main-slider .arrow-back {
    content: url("/public/assets/images/icons/prev.png");
    width: 8px;
    margin: 9px;
}

.who-qualified-msc .main-slider .main-arrow-next {
    position: absolute;
    top: -245px;
    right: 37%;
    z-index: 1;
    cursor: pointer;
    background-color: black;
    width: 30px;
    height: 30px;
    border-radius: 50px;

}

.who-qualified-msc .main-slider .arrow-next {
    content: url("/public/assets/images/icons/next.png");
    width: 8px;
    margin: 9px 9px 9px 12px;
}

@media(min-width:0px) and (max-width:320px) {
    .who-qualified-msc .main-slider .main-arrow-next {
        width: 22px;
        height: 22px;
        top: -247px;
        right: 0% !important;
        display: block;
    }

    .who-qualified-msc .main-slider .main-arrow-back {
        width: 22px;
        height: 22px;
        top: -247px;
        left: 0% !important;
        display: block;
    }
}

@media(min-width:321px) and (max-width:485px) {
    .who-qualified-msc .main-slider .main-arrow-next {
        width: 22px;
        height: 22px;
        top: -247px;
        right: 13% !important;
        display: block;
    }

    .who-qualified-msc .main-slider .main-arrow-back {
        width: 22px;
        height: 22px;
        top: -247px;
        left: 13% !important;
        display: block;
    }
}

@media(min-width:486px) and (max-width:576px) {
    .who-qualified-msc .main-slider .main-arrow-next {
        width: 22px;
        height: 22px;
        top: -247px;
        right: 20% !important;
        display: block;
    }

    .who-qualified-msc .main-slider .main-arrow-back {
        width: 22px;
        height: 22px;
        top: -247px;
        left: 20% !important;
        display: block;
    }
}

@media(min-width:578px) and (max-width:767px) {
    .who-qualified-msc .main-slider .main-arrow-next {
        width: 22px;
        height: 22px;
        top: -247px;
        right: 20% !important;
        display: block;
    }

    .who-qualified-msc .main-slider .main-arrow-back {
        width: 22px;
        height: 22px;
        top: -247px;
        left: 20% !important;
        display: block;
    }
}

@media(min-width:0px) and (max-width:768px) {
    .who-qualified-msc .main-slider .arrow-next {
        width: 5px;
        margin: 7px 6px 6px 9px;
    }

    .who-qualified-msc .main-slider .main-arrow-next {
        width: 22px;
        height: 22px;
        top: -247px;
        right: 27%;
        display: block;
    }

    .who-qualified-msc .main-slider .main-arrow-back {
        width: 22px;
        height: 22px;
        top: -247px;
        left: 27%;
        display: block;
    }

    .who-qualified-msc .main-slider .arrow-back {
        width: 5px;
        margin: 7px 6px 6px 9px;
    }

    .who-qualified-msc .heading-holder .title {
        font-size: 20px;
    }

    .who-qualified-msc .heading-holder p {
        font-size: 14px;
    }

    .who-qualified-msc .text-heading h4 {
        font-size: 16px;
    }

    .who-qualified-msc .text-heading p {
        font-size: 12px;
    }
}

@media(min-width:768px) and (max-width:991px) {
    .who-qualified-msc .heading-holder .title {
        font-size: 20px;
    }

    .who-qualified-msc .heading-holder p {
        font-size: 14px;
    }

    .who-qualified-msc .main-slider .main-arrow-next {
        right: 27%;

    }

    .who-qualified-msc .main-slider .main-arrow-back {
        left: 27%;

    }
}

@media(min-width:992px) and (max-width:1200px) {
    .who-qualified-msc .heading-holder .title {
        font-size: 25px;
    }

    .who-qualified-msc .main-slider .main-arrow-next {
        top: -241px;
        right: 32%;

    }

    .who-qualified-msc .main-slider .main-arrow-back {
        position: absolute;
        top: -241px;
        left: 32%;

    }
}