.admission-procedure {
    margin: 0 0 5% 0;
}

.admission-procedure .text-holder-procedure h2 {
    font-size: 32px;
    color: #000000;
    font-weight: bold;
    text-align: center;
}

.admission-procedure .text-holder-procedure p {
    font-size: 16px;
    text-align: center;
    color: #000000;
    font-weight: 500;
}

.admission-procedure .step-swiper {
    margin: 2% 0 5% 0;
}

.admission-procedure .step-swiper .main-card {
    position: relative;
    margin-top: 20%;
    height: 239px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px #00000029;
    border: none;
    margin-bottom: 30%;
    border-radius: 0%;
}

.admission-procedure .step-swiper .main-card .orange-bg {
    background: #FF8600;
    height: 78px;
    width: 78px;
    position: absolute;
    top: -14%;
    left: 35%;
    box-shadow: 0px 0px 5px #00000029;
    border-radius: 50%;
    text-align: center;
    padding: 9px;
}

.admission-procedure .step-swiper .main-card .orange-bg .card-img-class {
    width: 58px;
    height: 58px;
    padding: 4px;
}

.admission-procedure .step-swiper .main-card .step-title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10%;
    margin-top: 15%;
    text-align: center;
    height: 40px;
}

.admission-procedure .step-swiper .main-card .card-text {
    font-size: 14px;
    color: #383838;
    padding: 0;
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    font-weight: 500;
}

.admission-procedure .step-swiper .swiper-pagination-bullet {
    width: 10px;
    height: 4px;
    border-radius: 5px;
    opacity: 1;
    background-color: #CECECE;
}

.admission-procedure .step-swiper .swiper-pagination-bullet-active {
    width: 18px;
    background: #000;
}

/* Media Query */

@media(min-width:0px) and (max-width:320px) {
    .admission-procedure .text-holder-procedure h2 {
        font-size: 20px;
    }

    .admission-procedure .text-holder-procedure p {
        font-size: 12px;
    }

    .admission-procedure .step-swiper .main-card {

        margin-bottom: 20%;
        height: 205px;
    }

    .admission-procedure .step-swiper .main-card .step-title {
        font-size: 16px;
    }

    .admission-procedure .step-swiper .main-card .card-text {
        font-size: 12px;
    }

    .admission-procedure .step-swiper .main-card .orange-bg {
        top: -17%;
        left: 36%;
    }
}

@media(min-width:421px) and (max-width:485px) {
    .admission-procedure .step-swiper .main-card .orange-bg {

        left: 39% !important;
    }
}

@media(min-width:321px) and (max-width:485px) {
    .admission-procedure .text-holder-procedure h2 {
        font-size: 20px;
    }

    .admission-procedure .text-holder-procedure p {
        font-size: 13px;
    }

    .admission-procedure .step-swiper .main-card {

        margin-bottom: 20%;
        height: 205px;
    }

    .admission-procedure .step-swiper .main-card .step-title {
        font-size: 17px;
    }

    .admission-procedure .step-swiper .main-card .card-text {
        font-size: 13px;
    }

    .admission-procedure .step-swiper .main-card .orange-bg {
        top: -17%;
        left: 36%;
    }
}

@media(min-width:486px) and (max-width:576px) {
    .admission-procedure .text-holder-procedure h2 {
        font-size: 20px;
    }

    .admission-procedure .text-holder-procedure p {
        font-size: 13px;
    }

    .admission-procedure .step-swiper .main-card {

        margin-bottom: 20%;
        height: 205px;
    }

    .admission-procedure .step-swiper .main-card .step-title {
        font-size: 17px;
    }

    .admission-procedure .step-swiper .main-card .card-text {
        font-size: 13px;
    }

    .admission-procedure .step-swiper .main-card .orange-bg {
        left: 43%;
    }
}

@media(min-width:577px) and (max-width:768px) {
    .admission-procedure .text-holder-procedure h2 {
        font-size: 20px;
    }

    .admission-procedure .step-swiper .main-card {

        height: 216px;
        margin-bottom: 15%;
    }

    .admission-procedure .step-swiper .main-card .orange-bg {
        top: -17%;
        left: 38%;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .admission-procedure .text-holder-procedure h2 {
        font-size: 20px;
    }

    .admission-procedure .step-swiper .main-card {

        height: 216px;
        margin-bottom: 15%;
    }

    .admission-procedure .step-swiper .main-card .orange-bg {
        top: -17%;
        left: 38%;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .admission-procedure .text-holder-procedure h2 {
        font-size: 25px;
    }

    .admission-procedure .step-swiper .main-card {
        width: 100%;
        height: 216px;
        margin-bottom: 15%;
    }

    .admission-procedure .step-swiper .main-card .orange-bg {
        top: -18%;

    }
}