.amaity-achievement-section {
    margin: 6% 0;
}

.amaity-achievement-section .heading-holder h5 {
    font-size: 32px;
    font-weight: bold;
    color: #000;
    margin-bottom: 3px;
}


.amaity-achievement-section .main-img-class .achive-img {
    width: 100%;
    height: auto;
}

.amaity-achievement-section .swiper-pagination-bullet {
    width: 10px;
    height: 6px;
    border-radius: 5px;
    opacity: 1;
    background-color: #CECECE;
}

.amaity-achievement-section .swiper-pagination-bullet-active {
    width: 18px;
    background: #000;
}

@media(min-width:0px) and (max-width:991px) {
    .amaity-achievement-section .heading-holder h5 {
        font-size: 20px;
    }

    
}

@media(min-width:992px) and (max-width:1200px) {
    .amaity-achievement-section .heading-holder h5 {
        font-size: 25px;
    }

}