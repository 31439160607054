/* Banner Css Started  */
.Notice-news .newsslider-div {
    /* display: flex !important; */
    margin-bottom: 5%;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.Notice-news .newsslider-div:hover {
    background-color: #f1f1f1;
}

.newsslider-div .newimg-div {
    min-width: 50px;
    height: 50px;
    margin-right: 10px;

}

.newsslider-div .newimg-div .spec {
    min-width: 50px;
    height: 50px;
    margin-right: 10px;
}

.newsslider-div .newimg-div .spec {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.newsslider-div .newcont .sub-text {
    margin-bottom: 0px;
    color: #000;
    margin-left: 10px;
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    margin-bottom: 0px;
}

.Notice-news .newss-imggg {
    width: 100%;
}