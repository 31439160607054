.about-advantage {
    margin-bottom: 7%;
    position: relative;
}

.about-advantage .about-title p {
    font-size: 16px;
    color: #383838;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    text-align: justify;
    width: 95%;
}

.about-advantage .about-title h2 {
    font-size: 25px;
    font-weight: 600;
    color: #383838;
}

.about-advantage .about-title h2 .main-span-class span {
    text-decoration: underline;
    text-decoration-color: #FF8600;
}

.about-advantage .about-title h2 .main-span-class {
    font-size: 28px;
    font-weight: bold;
    color: #383838;
    position: relative;
}

.about-advantage .about-title h2 .main-span-class:after {
    content: '';
    position: absolute;
    border-bottom: 2px solid #FF8600;
    width: 20px;
    left: 0px;
    bottom: 0px;
}

.about-advantage .black-small-line {
    border-bottom: 1px solid #707070;
    margin-top: 10%;
    width: 86px;
}

.about-advantage .circle-line-holder{
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-advantage .orange-main-class {
    position: relative;
    width: 112px;
    height: 112px;
    background: #FF9116;
    border-radius: 100px;
}

.about-advantage .orange-main-class .white-bg-class {
    position: absolute;
    background: #FFF;
    height: 82px;
    width: 82px;
    border-radius: 100px;
    top: 13%;
    left: 13%;
}

.about-advantage .orange-main-class .number-class {
    font-size: 50px;
    font-weight: bold;
}

.about-advantage .middle-main{
display: flex;
justify-content: center;
align-items: center;
}

.about-advantage .middle-orange-line {
    height: 42px;
    border-left: 15px solid #FF8600;
    /* margin-left: 25%; */
}

.about-advantage .desktop-view-about {
    display: block;
    margin-bottom: 4%;
}

.about-advantage .mobile-view-about {
    display: none;
}

/* media query */

@media(min-width:0px) and (max-width:320px) {
    .about-advantage {
        margin-top: 0;
        margin-bottom: 9%;
    }

    .about-advantage .mobile-view-about {
        display: block;
    }

    .about-advantage .desktop-view-about {
        display: none;
    }

    .about-advantage .orange-main-class .number-class {
        font-size: 20px;
    }

    .about-advantage .orange-main-class .white-bg-class {
        height: 30px;
        width: 30px;
        top: 14%;
        left: 13%;
    }

    .about-advantage .orange-main-class {
        width: 40px;
        height: 40px;
    }

    .about-advantage .about-title h2 {
        font-size: 15px;
    }

    .about-advantage .about-title p {
        font-size: 12px;
    }

    .about-advantage .middle-orange-line {
        height: 58px;
        border-left: 4px solid #FF8600;
        margin-left: 62%;
    }
}

@media(min-width:321px) and (max-width:485px) {
    .about-advantage {
        margin-top: 0;
        margin-bottom: 9%;
    }

    .about-advantage .mobile-view-about {
        display: block;
    }

    .about-advantage .desktop-view-about {
        display: none;
    }

    .about-advantage .orange-main-class .number-class {
        font-size: 20px;
    }

    .about-advantage .orange-main-class .white-bg-class {
        height: 30px;
        width: 30px;
        top: 13%;
        left: 13%;
    }

    .about-advantage .orange-main-class {
        width: 40px;
        height: 40px;
    }

    .about-advantage .about-title h2 {
        font-size: 15px;
    }

    .about-advantage .about-title p {
        font-size: 12px;
    }

    .about-advantage .middle-orange-line {
        height: 58px;
        border-left: 4px solid #FF8600;
        margin-left: 36%;
    }
}

@media(min-width:486px) and (max-width:576px) {
    .about-advantage {
        margin-top: 0;
        margin-bottom: 9%;
    }

    .about-advantage .mobile-view-about {
        display: block;
    }

    .about-advantage .desktop-view-about {
        display: none;
    }

    .about-advantage .middle-orange-line {
        height: 58px;
        border-left: 4px solid #FF8600;
        /* margin-left: 32%; */
    }

    .about-advantage .orange-main-class .number-class {
        font-size: 20px;
    }

    .about-advantage .orange-main-class .white-bg-class {
        height: 30px;
        width: 30px;
        top: 14%;
        left: 13%;
    }

    .about-advantage .orange-main-class {
        width: 40px;
        height: 40px;
    }

    .about-advantage .about-title h2 {
        font-size: 15px;
    }

    .about-advantage .about-title p {
        font-size: 12px;
    }
}

@media(min-width:486px) and (max-width:767px) {
    .about-advantage .mobile-view-about {
        display: block;
    }

    .about-advantage .desktop-view-about {
        display: none;
    }

    .about-advantage .middle-orange-line {
        height: 58px;
        border-left: 4px solid #FF8600;
        margin-left: 29%;
    }

    .about-advantage .orange-main-class .number-class {
        font-size: 20px;
    }

    .about-advantage .orange-main-class .white-bg-class {
        height: 30px;
        width: 30px;
        top: 14%;
        left: 13%;
    }

    .about-advantage .orange-main-class {
        width: 40px;
        height: 40px;
    }

    .about-advantage .about-title h2 {
        font-size: 15px;
    }

    .about-advantage .about-title p {
        font-size: 12px;
    }
}

@media(min-width:767px) and (max-width:768px) {
    .about-advantage .about-title h2 .main-span-class {
        font-size: 25px;
    }

    .about-advantage .about-title h2 {
        font-size: 20px;
    }

    .about-advantage .about-title p {
        font-size: 13px;
    }

    .about-advantage .orange-main-class .number-class {
        font-size: 40px;
    }

    .about-advantage .orange-main-class .white-bg-class {
        height: 64px;
        width: 64px;
    }

    .about-advantage .orange-main-class {
        width: 84px;
        height: 84px;
    }

    .about-advantage .middle-orange-line {
        height: 41px;
        border-left: 10px solid #FF8600;
        /* margin-left: 40%; */
    }
}

@media(min-width:769px) and (max-width:992px) {
    .about-advantage .about-title h2 .main-span-class {
        font-size: 25px;
    }

    .about-advantage .about-title h2 {
        font-size: 20px;
    }

    .about-advantage .about-title p {
        font-size: 13px;
    }

    .about-advantage .orange-main-class .number-class {
        font-size: 40px;
    }

    .about-advantage .orange-main-class .white-bg-class {
        height: 64px;
        width: 64px;
    }

    .about-advantage .orange-main-class {
        width: 84px;
        height: 84px;
    }

    .about-advantage .middle-orange-line {
        height: 41px;
        border-left: 10px solid #FF8600;
        /* margin-left: 40%; */
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .about-advantage .about-title h2 .main-span-class {
        font-size: 26px;
    }

    .about-advantage .about-title h2 {
        font-size: 22px;
    }

    .about-advantage .about-title p {
        font-size: 14px;
    }

    .about-advantage .orange-main-class .number-class {
        font-size: 40px;
    }

    .about-advantage .orange-main-class .white-bg-class {
        height: 64px;
        width: 64px;
    }

    .about-advantage .orange-main-class {
        width: 84px;
        height: 84px;
    }

    .about-advantage .middle-orange-line {
        height: 50px;
        border-left: 10px solid #FF8600;
        /* margin-left: 27%; */
    }
}


@media(min-width:1201px) and (max-width:1399px) {
    .about-advantage .middle-orange-line {
        height: 42px;
        border-left: 15px solid #FF8600;
        /* margin-left: 30%; */
    }
}