.programm-featured {
    margin-bottom: 5%;
}

.programm-featured .title-programm-offered h2 {
    font-size: 32px;
    font-weight: bold;
    color: #000000;
    /* margin-top: 41%; */
}

.programm-featured .title-programm-offered p {
    font-size: 16px;
    color: #383838;
    width: 80%;
}

.programm-featured .programOffer {
    margin-bottom: 38px;
}

.programm-featured .swiper-slide {
    margin-bottom: 31px;

}

.programm-featured .title-programm-offered h2 span {
    border-bottom: 5px solid #FA8300;
}

.programm-featured .cource-card {
    background: #FFFFFF;
    box-shadow: 0px 1px 8px #00000026;
    border-radius: 10px;
    border-left: 7px solid #FA8300;
    border-right: none;
    border-top: none;
    border-bottom: none;
    padding: 5px 20px 5px 20px;
    position: relative;
    height: 200px;
    width: 100%;
}

.programm-featured .cource-card .card-img-main .card-img {
    width: 68px;
    height: 64px;
    position: absolute;
    top: -5%;
}

.programm-featured .cource-card .card-title h3 {
    font-size: 32px;
    font-weight: bold;
    color: #000000;
    margin-top: 17%;
}

.programm-featured .cource-card .card-title p {
    font-size: 16px;
    color: #383838;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
}

/* Media query */

@media(min-width:0px) and (max-width:575px) {
    .programm-featured .title-programm-offered p {
        width: 100%;
        text-align: center;
    }

    .programm-featured .title-programm-offered {
        text-align: center;
    }
}

@media(min-width:0px) and (max-width:320px) {
    .programm-featured .title-programm-offered h2 {
        font-size: 18px;
        margin-top: 0%;
    }

    .programm-featured .title-programm-offered p {
        font-size: 12px;
    }

    .programm-featured .title-programm-offered h2 span {
        border-bottom: 2px solid #FA8300;
    }

    .programm-featured .cource-card .card-title h3 {
        font-size: 18px;
    }

    .programm-featured .cource-card .card-title p {
        font-size: 13px;
    }

    .programm-featured .cource-card {
        height: auto;
    }

    .programm-featured .cource-card .card-img-main .card-img {
        width: 52px;
        height: 53px;
    }
}

@media(min-width:321px) and (max-width:485px) {
    .programm-featured .title-programm-offered h2 {
        font-size: 19px;
        margin-top: 0%;
    }

    .programm-featured .title-programm-offered p {
        font-size: 12px;
    }

    .programm-featured .title-programm-offered h2 span {
        border-bottom: 2px solid #FA8300;
    }

    .programm-featured .cource-card .card-title h3 {
        font-size: 18px;
    }

    .programm-featured .cource-card .card-title p {
        font-size: 13px;
    }

    .programm-featured .cource-card {
        height: auto;
    }

    .programm-featured .cource-card .card-img-main .card-img {
        width: 52px;
        height: 53px;
    }
}

@media(min-width:486px) and (max-width:576px) {
    .programm-featured .title-programm-offered h2 {
        font-size: 20px;
        margin-top: 0%;
    }

    .programm-featured .title-programm-offered p {
        font-size: 12px;
    }

    .programm-featured .title-programm-offered h2 span {
        border-bottom: 2px solid #FA8300;
    }

    .programm-featured .cource-card .card-title h3 {
        font-size: 18px;
    }

    .programm-featured .cource-card {
        height: auto;
    }

    .programm-featured .cource-card .card-img-main .card-img {
        width: 52px;
        height: 53px;
    }
}

@media(min-width:577px) and (max-width:766px) {
    .programm-featured .title-programm-offered h2 {
        font-size: 22px;
        margin-top: 0%;
        text-align: center;
    }

    .programm-featured .title-programm-offered p {
        font-size: 12px;
        text-align: center;
        width: 100%;
    }

    .programm-featured .title-programm-offered h2 span {
        border-bottom: 2px solid #FA8300;
    }

    .programm-featured .cource-card .card-title h3 {
        font-size: 20px;
    }

    .programm-featured .cource-card {
        height: auto;
    }

    .programm-featured .cource-card .card-img-main .card-img {
        width: 42px;
        height: 37px;
    }

    .programm-featured .cource-card .card-title p {
        font-size: 14px;
        color: #383838;
    }
}

@media(min-width:767px) and (max-width:768px) {
    .programm-featured .title-programm-offered h2 {
        font-size: 22px;
        margin-top: 59%;
    }

    .programm-featured .title-programm-offered p {
        font-size: 12px;
    }

    .programm-featured .title-programm-offered h2 span {
        border-bottom: 2px solid #FA8300;
    }

    .programm-featured .cource-card .card-title h3 {
        font-size: 20px;
    }

    .programm-featured .cource-card {
        height: auto;
    }

    .programm-featured .cource-card .card-img-main .card-img {
        width: 42px;
        height: 37px;
    }

    .programm-featured .cource-card .card-title p {
        font-size: 14px;
        color: #383838;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .programm-featured .title-programm-offered h2 {
        font-size: 22px;
        margin-top: 59%;
    }

    .programm-featured .title-programm-offered p {
        font-size: 12px;
    }

    .programm-featured .title-programm-offered h2 span {
        border-bottom: 2px solid #FA8300;
    }

    .programm-featured .cource-card .card-title h3 {
        font-size: 20px;
    }

    .programm-featured .cource-card {
        height: auto;
    }

    .programm-featured .cource-card .card-img-main .card-img {
        width: 42px;
        height: 37px;
    }

    .programm-featured .cource-card .card-title p {
        font-size: 14px;
        color: #383838;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .programm-featured .title-programm-offered h2 {
        font-size: 22px;
        margin-top: 59%;
    }

    .programm-featured .title-programm-offered p {
        font-size: 12px;
    }

    .programm-featured .title-programm-offered h2 span {
        border-bottom: 2px solid #FA8300;
    }

    .programm-featured .cource-card .card-title h3 {
        font-size: 20px;
    }

    .programm-featured .cource-card {
        height: auto;
    }

    .programm-featured .cource-card .card-img-main .card-img {
        width: 42px;
        height: 37px;
    }

    .programm-featured .cource-card .card-title p {
        font-size: 14px;
        color: #383838;
    }
}