.first-section .blogbanner-image .blog-img {
    width: 100%;
    height: auto;
    background: no-repeat;
}

.first-section .blogbanner-image {
    position: relative;
}

.first-section .blogbanner-image .firsticon-div {
    position: absolute;
    top: 10px;
    left: 10px;
}

.first-section .blogbanner-image .firsticon-div .top-icon {
    width: 25px;
}

@media (min-width:0px) and (max-width:768px) {
    .first-section .blogbanner-image .firsticon-div .top-icon {
        width: 20px;
    }
}

@media(min-width:0px) and (max-width:321) {
    .inner-banner .innerbanner-img-main .icon-div {
       top: 5px;
       
    }
}