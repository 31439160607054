.contact-form {
    margin-top: 5%;
    margin-bottom: 7%;
    background: #FFF8F0;
}

.contact-form .contact-img .cont-img {
    width: 692px;
    height: 512px;
}

.contact-form .forms-cont {
    margin-top: 14%;
}

.contact-form .forms-cont .form-control {
    box-shadow: 0px 0px 5px #00000029;
    border-radius: 5px;
    color: #000000B2;
    font-size: 14px;
    height: 37px;
}

.contact-form .forms-cont .text-area-holder {
    height: auto;
}

.contact-form .forms-cont .btn-submit {
    background: #FF8600 0% 0% no-repeat padding-box;
    border-radius: 5px;
    width: 135px;
    height: 37px;
    color: #FFF;
    font-size: 16px;
}

.contact-form .forms-cont .btn-submit:focus {
    background: #FF8600 0% 0% no-repeat padding-box;
    border-radius: 5px;
    width: 135px;
    height: 37px;
    color: #FFF;
    font-size: 16px;
}

@media(min-width:0px) and (max-width:768px) {
    .contact-form .contact-img .cont-img {
        width: 100%;
        height: 100%;
        margin-top: 20px;
    }
}

@media(min-width:768px) and (max-width:991px) {
    .contact-form .contact-img .cont-img {
        width: 100%;
        height: 368px;
        margin-top: 20px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .contact-form .contact-img .cont-img {
        width: 100%;
        height: 479px;
    }
}