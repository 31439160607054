.Online-Mlib-section {
    background-image: url('/public/assets/images/Courses/M.Lib/bg-img.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    padding: 5% 0px;
}

.Online-Mlib-section .main-onlin-sec .onlin-img-div .onlin-img {
    width: 100%;
    height: 100%;
}

.Online-Mlib-section .main-onlin-sec .online-content .descrptn h5 {
    font-size: 32px;
    color: #000;
    font-weight: bold;
    margin-bottom: 20px;
}

.Online-Mlib-section .main-onlin-sec .online-content .descrptn p {
    font-size: 16px;
    color: #000;
    font-weight: 300;
}

.Online-Mlib-section .main-onlin-sec .online-content .video-div .online-video {
    width: 100%;
    border-radius: 15px;
    border: 5px solid #fff;
}

.Online-Mlib-section .main-onlin-sec .online-content .descrptn {
    margin-top: 8%;
}


@media (min-width:0px) and (max-width:767px) {

    .Online-Mlib-section .main-onlin-sec .online-content .descrptn h5 {
        font-size: 20px;
        color: #000;
    }

    .Online-Mlib-section .main-onlin-sec .online-content .video-div {
        margin-top: 0%;
    }

    .Online-Mlib-section .main-onlin-sec .online-content .descrptn p {
        font-size: 13px;
        color: #000;
    }

    .Online-Mlib-section .main-onlin-sec .online-content .descrptn {
        margin-top: 0%;
    }
}

@media (min-width:768px) and (max-width:991px) {

    .Online-Mlib-section .main-onlin-sec .online-content .descrptn h5 {
        font-size: 25px;
    }

    .Online-Mlib-section .main-onlin-sec .online-content .descrptn p {
        font-size: 13px;
    }

    .Online-Mlib-section .main-onlin-sec .online-content .video-div {
        margin-top: 22%;
    }
}

@media (min-width:992px) and (max-width:1200px) {
    .Online-Mlib-section .main-onlin-sec .online-content .descrptn {
        margin-top: 0%;
    }

    .Online-Mlib-section .main-onlin-sec .online-content .descrptn h5 {
        font-size: 28px;
    }

    .Online-Mlib-section .main-onlin-sec .online-content .descrptn p {
        font-size: 14px;
    }
}