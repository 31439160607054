.mca-discover-section {
    margin: 5% 0 5% 0%;
}

.mca-discover-section .heading-holder h5 {
    font-size: 32px;
    font-weight: bold;
    font-family: 'Open Sans';
    margin-bottom: 16px;
}

.mca-discover-section .heading-holder p {
    font-size: 16px;
}

.mca-discover-section .swiper-div .card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    position: relative;
    border: none;
    height: 100%;
    min-height: 250px;
    margin-top: 20%;
    margin: 20% 4% 7% 4%;
    padding: 10px 20px;
    border: 1px solid #FFFFFF;
}

.mca-discover-section .swiper-div .card:hover {
    border: 1px solid #afaeae;
    transition: 0.9s ease;
    transition-delay: 0.05s;
}

.mca-discover-section .grey-border {
    width: 0px;
    border-bottom: 1px solid #000000;
    transition: 0.2s ease;
    display: none;
}

.mca-discover-section .card:hover .grey-border {
    width: 100%;
    border-bottom: 1px solid #000000;
    transition: 0.5s ease;
}

.mca-discover-section .swiper-div .card .image-holder {
    position: absolute;
    top: -81px;
    z-index: 1;
    overflow: hidden;
    /* width: 180px;
    height: 180px; */
    height: auto;
    right: -18px;
}

.mca-discover-section .swiper-div .card .image-holder img {
    height: auto;
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 100%;
    border: 1px solid #EBEBEB;
}



.mca-discover-section .swiper-div .card .content-holder h4 {
    font-size: 18px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /* overflow: hidden; */
    -webkit-line-clamp: 2;
    line-clamp: 2;
    transition: 0.9s ease;
    width: fit-content;
    height: 50px;
}

.mca-discover-section .swiper-div .card .text-holder {
    margin-top: 15%;
}

.mca-discover-section .swiper-div .card .text-holder p {
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;
    text-align: justify;
    font-family: 400;
}

.mca-discover-section .swiper-div .swiper-pagination {
    display: none;
}

.mca-discover-section .swiper-div .main-slider {
    position: relative;
}

.mca-discover-section .swiper-div .main-slider .arrow-back,
.mca-discover-section .swiper-div .main-slider .arrow-next {
    cursor: pointer;
}

.mca-discover-section .swiper-div .main-slider .main-arrow-back {
    position: absolute;
    top: -198px;
    left: -5px;
    z-index: 1;
    cursor: pointer;
    background-color: black;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: none;
}

.mca-discover-section:hover .swiper-div .main-slider .main-arrow-back,
.mca-discover-section:hover .swiper-div .main-slider .main-arrow-next {
    display: block;
}

.mca-discover-section .swiper-div .main-slider .main-arrow-back:hover,
.mca-discover-section .swiper-div .main-slider .main-arrow-next:hover {
    background-color: #ff8600;
    transition-delay: 0.2s;
}

.mca-discover-section .swiper-div .main-slider .arrow-back {
    content: url("/public/assets/images/icons/prev.png");
    width: 8px;
    margin: 9px;
}

.mca-discover-section .swiper-div .main-slider .main-arrow-next {
    position: absolute;
    top: -198px;
    right: -5px;
    z-index: 1;
    cursor: pointer;
    background-color: black;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: none;
}

.mca-discover-section .swiper-div .main-slider .arrow-next {
    content: url("/public/assets/images/icons/next.png");
    width: 8px;
    margin: 9px 9px 9px 12px;
}

@media(min-width:0px) and (max-width:768px) {
    .mca-discover-section .heading-holder h5 {
        font-size: 20px;
    }

    .mca-discover-section .heading-holder p {
        font-size: 13px;
    }

    .mca-discover-section .swiper-div .card .content-holder h4 {
        font-size: 16px;
    }

    /* .mca-discover-section .swiper-div .card .image-holder img {
        width: 140px;
        height: 144px;
    } */
    .mca-discover-section .swiper-div .card .image-holder img {
        width: 58%;
    }

    .mca-discover-section .swiper-div .card .text-holder {
        margin-top: 15%;
    }

    .mca-discover-section .swiper-div .main-slider .arrow-next {
        width: 5px;
        margin: 7px 6px 6px 9px;
    }

    .mca-discover-section .swiper-div .main-slider .main-arrow-next {
        width: 22px;
        height: 22px;
        right: 10px;
        top: -163px;
        display: block;
    }

    .mca-discover-section .swiper-div .main-slider .main-arrow-back {
        width: 22px;
        height: 22px;
        left: 10px;
        top: -163px;
        display: block;
    }

    .mca-discover-section .swiper-div .main-slider .arrow-back {
        width: 5px;
        margin: 7px 6px 6px 9px;
    }

    .mca-discover-section .swiper-div .card .image-holder {
        top: -47px;
        right: -56px;
    }

    .mca-discover-section .swiper-div .card {
        min-height: 245px;
    }

    .mca-discover-section .swiper-div .card .text-holder p {
        font-size: 13px;
    }

    .mca-discover-section .card:hover .grey-border {
        display: none;
    }
}

@media(min-width:768px) and (max-width:991px) {
    .mca-discover-section .heading-holder h5 {
        font-size: 20px;
    }

    .mca-discover-section .heading-holder p {
        font-size: 14px;
    }

    .mca-discover-section .swiper-div .card .content-holder h4 {
        font-size: 17px;
    }

    /* .mca-discover-section .swiper-div .card .image-holder img {
        width: 159px;
        height: 151px;
    } */

    .mca-discover-section .swiper-div .card {
        min-height: 261px;
    }

    .mca-discover-section .swiper-div .card .image-holder {
        top: -64px;
        right: -44px;
    }

    .mca-discover-section .swiper-div .card .image-holder img {
        width: 75%;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .mca-discover-section .heading-holder h5 {
        font-size: 25px;
    }

    .mca-discover-section .swiper-div .card {
        min-height: 286px;
        padding: 10px;
    }

    .mca-discover-section .swiper-div .card .content-holder h4 {
        font-size: 16px;
    }

    .mca-discover-section .swiper-div .card .image-holder {
        top: -64px;
        right: -44px;
    }

    .mca-discover-section .swiper-div .card .image-holder img {
        width: 75%;
    }
}

@media(min-width:1200px) and (max-width:1400px) {
    .mca-discover-section .swiper-div .card {
        min-height: 263px;
        padding: 10px;
    }

    .mca-discover-section .swiper-div .card .content-holder h4 {
        font-size: 17px;
    }

    .mca-discover-section .swiper-div .card .image-holder {
        top: -64px;
        right: -44px;
    }

    .mca-discover-section .swiper-div .card .image-holder img {
        width: 75%;
    }

}