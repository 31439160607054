.Mlib-Reasn-section {
    background: #FFF8F0 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 3% 0% 2% 0%;
}

.Mlib-Reasn-section .heading-div h5 {
    font-size: 32px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 15px;
}

.Mlib-Reasn-section .heading-div p {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

.Mlib-Reasn-section .card-imgggg {
    width: 100%;
    height: 100%;
}


.Mlib-Reasn-section .mlib-res-card {
    position: relative;
}

.Mlib-Reasn-section .mlib-res-card .overlay-text h6 {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #FFFF;
    text-decoration: underline;
}

.Mlib-Reasn-section .mlib-res-card .star-btn {
    background: none;
    border: none;
}

.Mlib-Reasn-section .mlib-res-card .star-img {
    width: 45px;
    height: 45px;
}


.Mlib-Reasn-section .mlib-res-card:hover .overlay-text h6 {}

/* .Mlib-Reasn-section .mlib-res-card:hover .overlay-color,
.Mlib-Reasn-section .mlib-res-card:hover .overlay-text {
    opacity: 1;
    transition-delay: 0.05s;
} */



.Mlib-Reasn-section .swiper-pagination-bullets {
    display: none;
}

.mlib-reason-card-modal .icons {
    width: 12px;
}

.mlib-reason-card-modal .main-slider .arrow-back,
.mlib-reason-card-modal .main-slider .arrow-next {
    cursor: pointer;
}

.mlib-reason-card-modal .main-slider .main-arrow-back {
    z-index: 1;
    cursor: pointer;
    background-color: black;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.mlib-reason-card-modal .main-slider .main-arrow-back:hover,
.mlib-reason-card-modal .main-slider .main-arrow-next:hover {
    background-color: #ff8600;
    transition-delay: 0.2s;
}

.mlib-reason-card-modal .main-slider .arrow-back {
    content: url("/public/assets/images/icons/prev.png");
    width: 8px;
}

.mlib-reason-card-modal .main-slider .main-arrow-next {
    z-index: 1;
    cursor: pointer;
    background-color: black;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.mlib-reason-card-modal .main-slider .arrow-next {
    content: url("/public/assets/images/icons/next.png");
    width: 8px;
}

.mlib-reason-card-modal .navigations {
    display: flex;
    gap: 22px;
    text-align: center;
    justify-content: center;
}

.mlib-reason-card-modal .navigations .close-btn {
    background-color: black;
    border: none;
    border-radius: 100px;
    width: 28px;
    height: 28px;
    padding: 0px;
}

.mlib-reason-card-modal .navigations .close-btn:hover {
    background-color: #ff8600;
}

.modal-contents {
    padding: 15px;
}

.mlib-reason-card-modal .modal-header {
    display: block;
    border: none;
}

.mlib-reason-card-modal .modal-contents p {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    text-align: center;
    margin-bottom: 13%;
}

.mlib-reason-card-modal .modal-contents h5 {
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    text-align: center;
    text-decoration: underline;
}

.Mlib-Reasn-section .swiper-wrapper {
    margin: 4% 0px 2% 0px;
}

.Mlib-Reasn-section .modal {
    backdrop-filter: blur(6px) !important;
    background: transparent;
}

.Mlib-Reasn-section .mlib-res-card .overlay-text {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translate(-50%, 0%);
    display: none;
}

.Mlib-Reasn-section .mlib-res-card:hover .overlay-text {
    display: block;
}

.Mlib-Reasn-section .content .content-overlay {
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    height: 99%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.Mlib-Reasn-section .content:hover .content-overlay {
    opacity: 1;
}

.Mlib-Reasn-section .content-image {
    width: 100%;
}

.Mlib-Reasn-section .content-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.Mlib-Reasn-section .content:hover .content-details {
    top: 50%;
    left: 50%;
    opacity: 1;
}



.Mlib-Reasn-section .content-details p {
    color: #fff;
    font-size: 0.8em;
}

.Mlib-Reasn-section .fadeIn-top {
    top: 20%;
    left: 20%
}

@media(min-width:0px) and (max-width:768px) {
    .Mlib-Reasn-section .heading-div h5 {
        font-size: 20px;
    }

    .Mlib-Reasn-section .heading-div p {
        font-size: 13px;
    }

    .mlib-reason-card-modal .modal-contents h5 {
        font-size: 16px;
    }

    .mlib-reason-card-modal .modal-contents p {
        font-size: 13px;
    }

    .Mlib-Reasn-section .mlib-res-card .overlay-text h6 {
        font-size: 16px;
    }
}