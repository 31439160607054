section.bharti-advantages-section {
    margin-bottom: 4%;
    margin-top: 1%;
}

.bharti-advantages-section .heading h1 {
    font-size: 32px;
    font-weight: bold;
    color: #000;
}

.bharti-advantages-section .heading .text-border {
    border-bottom: 3px solid #FF8600;
    width: 170px;
    margin: auto;
}

.bharti-advantages-section .cards-div .main-card-adv {
    padding: 50px 10px 5px 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    opacity: 1;
    height: 100%;
    min-height: 242px;
}

.bharti-advantages-section .cards-div .main-card-adv h5 {
    font-size: 20px;
    font-weight: bold;
    color: #000;
}

.bharti-advantages-section .cards-div .main-card-adv p {
    font-size: 14px;
    font-weight: 400;
    color: #000;
}

.bharti-advantages-section .cards-div:hover .main-card-adv h5 {
    color: #FF8600;

}

.bharti-advantages-section .cards-div:hover .main-img {
    filter: invert(74%) sepia(31%) saturate(6116%) hue-rotate(1deg) brightness(103%) contrast(106%);
    background: transparent;

}

.bharti-advantages-section .cards-div:hover .img-div .circle {
    border-top: 5px solid #FF8600;
}

.bharti-advantages-section .cards-div {
    position: relative;
    margin-top: 37%;
    margin-bottom: 17%;
}

.bharti-advantages-section .cards-div .img-div {
    position: absolute;
    top: -60px;
    left: 19px;
}

.bharti-advantages-section .cards-div .img-div .circle {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    width: 100px;
    height: 100px;
    text-align: center;
    border-radius: 50px;
    padding: 20px 13px 8px 13px;
    border-top: 5px solid #707070;
}

.bharti-advantages-section .cards-div .img-div .circle .main-img {
    width: 60px;
}

.bharti-advantages-section .bharti-adv-swiper .swiper-pagination-bullet-active {
    background-color: black;
    width: 16px;
    height: 4px;
    border-radius: 5px;
}

.bharti-advantages-section .bharti-adv-swiper .swiper-pagination-clickable .swiper-pagination-bullet {
    height: 4px;
    border-radius: 5px;
    background-color: black;
}


@media (min-width:0px) and (max-width:768px) {
    .bharti-advantages-section .heading h1 {
        font-size: 18px;
    }

    .bharti-advantages-section .cards-div {
        margin-top: 27%;
        margin-bottom: 12%;
    }

    .bharti-advantages-section .cards-div .main-card-adv h5 {
        font-size: 18px;
    }

    .bharti-advantages-section .cards-div .main-card-adv p {
        font-size: 13px;
    }

    .bharti-advantages-section .cards-div .main-card-adv {
        min-height: 215px;
    }
}

@media (min-width:769px) and (max-width:991px) {
    .bharti-advantages-section .heading h1 {
        font-size: 20px;
    }
}

@media (min-width:992px) and (max-width:1200px) {
    .bharti-advantages-section .heading h1 {
        font-size: 25px;
    }
}