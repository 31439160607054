.dy-ban-section .banner-div {
    padding: 30px 0% 5% 0%;
}

.dy-ban-section .banner-div .banner-img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.dy-ban-section .ban-sub-conttt .cont-div {
    background: #fff;
    padding: 20px;
    filter: drop-shadow(0px 0px 13px rgba(0, 0, 0, 0.23));
    border-radius: 20px;
    margin: 0% 2% 2% 2%;
}

.dy-ban-section .ban-sub-conttt .cont-div .text {
    margin-bottom: 0px;
    font-size: 16px;
    text-align: center;
    color: #383838;
    letter-spacing: 0.01em;
    line-height: 30px;
}



@media (min-width:0px) and (max-width:768px) {
    .dy-ban-section .ban-sub-conttt .cont-div .text {
        font-size: 14px;
        line-height: 24px;
    }

    .dy-ban-section .ban-sub-conttt .cont-div {
        margin: 0% 0% 0% 2%;
    }
}