.Eligibilty-Criteria {
    margin: 5% 0;
}

.Eligibilty-Criteria .image-icon .icon-img {
    width: 22px;
    height: 26px;
}

.Eligibilty-Criteria .text-holder h2 {
    font-size: 20px;

}

.Eligibilty-Criteria .text-holder .border-line {
    border-bottom: 2px solid #FF9900;
    width: 146px;

}

.Eligibilty-Criteria .bg-holder {
    background-image: url('../../../../../public/assets/images/spaecialization/bg-img.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 18px 0px 0px 0px;
    border-radius: 19px;
}

.Eligibilty-Criteria .bg-holder .circle-box {
    width: 10px;
    height: 10px;
    /* background-color: #FF9900; */

}

.Eligibilty-Criteria .bg-holder .content-holder p {
    font-size: 14px;
}

@media(min-width:0px) and (max-width:767px) {
    .Eligibilty-Criteria .bg-holder .content-holder p {
        font-size: 12px;
    }
}