.achievement {
    margin: 5% 0;
}

.achievement .heading-holder h5 {
    font-size: 32px;
    font-weight: bold;
    color: #000;
    margin-bottom: 3px;
}

.achievement .text-border {
    border-bottom: 3px solid #FF8600;
    width: 170px;
    margin: auto;
}

.achievement .main-img-class .achive-img {
    width: 100%;
    height: auto;
}

.achievement .swiper-pagination-bullet {
    width: 10px;
    height: 6px;
    border-radius: 5px;
    opacity: 1;
    background-color: #CECECE;
}

.achievement .swiper-pagination-bullet-active {
    width: 18px;
    background: #000;
}

@media(min-width:0px) and (max-width:991px) {
    .achievement .heading-holder h5 {
        font-size: 20px;
    }

    .achievement .text-border {
        width: 119px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .achievement .heading-holder h5 {
        font-size: 25px;
    }

    .achievement .text-border {
        width: 125px;
    }
}