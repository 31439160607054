
.NoWait-Section
{
    margin: 5% 0px;
}
.NoWait-Section .banner-content {
    position: relative;
}

.NoWait-Section .banner-content .baner .overlay-color {
    background: transparent linear-gradient(89deg, #FFFFFF2E 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    opacity: 0.89;
    top: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    position: absolute;
}

.NoWait-Section .banner-content .baner .banner-img {
    width: 100%;
    height: 100%;
}
.NoWait-Section .banner-content .overlay-text
{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}
.NoWait-Section .banner-content .overlay-text .description
{
    text-align: center;
}
.NoWait-Section .banner-content .overlay-text .description h3{
    font-size: 32px;
    font-weight: bold;
    color: #000;
    margin-bottom: 3px;
}
.NoWait-Section .banner-content .overlay-text .description p{
    font-size: 16px;
    font-weight:400;
    color: #000;
}
.NoWait-Section .banner-content .overlay-text .description .text-border {
    border-bottom: 3px solid #FF8600;
    width: 170px;
    margin: auto;
}


@media (min-width:0px) and (max-width:576px)
{
    .NoWait-Section .banner-content .baner .banner-img {
        height: 250px;
    }
    .NoWait-Section .banner-content .overlay-text .description h3 {
        font-size: 18px;
    }
    .NoWait-Section .banner-content .overlay-text .description p {
        font-size: 13px;
    }
    .NoWait-Section .banner-content .overlay-text .description .text-border {
        width: 135px;
    }
}
@media (min-width:577px) and (max-width:768px)
{
    .NoWait-Section .banner-content .baner .banner-img {
        height: 225px;
    }
    .NoWait-Section .banner-content .overlay-text .description h3 {
        font-size: 20px;
    }
    .NoWait-Section .banner-content .overlay-text .description p {
        font-size: 14px;
    }
    .NoWait-Section .banner-content .overlay-text .description .text-border {
        width: 135px;
    }
}
@media (min-width:769px) and (max-width:991px)
{
    .NoWait-Section .banner-content .baner .banner-img {
        height: 225px;
    }
    .NoWait-Section .banner-content .overlay-text .description h3 {
        font-size: 25px;
    }
    .NoWait-Section .banner-content .overlay-text .description p {
        font-size: 15px;
    }
}