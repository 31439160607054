.Online_banner .OnlinebanHeadtext h3 {
    font: normal normal bold 32px/45px Open Sans;
    color: #000000;
}

.Online_banner .OnlinebanHeadtext p {
    font: normal normal normal 16px/30px Roboto;
    color: #000000;
}

.Online_banner .Onlineban1 {
    width: 100%;
}

.Online_banner {
    background: #FFF8F0 0% 0% no-repeat padding-box;
    padding: 2%;
}


@media screen and (min-width: 0px) and (max-width: 768px) {
    .Online_banner .OnlinebanHeadtext h3 {

        font: normal normal 700 20px/45px Open Sans;
        text-align: center;
    }

    .Online_banner .OnlinebanHeadtext p {
        font: normal normal normal 14px/25px Roboto;
        text-align: justify;

    }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .Online_banner .OnlinebanHeadtext h3 {

        font: normal normal 700 25px/45px Open Sans;
        
    }

    .Online_banner .OnlinebanHeadtext p {
        font: normal normal normal 16px/25px Roboto;
        text-align: justify;

    }
}