.mca-qualified-section {
    background: #FFF8F0 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 5% 0% 7% 0%;
}
.mca-qualified-section .mca-qualified-card .main-icon {
    width: 45px;
}
.mca-qualified-section .heading
{
    text-align: center;
}
.mca-qualified-section .heading h4
{
    font-size: 32px;
    font-weight: bold;
    color: #000;
}
.mca-qualified-section .heading p {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    margin: 15px 0px;
}
.mca-qualified-section .mca-qualified-card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #707070;
    border-radius: 20px;
    opacity: 1;
    position: relative;
}
.mca-qualified-section .mca-qualified-card .bottom-shape {
    width: 100%;
    height: 30px;
    background-color: orange;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}
.swiper-slide.swiper-slide-prev .mca-qualified-card {
    height: 236px;
}
.swiper-slide.swiper-slide-next .mca-qualified-card {
    height: 236px;
}
.swiper-slide.swiper-slide-active .mca-qualified-card{
    height: 200px;
}
.mca-qualified-section .mca-qualified-card .descriptn p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    text-align: justify;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    margin-top: 10px;
}
.mca-qualified-section .mca-qualified-card .title-sec h5 {
    font-size: 18px;
    font-weight: bold;
    color: #000;
    margin: 8px 0px ;
}
.mca-qualified-section .swiper-pagination-bullet  {
    display: none;
}
.mca-qualified-section .swiper-wrapper {
    margin: 22px 0px;
}
.mca-qualified-section .curve-div {
    position: absolute;
    bottom: 0px;
}
.mca-qualified-section .curve-div .curve-img
{
    width: 100%;
}

.mca-qualified-section .main-slider
{
    position: relative;
}

.mca-qualified-section .main-slider .arrow-back,
.mca-qualified-section  .main-slider .arrow-next {
    cursor: pointer;
}

.mca-qualified-section .main-slider .main-arrow-back {
    position: absolute;
    z-index: 1;
    cursor: pointer;
    background-color: black;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    left: 47%;
    position: absolute;
    text-align: center;
    top: 11%;
    transform: translate(-50%,-100%);
}


.mca-qualified-section .main-slider .main-arrow-back:hover,
.mca-qualified-section .main-slider .main-arrow-next:hover{
    background-color: #ff8600;
    transition-delay: 0.2s;
}

.mca-qualified-section  .main-slider .arrow-back {
    content: url("/public/assets/images/icons/prev.png");
    width: 8px;
    margin: 9px;
}

.mca-qualified-section  .main-slider .main-arrow-next {
    position: absolute;
    z-index: 1;
    cursor: pointer;
    background-color: black;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    left: 53%;
    text-align: center;
    top: 11%;
    transform: translate(-50%,-100%);
}

.mca-qualified-section .main-slider .arrow-next {
    content: url("/public/assets/images/icons/next.png");
    width: 8px;
    margin: 9px 9px 9px 12px;
}

@media(min-width:0px) and (max-width:480px)
{
    .mca-qualified-section .main-slider .main-arrow-back {
        left: 40%;
    }
    .mca-qualified-section .mca-qualified-card .title-sec h5 {
        font-size: 16px;
    }
    .mca-qualified-section .mca-qualified-card .descriptn p {
        font-size: 13px;
    }
    .mca-qualified-section .heading h4 {
        font-size: 25px;
    }
    .mca-qualified-section .heading p {
        font-size: 12px;
    }
}
@media(min-width:481px) and (max-width:768px)
{
    .mca-qualified-section .main-slider .main-arrow-back {
        left: 42%;
    }
    .mca-qualified-section .mca-qualified-card .title-sec h5 {
        font-size: 16px;
    }
    .mca-qualified-section .mca-qualified-card .descriptn p {
        font-size: 13px;
    }
    .mca-qualified-section .heading h4 {
        font-size: 25px;
    }
    .mca-qualified-section .heading p {
        font-size: 13px;
    }
}