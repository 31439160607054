.top-reason-msc {
    padding: 4% 0;
    background: #FFF8F0;
}

.top-reason-msc .heading-holder .title {
    font-size: 34px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    line-clamp: 1;

}

.top-reason-msc .heading-holder p {
    font-size: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    line-clamp: 3;

}



.top-reason-msc .bg-circle {
    background: #FF860059 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    width: 90px;
    height: 90px;
    margin: auto;
    border-radius: 50%;
    position: relative;
}

.top-reason-msc .bg-circle .icon-img {
    width: 67px;
    height: 68px;
    position: absolute;
    top: 14px;
    left: 13px;
}

.top-reason-msc .content-holder h5 {
    font-size: 20px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    line-clamp: 1;
}

.top-reason-msc .content-holder p {
    font-size: 15px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5;
    line-clamp: 5;
}

@media(min-width:0px) and (max-width:991px) {
    .top-reason-msc .heading-holder .title {
        font-size: 20px;
    }

    .top-reason-msc .heading-holder p {
        font-size: 14px;
    }

    .top-reason-msc .content-holder h5 {
        font-size: 16px;

    }

    .top-reason-msc .content-holder p {
        font-size: 13px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .top-reason-msc .heading-holder .title {
        font-size: 25px;
    }

    .top-reason-msc .heading-holder p {
        font-size: 14px;
    }

    .top-reason-msc .content-holder h5 {
        font-size: 18px;

    }

    .top-reason-msc .content-holder p {
        font-size: 14px;
    }
}