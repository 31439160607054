.Right_guidance_section .cont-seccc {
    text-align: center;
}

.Right_guidance_section .cont-seccc .main-tt {
    font-weight: bold;
    font-size: 32px;
}

.Right_guidance_section .cont-seccc .subcont {
    margin-bottom: 0px;
    font-size: 16px;
}

.Right_guidance_section .guidanceslider_sec {
    /* margin: 25px 0px; */
    margin-bottom: 7%;
}

.Right_guidance_section .swiper-pagination {
    display: none;
}

.Right_guidance_section .guidanceslider_sec .slider-div {
    /* aspect-ratio: 3/4; */
    position: relative;
    width: 100%;
    height: 100%;

}

.Right_guidance_section .guidanceslider_sec .slider-div .guidanceimg {
    width: 100%;
    border-radius: 8px;
    height: 100%;
    padding: 20px;
}

.Right_guidance_section .guidance-details {
    width: 90%;
    min-height: 120px;
    background-color: #fff;
    /* position: absolute;
    bottom: 10px; */
    text-align: center;
    /* left: 5%; */
    border-radius: 10px;
    padding: 8px;
}

.Right_guidance_section .guidance-details .name {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 3px;
    color: #000;
}

.Right_guidance_section .guidance-details .position {
    font-size: 14px;
    margin-bottom: 0px;
    color: #ff8600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    height: 52px;
}

.Right_guidance_section .guidance-details .position span {
    background-color: #ffddb7;
    padding: 2px 7px;
    border-radius: 4px;
    font-size: 13px;
}

.Right_guidance_section .guidance-details .experience {
    font-size: 12px;
    color: #999;
    margin-bottom: 0px;
}

.Right_guidance_section .guidance-details .cons-btn {
    background-color: #000;
    color: #fff;
    font-size: 12px;
    padding: 2px 5px;
}

.Right_guidance_section .main-divvvv {
    background: #fff;
    box-shadow: 0px 3px 6px #a4a2a2;
    padding: 10px;
    margin: 5% 0;
}

.Right_guidance_section .guidanceslider_sec {
    margin-top: 38px;
}

.Right_guidance_section .guidanceslider_sec .swiper-wrapper {
    justify-content: center;
}


@media (min-width:0px) and (max-width:767.98px) {
    .Right_guidance_section .cont-seccc .main-tt {
        font-size: 20px;
    }

    .Right_guidance_section .guidanceslider_sec .slider-div .guidance-details .name {
        font-size: 12px;
    }

    .Right_guidance_section .guidanceslider_sec .slider-div .guidance-details .position {
        font-size: 11px;
    }

    .Right_guidance_section .guidanceslider_sec .slider-div .guidance-details .position span {
        font-size: 10px;
    }

    .Right_guidance_section .guidanceslider_sec .slider-div .guidance-details .experience {
        font-size: 10px;
    }

    .Right_guidance_section .guidanceslider_sec .slider-div .guidance-details .cons-btn {
        font-size: 10px;
    }
}