.homefaq {
    margin:0 0 5% 0;
}

.homefaq .heading-holder h5 {
    color: #FF8600;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
}

.homefaq .heading-holder .title {
    font-size: 32px;
    font-weight: bold;

    margin-bottom: 15px;
}

.homefaq .heading-holder p {
    color: #383838;
    font-size: 16px;

}

.homefaq .nav-pills .nav-link {
    color: #000;
    font-size: 16px;
    margin: 0px 15px;
}

.homefaq .nav-pills .nav-link.active {
    color: #FF8600;
    background-color: transparent;
    /* border-bottom: 2px solid #000; */
    border-radius: 0%;
    font-size: 16px;
}

.homefaq .bg-color {
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border-radius: 20px;
    padding: 3%;
}

.homefaq .accordion-button:not(.collapsed)::after {
    background-image: url('/public/assets/images/icons/minus-icon.png');
    width: 10px;
    height: 14px;
    background-size: auto;
}

.homefaq .accordion-button::after {
    background-image: url('/public/assets/images/icons/plus-icon.png');
    width: 14px;
    height: 14px;
    background-size: auto;
}

.homefaq .accordion-item {
    background: #FFEEDB 0% 0% no-repeat padding-box;
    padding: 5px;
    border: none;
    border-radius: 0%;
    box-shadow: none;
    margin-bottom: 20px;
}

.homefaq .accordion-button:not(.collapsed) {
    background: #FFEEDB 0% 0% no-repeat padding-box;
    box-shadow: none;
    border-bottom: 1px solid #FF8600;
    color: #000;
    font-size: 20px;
    font-weight: bold;
}

.homefaq .accordion-button {
    background: #FFEEDB 0% 0% no-repeat padding-box;
    box-shadow: none;
    color: #000;
    font-size: 20px;
    font-weight: bold;
}

.homefaq .accordion-body p {
    font-size: 14px;
}

.custom-nav-link {
    position: relative;

}

.custom-nav-link.active::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 2px;
    background-color: #000;
    transform: translate(50%, -9%);
}

.homefaq .accordion-item:last-of-type {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    box-shadow: none;
}

.homefaq .accordion-item:first-of-type {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    box-shadow: none;
}


@media(min-width:0px) and (max-width:767px) {
    .homefaq .heading-holder .title {
        font-size: 16px;
    }

    .homefaq .heading-holder p {
        font-size: 13px;
    }

    .homefaq .nav-pills .nav-link {
        font-size: 13px;
        margin: 0px -4px;
    }

    .homefaq .nav-pills .nav-link.active {
        font-size: 13px;
    }

    .homefaq .accordion-button:not(.collapsed) {
        font-size: 16px;
    }

    .homefaq .accordion-body p {
        font-size: 12px;
    }

    .homefaq .accordion-button {
        font-size: 16px;

    }

}

@media(min-width:0px) and (max-width:321px) {
    .homefaq .nav-pills .nav-link {
        font-size: 11px;
        margin: 0px -4px;
    }

    .homefaq .nav-pills .nav-link.active {
        font-size: 11px;
    }

    .homefaq .accordion-button {
        font-size: 13px;

    }

    .homefaq .accordion-button:not(.collapsed) {
        font-size: 13px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .homefaq .heading-holder .title {
        font-size: 25px;
    }

    .homefaq .heading-holder p {
        font-size: 14px;
    }
}

@media(min-width:768px) and (max-width:991px) {
    .homefaq .heading-holder .title {
        font-size: 20px;
    }

    .homefaq .heading-holder p {
        font-size: 14px;
    }
}

@media (min-width: 0px) and (max-width: 767px) {
    .homefaq .heading-holder .title {
        font-size: 20px;
    }

    .homefaq .heading-holder p {
        font-size: 14px;
    }

    .homefaq .heading-holder h5 {
        font-size: 13px;

    }
}