.OnlineMca-section {
    background: #FFF8F0 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 3% 0%;
    margin: 6% 0px 0% 0px;
}

.OnlineMca-section .main-onlin-sec .onlin-img-div .onlin-img {
    width: 100%;
    height: 100%;
}

.OnlineMca-section .main-onlin-sec .online-content .descrptn h5 {
    font-size: 32px;
    color: #000;
    font-weight: bold;
    margin-bottom: 20px;
}

.OnlineMca-section .main-onlin-sec .online-content .descrptn p {
    font-size: 16px;
    color: #000;
    font-weight: 300;
    line-height: 32px;
}

.OnlineMca-section .main-onlin-sec .online-content .video-div .online-video {
    width: 100%;
    border-radius: 15px;
    border: 5px solid #fff;
}

/* .OnlineMca-section .main-onlin-sec .online-content .descrptn {
    margin-top: 8%;
} */


@media (min-width:0px) and (max-width:767px) {

    .OnlineMca-section .main-onlin-sec .online-content .descrptn h5 {
        font-size: 20px;
        color: #000;
    }

    .OnlineMca-section .main-onlin-sec .online-content .video-div {
        margin-top: 0%;
    }

    .OnlineMca-section .main-onlin-sec .online-content .descrptn p {
        font-size: 13px;
        color: #000;
    }

    .OnlineMca-section .main-onlin-sec .online-content .descrptn {
        margin-top: 0%;
    }
}

@media (min-width:768px) and (max-width:991px) {

    .OnlineMca-section .main-onlin-sec .online-content .descrptn h5 {
        font-size: 25px;
    }

    .OnlineMca-section .main-onlin-sec .online-content .descrptn p {
        font-size: 13px;
    }

    .OnlineMca-section .main-onlin-sec .online-content .video-div {
        margin-top: 22%;
    }
}

@media (min-width:992px) and (max-width:1200px) {
    .OnlineMca-section .main-onlin-sec .online-content .descrptn {
        margin-top: 0%;
    }

    .OnlineMca-section .main-onlin-sec .online-content .descrptn h5 {
        font-size: 28px;
    }

    .OnlineMca-section .main-onlin-sec .online-content .descrptn p {
        font-size: 14px;
    }
}