.QuestionsStepFormalumini {
  padding-top: 0%;
  padding-bottom: 5%;
}

.QuestionsStepFormalumini .aluminifirstrow.nav.nav-pills {
  justify-content: center;
  display: flex;
}

.QuestionsStepFormalumini .StepForm .heading-holder .heading-text h6 {
  font-size: 18px;
  font-weight: 600;
  color: #131313;
  text-align: center;
  margin-bottom: 0px;
}

.QuestionsStepFormalumini .StepForm .heading-holder {
  padding: 20px 25px;
  border-radius: 10px 10px 0px 0px;
}

.QuestionsStepFormalumini .progress-indicator {
  background-color: #EBEBEB;
}

.QuestionsStepFormalumini .BacktoCompanies p {
  margin-bottom: 0px;
  text-align: center;
  margin-top: 3%;
}

/* stepform css start */

.QuestionsStepFormalumini .StepForm .StepForm-holder h3 {
  font-size: 23px;
  font-weight: 600;
  color: #131313;
  text-align: center;
  margin-bottom: 4%;
}

.QuestionsStepFormalumini .StepForm .StepForm-holder p {
  font-size: 14px;
  text-align: center;
}

.QuestionsStepFormalumini .StepForm .StepForm-holder .resume .resume-text-holder p {
  font-size: 14px;
  font-weight: 500;
  color: #131313;
}

.QuestionsStepFormalumini .StepForm .StepForm-holder .compress_pdf .text-holder p {
  font-size: 12px;
  font-weight: 600;
  color: #131313;
  margin-bottom: 0px;
}

.QuestionsStepFormalumini .StepForm .StepForm-holder .compress_pdf .text-holder span {
  font-size: 11px;
  font-weight: 500;
  color: #131313;
}

.QuestionsStepFormalumini .StepForm .StepForm-holder .compress_pdf .download-btn .download-icon {
  font-size: 12px;
  margin-left: 10px;
  background: #dbdbdb;
  padding: 8px;
  border-radius: 50px;
}

.QuestionsStepFormalumini .StepForm .StepForm-holder .compress_pdf .download-btn {
  display: flex;
  justify-content: end;
}

.QuestionsStepFormalumini .StepForm .StepForm-holder .upload-file {
  margin-top: 3%;
}

.QuestionsStepFormalumini .StepForm .StepForm-holder .upload-file .upload-btn-holder p {
  font-size: 11px;
  font-weight: 500;
  color: #8d8d8d;
  margin-bottom: 0px;
}

.QuestionsStepFormalumini .StepForm .StepForm-holder .upload-file .upload-btn-holder {
  display: flex;
  justify-content: start;
  align-items: center;
}

.QuestionsStepFormalumini .StepForm .StepForm-holder .upload-file .upload-btn-holder .apply-btn-holder {
  margin-right: 4%;
}

.QuestionsStepFormalumini .StepForm .StepForm-holder .upload-file .upload_resume p {
  text-decoration: underline;
  font-size: 15px;
  font-weight: 500;
  color: #131313;
}

.QuestionsStepFormalumini .StepForm .StepForm-holder .upload-file .upload_resume {
  text-align: right;
}

.QuestionsStepFormalumini .StepForm .StepForm-holder .upload-file .upload_resume p:hover {
  color: #1967d3;
}

.QuestionsStepFormalumini .StepForm .StepForm-holder .upload-file .upload_resume .upload-icon {
  margin-left: 1%;
}

.QuestionsStepFormalumini .StepForm .nav-pills .nav-link {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #7070704D;
  border-radius: 5px;
  color: #000;
  margin: 8px;
  width: 184px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.QuestionsStepFormalumini .StepForm .nav-pills .nav-link.active {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #FF9900;
  border-radius: 5px;
}

.StepForm-holder .QuestionsStepFormalumini .StepForm .aluminifirstrow {
  display: flex;
  justify-content: center;

}

.QuestionsStepFormalumini .StepForm .alustepimg {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.QuestionsStepFormalumini .StepForm .aluminipara p {
  margin-bottom: 0;
  font-size: 13px;
  text-align: center;
}

.QuestionsStepFormalumini .StepForm .form-select {
  width: 184px;
}

.QuestionsStepFormalumini .StepForm .aluminiselect {
  justify-content: center;
  display: flex;
}

.QuestionsStepFormalumini .StepForm .notdecidedbtn {
  width: 184px;
  margin-bottom: 15px;
  border: 1px solid #70707080;
  border-radius: 5px;
  background-color: transparent;
  color: #000;
}

.QuestionsStepFormalumini .StepForm .stepsevenmain .stepseven p {
  margin-bottom: 3px;
  text-align: center;
  font-size: 14px;
}

.QuestionsStepFormalumini .StepForm .stepsevenmain .nav-pills .nav-link {
  height: 100px;
}

.QuestionsStepFormalumini .StepForm .steptwelve {
  background: #FBFBFB 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #6868684F;
  border-radius: 10px;
  padding: 15px 15px 39px 36px;
  margin: 5px;
}

.QuestionsStepFormalumini .StepForm .steptwelve .form-control {
  border-bottom: 1px solid #BFBFBFB3;
  border-top: 1px solid #BFBFBFB3;
  border-right: 1px solid #BFBFBFB3;
  border-left: 1px solid #BFBFBFB3;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  box-shadow: none;

}


.notyedinput {
  width: 184px !important;
  margin: 10px auto;
  border: 1px solid #ccc !important;
  border-radius: 6px !important;
}

/* stepform css end */