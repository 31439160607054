.mca-card-section
{
    background-image: url('/public/assets/images/Courses/M.Lib/bg-img.png');
background-position: center;
background-repeat: no-repeat;
background-size: cover;
height: 100%;
width: 100%;
padding: 2% 0px 3% 0%;
}
    
.mca-card-section .card-div {
    background: #FF860059 0% 0% no-repeat padding-box;
    border-radius: 62px;
    opacity: 1;
    text-align: center;
    margin: 10px 22px;
    height: 215px;
    border: 4px solid #EBEBEB;
}
.mca-card-section .main-div {
    position: relative;
    margin-bottom: 32%;
}

.mca-card-section .card-div .card-inner-div {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    padding: 12px;
}
.mca-card-section .card-div .card-inner-div p {
    font-size: 16px;
    color: black;
    font-weight: 400;
    margin: 0px;
}
.mca-card-section .card-div .card-inner-div .bold-text
{
    font-size: 16px;
    color: black;
    font-weight: 500;
}
.mca-card-section .shape-div {
    left: 50%;
    position: absolute;
    text-align: center;
    bottom: -92px;
    transform: translate(-50%,-50%);
}
.mca-card-section .orange-shape {
    background: none;
    width: 0;
    height: 0;
    border-top: 90px solid #F0721F;
    border-right: 90px solid transparent;
    margin: auto;
    border-radius: 30px;
    transform: rotate(-135deg);
}
.shadow-div {
    width: 112px;
    height: 4px;
    background: #00000036 0% 0% no-repeat padding-box;
    opacity: 1;
    filter: blur(1px);
    position: absolute;
    bottom: -63px;
    transform: translate(-50%,-50%);
    text-align: center;
    left: 50%;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
@media (min-width:0px) and (max-width:480px)
{
    .mca-card-section .main-div {
        margin-bottom: 25%;
    }
    .mca-card-section .card-div {
        margin: 10px 50px;
    }
    .mca-card-section .card-div .card-inner-div p {
        font-size: 14px;
    }
    .mca-card-section .card-div .card-inner-div .bold-text {
        font-size: 14px;
    }
}
@media (min-width:481px) and (max-width:575px)
{
    .mca-card-section .main-div {
        margin-bottom: 15%;
    }
    .mca-card-section .card-div {
        margin: 10px 80px;
    }
    .mca-card-section .card-div .card-inner-div p {
        font-size: 14px;
    }
    .mca-card-section .card-div .card-inner-div .bold-text {
        font-size: 14px;
    }
}
@media (min-width:576px) and (max-width:768px) 
{
    .mca-card-section .main-div {
        margin-bottom: 30%;
    }
    .mca-card-section .card-div .card-inner-div p {
        font-size: 14px;
    }
    .mca-card-section .card-div .card-inner-div .bold-text {
        font-size: 14px;
    }
}
@media (min-width:992px) and (max-width:1200px) 
{
    .mca-card-section .orange-shape {
        border-top: 70px solid #F0721F;
        border-right: 70px solid transparent;
        border-radius: 25px;
    }
    .mca-card-section .shape-div {
        bottom: -73px;
    }
    .shadow-div {
        width: 70px;
        bottom: -56px;
    }
}
