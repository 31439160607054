.profcyma-edge {
    background-image: url('/public/assets/images/home/profcyma-edge/profcymaedgebg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    margin-bottom: 5%;
}

.profcyma-edge .edge-img {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 100%;
}

.profcyma-edge .title-profcyma-edge h5 {
    font-size: 18px;
    font-weight: 600;
    color: #FF8600;
    margin-top: 9%;
}

.profcyma-edge .title-profcyma-edge h2 {
    font-size: 32px;
    font-weight: 600;
    color: #FFF;
}

.profcyma-edge .title-profcyma-edge p {
    font-size: 16px;
    color: #FFF;
}

.profcyma-edge .profcyma-edge-swiper {
    background: #FFF8F0;
    border-radius: 13px;
    padding: 30px 30px 10px 30px;
}

.profcyma-edge .profcymaedge-main-img-class {
    position: relative;
}

.profcyma-edge .profcymaedge-main-img-class .headphone-img {
    position: absolute;
    top: 73%;
    right: 10%;
}

.profcyma-edge .swiper-pagination-bullet {
    width: 10px;
    height: 3px;
    border-radius: 5px;
    opacity: 1;
    background-color: #CECECE;
}

.profcyma-edge .swiper-pagination-bullet-active {
    width: 18px;
    background: #000;
}

.profcyma-edge .profcyma-edge-swiper .alumini-main-img-class .alumini-img {
    width: 80%;
}

.profcyma-edge .swiper-grid-column>.swiper-wrapper {
    flex-wrap: wrap;
    flex-direction: column;
}

.profcyma-edge .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.profcyma-edge .grid-item {
    position: relative;
    overflow: hidden;
}

.profcyma-edge .test-swiper-edge h5 {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin-bottom: 0px;
}

.profcyma-edge .test-swiper-edge .read-more-text {
    font-size: 12px;
}

.profcyma-edge .read-more-button {
    font-size: 12px;
    font-weight: bold;
}

.profcyma-edge .display-text-group span {
    font-size: 13px;
}

.profcyma-edge .readMoretxt {
    /* margin-left: 15px; */
    font-weight: 600;
    font-size: 12px;

}

.profcyma-edge .edgedescp {
    font-size: 14px;
}



@media(min-width:0px) and (max-width:320px) {
    .profcyma-edge .profcymaedge-main-img-class .headphone-img {
        top: 72%;
        right: 13%;
        width: 40px;
    }

    .profcyma-edge .title-profcyma-edge h2 {
        font-size: 20px;
    }

    .profcyma-edge .title-profcyma-edge p {
        font-size: 13px;
    }

    .profcyma-edge .title-profcyma-edge h5 {
        font-size: 14px;
    }

    .profcyma-edge .profcyma-edge-swiper .alumini-main-img-class .alumini-img {
        width: 45%;
    }

    .profcyma-edge .test-swiper-edge h5 {
        font-size: 15px;
        margin-top: 10px;
    }

    .profcyma-edge .profcyma-edge .test-swiper-edge .read-more-text {
        font-size: 12px;
    }

    .profcyma-edge .swiper {
        padding: 0% 0% 18% 0%;
    }

    .profcyma-edge .edgedescp {
        font-size: 13px;
    }
}

@media(min-width:321px) and (max-width:485px) {
    .profcyma-edge .profcymaedge-main-img-class .headphone-img {
        position: absolute;
        top: 74%;
        right: 12%;
        width: 60px;
    }

    .profcyma-edge .title-profcyma-edge h2 {
        font-size: 20px;
    }

    .profcyma-edge .title-profcyma-edge p {
        font-size: 14px;
    }

    .profcyma-edge .title-profcyma-edge h5 {
        font-size: 16px;

    }

    .profcyma-edge .profcyma-edge-swiper .alumini-main-img-class .alumini-img {
        width: 32%;
    }

    .profcyma-edge .test-swiper-edge h5 {
        font-size: 15px;
        margin-top: 10px;
    }

    .profcyma-edge .profcyma-edge .test-swiper-edge .read-more-text {
        font-size: 13px;
    }

    .profcyma-edge .display-text-group span {
        font-size: 12px;
    }

    .profcyma-edge .swiper {
        padding: 0% 0% 18% 0%;
    }

    .profcyma-edge .edgedescp {
        font-size: 13px;
    }
}

@media(min-width:486px) and (max-width:576px) {
    .profcyma-edge .profcymaedge-main-img-class .headphone-img {
        position: absolute;
        top: 74%;
        right: 12%;
        width: 60px;
    }

    .profcyma-edge .title-profcyma-edge h2 {
        font-size: 28px;
    }

    .profcyma-edge .title-profcyma-edge p {
        font-size: 14px;
    }

    .profcyma-edge .title-profcyma-edge h5 {
        font-size: 16px;
    }

    .profcyma-edge .profcyma-edge-swiper .alumini-main-img-class .alumini-img {
        width: 18%;
    }

    .profcyma-edge .test-swiper-edge h5 {
        font-size: 15px;
    }

    .profcyma-edge .profcyma-edge .test-swiper-edge .read-more-text {
        font-size: 13px;
    }

    .profcyma-edge .swiper {
        padding: 0% 0% 18% 0%;
    }

    .profcyma-edge .edgedescp {
        font-size: 13px;
    }
}

@media(min-width:577px) and (max-width:767px) {
    .profcyma-edge .profcymaedge-main-img-class .headphone-img {
        top: 71%;
        right: 6%;
        height: 30px;
        width: 30px;
    }

    .profcyma-edge .title-profcyma-edge h2 {
        font-size: 27px;
    }

    .profcyma-edge .title-profcyma-edge p {
        font-size: 13px;
        color: #FFF;
    }

    .profcyma-edge .profcyma-edge-swiper .alumini-main-img-class .alumini-img {
        width: 30%;
    }

    .profcyma-edge .swiper {
        padding: 0% 0% 18% 0%;
    }

    .profcyma-edge .edgedescp {
        font-size: 13px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .profcyma-edge .profcymaedge-main-img-class .headphone-img {
        top: 71%;
        right: 12%;
        height: 30px;
        width: 30px;
    }

    .profcyma-edge .title-profcyma-edge h2 {
        font-size: 27px;
    }

    .profcyma-edge .title-profcyma-edge p {
        font-size: 13px;
        color: #FFF;
    }

    .profcyma-edge .profcyma-edge-swiper .alumini-main-img-class .alumini-img {
        width: 162%;
    }

    .profcyma-edge .test-swiper-edge h5 {
        font-size: 15px;
    }

    .profcyma-edge .edgedescp {
        font-size: 15px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .profcyma-edge .profcymaedge-main-img-class .headphone-img {
        top: 74%;
        right: 12%;
        height: 45px;
        width: 45px;
    }

    .profcyma-edge .title-profcyma-edge h2 {
        font-size: 27px;
    }

    .profcyma-edge .title-profcyma-edge p {
        font-size: 13px;
        color: #FFF;
    }

    .profcyma-edge .test-swiper-edge h5 {
        font-size: 15px;

    }
}