.document {
    width: 100%;
    position: relative;
}

.document .document-banner-img {
    width: 100%;
}

.document .document-content {
    width: 100%;
    height: 100%;
    background-image: url('../../../../../public/assets/images/admission/addmsbbg.png');
    background-position: center;
    background-size: cover;
}


.document .text-holder-document {
    text-align: center;
    color: #000000;
}

.document .text-holder-document h2 {
    font-size: 32px;
    font-weight: bold;
}

.document .text-holder-document p {
    font-size: 16px;
}

.document .document-content ul {
    list-style: none;
    line-height: 15px;
}

.document .document-content ul li {
    font-size: 16px;
}

.document .document-content ul li::before {
    content: "";
    border-color: transparent #111;
    border-style: solid;
    border-width: 0.55em 0 0.55em 0.65em;
    display: block;
    height: 0;
    width: 0;
    left: -26px;
    top: 13.9px;
    position: relative;
    border-radius: 3px;
}

/* Media  */
@media(min-width:0px) and (max-width:320px) {
    .document .document-content {
        position: unset;
    }

    .document .text-holder-document h2 {
        font-size: 20px;
        margin-top: 14%;
    }

    .document .document-banner-img {
        display: none;
    }

    .document .document-content ul {
        line-height: normal;
    }

    .document .document-content ul li {
        font-size: 13px;
    }

}

@media(min-width:321px) and (max-width:485px) {
    .document .document-content {
        position: unset;
    }

    .document .text-holder-document h2 {
        font-size: 20px;
        margin-top: 14%;
    }

    .document .document-banner-img {
        display: none;
    }

    .document .document-content ul {
        line-height: normal;
    }

    .document .document-content ul li {
        font-size: 14px;
    }
}

@media(min-width:486px) and (max-width:576px) {
    .document .document-content {
        position: unset;
    }

    .document .text-holder-document h2 {
        font-size: 20px;
        margin-top: 14%;
    }

    .document .document-banner-img {
        display: none;
    }

    .document .document-content ul {
        line-height: normal;
    }

    .document .document-content ul li {
        font-size: 14px;
    }
}

@media(min-width:577px) and (max-width:768px) {
    .document .document-content {
        position: unset;
    }

    .document .text-holder-document h2 {
        font-size: 20px;
        margin-top: 14%;
    }

    .document .document-banner-img {
        display: none;
    }

    .document .document-content ul {
        line-height: normal;
    }

    .document .document-content ul li {
        font-size: 14px;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .document .document-content {
        position: unset;
    }

    .document .text-holder-document h2 {
        font-size: 20px;
        margin-top: 14%;
    }

    .document .document-banner-img {
        display: none;
    }

    .document .document-content ul {
        line-height: normal;
    }

    .document .document-content ul li {
        font-size: 15px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .document .document-content {
        top: 4%;
    }

    .document .text-holder-document h2 {
        font-size: 25px;
    }

    .document .document-content ul li {
        font-size: 13px;
    }

    .document .document-content {
        top: 10%;
    }
}