.profcyma-help {
    margin: 0% 0% 7% 0;
}

.title-profcyma-help h5 {
    font-size: 18px;
    font-weight: 600;
    color: #FF8600;
}

.title-profcyma-help h2 {
    font-size: 32px;
    font-weight: 700;
    color: #000000;
}

.title-profcyma-help p {
    font-size: 16px;
    color: #383838;
    font-weight: 500;
}

.profcyma-help .help-card {
    border-radius: 10px;
    border: none;
    background: #FFFFFF;
    box-shadow: #ebe8e8 0px 3px 10px;
    padding: 18px;
    margin: 0px 8px 0px 8px;
}

.profcyma-help .help-card:hover {
    box-shadow: #ff86008a 0px 3px 10px;
}

.profcyma-help .help-card .text-holder-title-university {
    position: relative;
}

.profcyma-help .help-card .text-holder-title-university .main-grey-class {
    position: absolute;
    top: -34%;
    left: 32%;
}

.profcyma-help .help-card .text-holder-title-university .main-grey-class .grey-backround {
    position: relative;
    top: -34%;
    left: 0%;
    background: #EFEFEF;
    box-shadow: #ebe8e8 0px 3px 10px;
    border-radius: 50px;
    height: 90px;
    width: 90px;
}

/* down */

.profcyma-help .help-card .text-holder-title-university .main-grey-down-class {
    position: absolute;
    bottom: -34%;
    left: 32%;
}

.profcyma-help .help-card .text-holder-title-university .main-grey-down-class .grey-backround {
    position: relative;
    top: -34%;
    left: 0%;
    background: #EFEFEF;
    box-shadow: #ebe8e8 0px 3px 10px;
    border-radius: 50px;
    height: 90px;
    width: 90px;
}

.profcyma-help .help-card .text-holder-title-university .grey-backround .white-backround-main {
    background: #FFF;
    border-radius: 50px;
    font-size: 50px;
    padding: 10px;
    position: absolute;
    top: 10px;
    left: 10px;
    height: 70px;
    width: 70px;
}

.profcyma-help .help-card .text-holder-title-university .grey-backround .white-backround .first-icon {
    background: #FFF;
    border-radius: 50px;
    font-size: 50px;
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0px;
    height: 100%;
    width: 100%;
}

.profcyma-help .help-card .text-holder-title-university .grey-backround .white-backround .first-icon:hover {
    filter: invert(74%) sepia(31%) saturate(6116%) hue-rotate(1deg) brightness(103%) contrast(106%);
    box-shadow: unset !important;
    background: transparent;
}

.profcyma-help .help-card .text-holder-title-university h3 {
    font-size: 28px;
    font-weight: 600;
    margin-top: 20%;
}
.profcyma-help .help-card .text-holder-title-university p {
    font-size: 16px;
    color: #313131;
    margin-top: 6%;
}


.profcyma-help .help-card:hover {
    -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
    }
}

@keyframes slide-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
    }
}
/* media query */

@media(min-width:0px) and (max-width:320px) {
    .title-profcyma-help h5 {
        font-size: 16px;
    }

    .title-profcyma-help h2 {
        font-size: 18px;
    }

    .profcyma-help .help-card .text-holder-title-university h3 {
        font-size: 18px;
    }

    .title-profcyma-help p {
        font-size: 13px;
    }

    .profcyma-help .help-card .text-holder-title-university p {
        font-size: 14px;
    }

    .profcyma-help .help-card .text-holder-title-university .main-grey-class {
        top: -40%;
        left: 32%;

    }

    .profcyma-help .help-card .text-holder-title-university .main-grey-down-class {
        left: 31%;
        top: 4%;
    }
}

@media(min-width:321px) and (max-width:485px) {
    .title-profcyma-help h5 {
        font-size: 16px;
    }

    .title-profcyma-help h2 {
        font-size: 20px;
    }

    .profcyma-help .help-card .text-holder-title-university h3 {
        font-size: 18px;
    }

    .title-profcyma-help p {
        font-size: 13px;
    }

    .profcyma-help .help-card .text-holder-title-university p {
        font-size: 14px;
    }

    .profcyma-help .help-card .text-holder-title-university .main-grey-class {
        top: -31%;

    }

    .profcyma-help .help-card .text-holder-title-university .main-grey-down-class {
        left: 38%;
        top: 10%;
    }
}

@media(min-width:360px) and (max-width:486px) {
    .profcyma-help .help-card .text-holder-title-university .main-grey-class {
        left: 38%;

    }

    .profcyma-help .help-card .text-holder-title-university .main-grey-down-class {
        left: 38%;
        top: 10%;
    }
}

@media(min-width:486px) and (max-width:576px) {
    .title-profcyma-help h5 {
        font-size: 14px;
    }

    .title-profcyma-help h2 {
        font-size: 20px;
    }

    .profcyma-help .help-card .text-holder-title-university h3 {
        font-size: 20px;
    }

    .title-profcyma-help p {
        font-size: 13px;
    }

    .profcyma-help .help-card .text-holder-title-university p {
        font-size: 14px;
        margin-top: 4%;
    }

    .profcyma-help .help-card .text-holder-title-university .main-grey-class {
        left: 43%;
    }

    .profcyma-help .help-card .text-holder-title-university .main-grey-down-class {
        left: 43%;
        top: 10%;
    }
}

@media(min-width:0px) and (max-width:767px) {
    .title-profcyma-help p {
        font-size: 14px;
        text-align: center;
    }

    .title-profcyma-help h2 {
        font-size: 20px;
        text-align: center;
    }

    .title-profcyma-help h5 {
        font-size: 13px;
        text-align: center;
    }
}

@media(min-width:577px) and (max-width:768px) {
    .title-profcyma-help h2 {
        font-size: 20px;
    }

    .profcyma-help .help-card .text-holder-title-university h3 {
        font-size: 19px;
    }

    .title-profcyma-help p {
        font-size: 14px;
    }

    .profcyma-help .help-card .text-holder-title-university .main-grey-class {
        top: -49%;
        left: 31%;
    }

    .profcyma-help .help-card .text-holder-title-university p {
        font-size: 13px;
    }

    .profcyma-help .help-card .text-holder-title-university .main-grey-down-class {
        bottom: -37%;
        left: 30%;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .title-profcyma-help p {
        font-size: 14px;
        color: #000000;
    }

    .title-profcyma-help h2 {
        font-size: 24px;
    }

    .profcyma-help .help-card .text-holder-title-university h3 {
        font-size: 24px;
    }

    .profcyma-help .help-card .text-holder-title-university .main-grey-class {
        position: absolute;
        top: -34%;
        left: 36%;
    }

    .profcyma-help .help-card .text-holder-title-university .main-grey-down-class {
        position: absolute;
        bottom: -34%;
        left: 37%;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .title-profcyma-help h2 {
        font-size: 26px;
    }

    .profcyma-help .help-card .text-holder-title-university h3 {
        font-size: 21px;
    }

    .profcyma-help .help-card .text-holder-title-university p {
        font-size: 14px;

    }

    .profcyma-help .help-card .text-holder-title-university .main-grey-class {
        position: absolute;
        top: -43%;
        left: 28%;
    }

    .profcyma-help .help-card .text-holder-title-university .main-grey-down-class {
        position: absolute;
        bottom: -34%;
        left: 26%;
    }
}

@media(min-width:1201px) and (max-width:1400px) {
    .title-profcyma-help h2 {
        font-size: 26px;
    }

    .profcyma-help .help-card .text-holder-title-university h3 {
        font-size: 21px;
    }

    .profcyma-help .help-card .text-holder-title-university p {
        font-size: 14px;
    }

    .profcyma-help .help-card .text-holder-title-university .main-grey-class {
        position: absolute;
        top: -38%;
        left: 28%;
    }
}