.inner-banner {
    background: #FFF8F0 0% 0% no-repeat padding-box;
    opacity: 1;
}

.banners {
    margin-bottom: 115px;
}

.inner-banner .innerbanner-img-main {
    position: relative;
}

.inner-banner .innerbanner-img-main .icon-div {
    position: absolute;
    top: 10px;
    left: 10px;
}

.inner-banner .innerbanner-img-main .icon-div .backside-icon {
    width: 25px;
}

.inner-banner .innerbanner-img-main .innerbanner-img {
    width: 100%;
    height: auto;
    background: no-repeat;
}

.inner-banner .introduction {
    padding-bottom: 50px;
}

.inner-banner .introduction h2 {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.inner-banner .introduction p {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

@media(min-width:0px) and (max-width:321) {
    .inner-banner .innerbanner-img-main .icon-div {
        top: 5px;

    }
}

@media(min-width:0px) and (max-width:320px) {
    .banners {
        margin-bottom: 25px;
    }

    .inner-banner .introduction h2 {
        font-size: 22px;
    }

    .inner-banner .introduction p {
        font-size: 11px;
    }

    .inner-banner .introduction {
        padding-bottom: 15px;
    }

}

@media(min-width:321px) and (max-width:485px) {
    .banners {
        margin-bottom: 25px;
    }

    .inner-banner .introduction h2 {
        font-size: 22px;
    }

    .inner-banner .introduction p {
        font-size: 11px;
    }

    .inner-banner .introduction {
        padding-bottom: 15px;
    }

}

@media(min-width:486px) and (max-width:575px) {
    .banners {
        margin-bottom: 35px;
    }

    .inner-banner .introduction h2 {
        font-size: 25px;
    }

    .inner-banner .introduction p {
        font-size: 13px;
    }

    .inner-banner .introduction {
        padding-bottom: 22px;
    }

}

@media(min-width:576px) and (max-width:767px) {
    .banners {
        margin-bottom: 40px;
    }

    .inner-banner .introduction h2 {
        font-size: 28px;
    }

    .inner-banner .introduction p {
        font-size: 14px;
    }

    .inner-banner .introduction {
        padding-bottom: 27px;
    }

}

@media(min-width:768px) and (max-width:991px) {
    .banners {
        margin-bottom: 40px;
    }

    .inner-banner .introduction h2 {
        font-size: 29px;
    }

    .inner-banner .introduction p {
        font-size: 15px;
    }

    .inner-banner .introduction {
        padding-bottom: 27px;
    }

}

@media(min-width:992px) and (max-width:1200px) {
    .banners {
        margin-bottom: 100px;
    }

    .inner-banner .introduction h2 {
        font-size: 29px;
    }

    .inner-banner .introduction p {
        font-size: 15px;
    }

    .inner-banner .introduction {
        padding-bottom: 40px;
    }

}

@media (min-width:0px) and (max-width:768px) {
    .inner-banner .innerbanner-img-main .icon-div .backside-icon {
        width: 20px;
    }
}