.alumini-hub {   
    width: 100%;
    height: 100%;
    border: 1px solid #707070;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0% 0% 5% 0%;
}

.alumini-hub .background-banner-img {
    width: 100%;
    height: auto;
    position: relative;
}

.alumini-hub .alumini-hub-swiper  {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
}

.alumini-hub .title-profcyma-help {
    position: relative;
    z-index: 99;
}

.alumini-hub .swiper-pagination-bullet {
    width: 6px;
    height: 4px;
    border-radius: 5px;
    opacity: 1;
    background-color: #CECECE;
}

.alumini-hub .swiper-pagination {
    text-align: left;
    left: 2% !important;
}

.alumini-hub .swiper-pagination-bullet-active {
    width: 18px;
    background: #000;
}

.alumini-hub .alumini-main-img-class .alumini-img {
    width: 100%;
    height: auto;
}

.alumini-hub .alumini-hub-inter{
    position: relative;
    width: 100%;
    height: 100%;
}

.alumini-hub  .alumini-hun-backround .background-banner-img {
    width: 100%;
    position: absolute;
    right: 0px;
    bottom: 0px;
    top: 0;
    height: 100%;
}

@media(min-width:0px) and (max-width:375px) {
    .alumini-hub .alumini-main-img-class .alumini-img {
        width: 75%;
    }
    .alumini-hub  .alumini-hun-backround .background-banner-img {
        display: none;
    }
    .alumini-hub .swiper-pagination {
        left: 45% !important;
    }
}

@media(min-width:376px) and (max-width:486px) {
    .alumini-hub .alumini-main-img-class .alumini-img {
        width: 57%;
    }
    .alumini-hub  .alumini-hun-backround .background-banner-img {
        display: none;
    }
    .alumini-hub .swiper-pagination {
        left: 45% !important;
    }
}

@media(min-width:487px) and (max-width:766px) {
    .alumini-hub .alumini-main-img-class .alumini-img {
        width: 56%;
    }
    .alumini-hub  .alumini-hun-backround .background-banner-img {
        display: none;
    }
    .alumini-hub .swiper-pagination {
        left: 45% !important;
    }
}

@media(min-width:767px) and (max-width:768px) {
    .alumini-hub .alumini-main-img-class .alumini-img {
        width: 100%;
    }
    .alumini-hub  .alumini-hun-backround .background-banner-img {
        display: block;
    }
    .alumini-hub .swiper-pagination {
        left: 45% !important;
    }
}

@media(min-width:769px) and (max-width:991px){
    .alumini-hub  .alumini-hun-backround .background-banner-img {
        width: 109%;
    }
}

@media(min-width:992px) and (max-width:1200px){
    .alumini-hub .alumini-main-img-class .alumini-img {
        width: 60%;
    }
    .alumini-hub  .alumini-hun-backround .background-banner-img {
        width: 106%;
    }
}



@media(min-width:1201px) and (max-width:1400px){
    .alumini-hub  .alumini-hun-backround .background-banner-img {
        width: 108%;
    }
}

