.programe-highlight {
    margin: 5% 0;
}

.programe-highlight .heading-holder h2 {
    font-size: 20px;
    font-weight: 500;


}

.programe-highlight .heading-holder .border-line {
    border-bottom: 2px solid #FF9900;
    width: 146px;
    margin: auto;
}



.programe-highlight .main-section {
    position: relative;
}

.programe-highlight .main-section .all-image {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 27px;
}

.programe-highlight .main-section .text-holder {
    position: absolute;
    bottom: 0px;
    left: 24px;
    z-index: 1;
}


.programe-highlight .main-section .text-holder p {
    font-size: 18px;
    color: #FFF;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
}

.programe-highlight .main-section .bg-overlay {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(180deg, #00000000 0%, #031547 100%) 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 27px 27px;
}

.programe-highlight .silder-btn {
    position: relative;
}

.programe-highlight .silder-btn .back-btn {
    content: url("../../../../../public/assets/images/spaecialization/prev.png");
    width: 20px;
    height: 20px;
    margin: 10px;
    cursor: pointer;
    position: absolute;
    bottom: 0px;
    z-index: 999;
    left: 47%;
}

.programe-highlight .silder-btn .next-btn {
    content: url("../../../../../public/assets/images/spaecialization/next.png");
    width: 20px;
    height: 20px;
    margin: 10px;
    cursor: pointer;
    position: absolute;
    bottom: 0px;
    z-index: 999;
    right: 47%;
}