.Stepformoverall {
    background-image: url("../../../../public/assets/images/stepform/steformbanner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 5%;
    padding-bottom: 5%;
}

.Stepformoverall .stepformMaincon .stepformside{
   width: 100%;
}