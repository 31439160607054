.AmityInternational {
    background: #FFF8F0 0% 0% no-repeat padding-box;
    padding-bottom: 12px;
}

.AmityInternational .internationalheadtxt h3 {
    text-align: center;
    font: normal normal bold 32px/45px Open Sans;
    letter-spacing: 0px;
    color: #000000;
}

.AmityInternational .internationalheadtxt {
    padding: 3% 0% 1% 0%;
}
.AmityInternational img {
    width: 100%;
}
.AmityInternational .internationalheadtxt p {
    text-align: center;
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #383838;
}

.AmityInternational .internationalbanner {
    width: 100%;
    height: 100%;
}

.AmityInternational .border-line {
    border-bottom: 3px solid #FF8600;
    margin: auto;
    width: 170px;
}

@media screen and (min-width: 0px) and (max-width: 320px) {
    .AmityInternational {
        padding-bottom: 5%;
    }

    .AmityInternational .internationalheadtxt p {
        font-size: 14px;
    }

    .AmityInternational .internationalheadtxt h3 {
        font: normal normal bold 20px/37px Open Sans;
    }
}

@media screen and (min-width: 321px) and (max-width: 991px) {
    .AmityInternational {
        padding-bottom: 5%;
    }

    .AmityInternational .internationalheadtxt p {
        font-size: 14px;
    }

    .AmityInternational .internationalheadtxt h3 {
        font: normal normal bold 20px/28px Open Sans;
    }


}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .AmityInternational .internationalheadtxt h3 {
        font: normal normal bold 25px/28px Open Sans;
    }


}