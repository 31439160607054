.ed-techpartner-page {
    padding: 20px;
    margin: 0% 0% 5% 0%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
}

.ed-techpartner-page h1 {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
}

.ed-techpartner-page .form-control,
.ed-techpartner-page .form-control:focus {
    box-shadow: 0px 0px 5px #00000029 !important;
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border-radius: 5px;
    margin-top: 3%;
    border: none;
    outline: none;
    font-size: 15px;
    font-weight: 500;
}

.ed-techpartner-page .form-label {
    font-size: 16px;
    font-weight: 500;
}

.ed-techpartner-page .submit-btn {
    background: #FF8600;
    border-radius: 5px;
    border: none;
    color: #FFFFFF;
    font-size: 16px;
    margin-top: 3%;
    padding: 5px 20px;
}

@media(min-width:0px) and (max-width:767px) {
    .ed-techpartner-page h1 {
        font-size: 20px;
    }

    .ed-techpartner-page .form-control,
    .ed-techpartner-page .form-control:focus {
        font-size: 13px;

    }

    .ed-techpartner-page .form-label {
        font-size: 14px;

    }

    .ed-techpartner-page {
        padding: 13px;
        margin: 4% 6%;
    }

    .ed-techpartner-page .submit-btn {
        font-size: 14px;
    }
}
@media (min-width:769px) and (max-width:991px)
{
    .ed-techpartner-page h1 {
        font-size: 23px;
    }
}
@media (min-width:992px) and (max-width:1200px)
{
    .ed-techpartner-page h1 {
        font-size: 25px;
    }
}