.myths-section {
    margin: 7% 0%;
    background-image: url("../../../../../../public/assets/images/Blogs/innerpages/mythbackground.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.myths-section .myths-main .myths-part {
    margin: 55px 0px;
}

.myths-section .myths-main .myths-part .myth-heading {
    width: 221px;
    height: 57px;
    display: flex;
    background: #FF8600BF 0% 0% no-repeat padding-box;
    border-radius: 0px 20px;
    opacity: 1;
    align-items: center;
    margin-bottom: 15px;
}

.myths-section .myths-main .myths-part .left-heading {
    padding-left: 41px;
}


.myths-section .myths-main .myths-part .myth-heading h3 {
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}



.myths-section .myths-main .myths-part .left-contents {
    padding-left: 45px;
}

.myths-section .myths-main .myths-part .myth-contents h3 {
    font-size: 32px;
    font-weight: bold;
    line-height: 45px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    /* display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2; */
}

.myths-section .myths-main .myths-part .myth-contents p {
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    /* display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3; */
}

.myths-section .myths-main .myths-part .right-heading {
    padding-right: 41px;
    margin-left: auto;
    justify-content: end;
}

.myths-section .myths-main .myths-part .right-contents {
    padding-right: 45px;
}


@media(min-width:0px) and (max-width:320px) {
    .myths-section .myths-main .myths-part .myth-contents h3 {
        font-size: 18px;
        line-height: 24px;
    }

    .myths-section .myths-main .myths-part .left-contents {
        padding-left: 23px;
    }

    .myths-section .myths-main .myths-part .left-heading {
        padding-left: 23px;
    }

    .myths-section .myths-main .myths-part .myth-heading {
        width: 132px;
        height: 32px;
        margin-bottom: 12px;
    }

    .myths-section .myths-main .myths-part .myth-heading h3 {
        font-size: 18px;
    }

    .myths-section .myths-main .myths-part .right-heading {
        padding-right: 23px;
    }

    .myths-section .myths-main .myths-part .right-contents {
        padding-right: 23px;
    }

    .myths-section .myths-main .myths-part .myth-contents p {
        font-size: 12px;
        line-height: 20px;
    }
}

@media(min-width:321px) and (max-width:485px) {
    .myths-section .myths-main .myths-part .myth-contents h3 {
        font-size: 18px;
        line-height: 24px;
    }

    .myths-section .myths-main .myths-part .left-contents {
        padding-left: 23px;
    }

    .myths-section .myths-main .myths-part .left-heading {
        padding-left: 23px;
    }

    .myths-section .myths-main .myths-part .myth-heading {
        width: 132px;
        height: 33px;
        margin-bottom: 12px;
    }

    .myths-section .myths-main .myths-part .myth-heading h3 {
        font-size: 18px;
    }

    .myths-section .myths-main .myths-part .right-heading {
        padding-right: 23px;
    }

    .myths-section .myths-main .myths-part .right-contents {
        padding-right: 23px;
    }

    .myths-section .myths-main .myths-part .myth-contents p {
        font-size: 12px;
        line-height: 20px;
    }

}

@media(min-width:486px) and (max-width:575px) {
    .myths-section .myths-main .myths-part .myth-contents h3 {
        font-size: 20px;
        line-height: 24px;
    }

    .myths-section .myths-main .myths-part .left-contents {
        padding-left: 23px;
    }

    .myths-section .myths-main .myths-part .left-heading {
        padding-left: 23px;
    }

    .myths-section .myths-main .myths-part .myth-heading {
        width: 142px;
        height: 35px;
        margin-bottom: 12px;
    }

    .myths-section .myths-main .myths-part .myth-heading h3 {
        font-size: 20px;
    }

    .myths-section .myths-main .myths-part .right-heading {
        padding-right: 23px;
    }

    .myths-section .myths-main .myths-part .right-contents {
        padding-right: 23px;
    }

    .myths-section .myths-main .myths-part .myth-contents p {
        font-size: 13px;
        line-height: 20px;
    }

}

@media(min-width:576px) and (max-width:767px) {
    .myths-section .myths-main .myths-part .myth-contents h3 {
        font-size: 20px;
        line-height: 24px;
    }

    .myths-section .myths-main .myths-part .left-contents {
        padding-left: 23px;
    }

    .myths-section .myths-main .myths-part .left-heading {
        padding-left: 23px;
    }

    .myths-section .myths-main .myths-part .myth-heading {
        width: 152px;
        height: 35px;
        margin-bottom: 13px;
    }

    .myths-section .myths-main .myths-part .myth-heading h3 {
        font-size: 20px;
    }

    .myths-section .myths-main .myths-part .right-heading {
        padding-right: 23px;
    }

    .myths-section .myths-main .myths-part .right-contents {
        padding-right: 23px;
    }

    .myths-section .myths-main .myths-part .myth-contents p {
        font-size: 13px;
        line-height: 20px;
    }
}

@media(min-width:768px) and (max-width:991px) {
    .myths-section .myths-main .myths-part .myth-contents h3 {
        font-size: 22px;
        line-height: 24px;
    }

    .myths-section .myths-main .myths-part .left-contents {
        padding-left: 23px;
    }

    .myths-section .myths-main .myths-part .left-heading {
        padding-left: 23px;
    }

    .myths-section .myths-main .myths-part .myth-heading {
        width: 162px;
        height: 40px;
        margin-bottom: 14px;
    }

    .myths-section .myths-main .myths-part .myth-heading h3 {
        font-size: 22px;
    }

    .myths-section .myths-main .myths-part .right-heading {
        padding-right: 23px;
    }

    .myths-section .myths-main .myths-part .right-contents {
        padding-right: 23px;
    }

    .myths-section .myths-main .myths-part .myth-contents p {
        font-size: 14px;
        line-height: 20px;
    }

}

@media(min-width:992px) and (max-width:1200px) {
    .myths-section .myths-main .myths-part .myth-contents h3 {
        font-size: 24px;
        line-height: 26px;
    }

    .myths-section .myths-main .myths-part .left-contents {
        padding-left: 23px;
    }

    .myths-section .myths-main .myths-part .left-heading {
        padding-left: 23px;
    }

    .myths-section .myths-main .myths-part .myth-heading {
        width: 182px;
        height: 45px;
        margin-bottom: 15px;
    }

    .myths-section .myths-main .myths-part .myth-heading h3 {
        font-size: 24px;
    }

    .myths-section .myths-main .myths-part .right-heading {
        padding-right: 30px;
    }

    .myths-section .myths-main .myths-part .right-contents {
        padding-right: 30px;
    }

    .myths-section .myths-main .myths-part .myth-contents p {
        font-size: 14px;
        line-height: 25px;
    }
}