



.home-counter-section{
    background-image: linear-gradient(180deg,rgb(0 0 0 / 45%) 0%,#00000066 100%),url("/public/assets/images/home/counter/bgcounter.png");
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    margin: 0% 0% 5% 0%;
    padding: 75px 0px;
}

.home-counter-section .counter-div .icon-div{
    min-width: 84px;
    height: 84px;
    border-radius: 100%;
    background-color: #FF8600;
    display: flex;
    justify-content: center;
    align-items: center;
}
.home-counter-section .counter-div .icon-div .icon{ 
    width: 38px;
    height: auto;
}
.home-counter-section .counter-div  .number-div{
    margin-left: 20px;
}   

.home-counter-section .counter-div  .number-div .count-num{
    font-weight: bold;
    font-size: 32px;
    color: #fff;
}
.home-counter-section .counter-div  .number-div .count-name{
    font-weight: normal;
    font-size: 14px;
    color: #fff;
    margin-bottom: 0px;
}
.home-counter-section .counter-div .d-flex{
    justify-content: center;
}

@media (min-width:0px) and (max-width:575px) {
    .home-counter-section {
        padding: 5%  15% 2% 15%;
        height: auto;
    }
    .home-counter-section .counter-div  {
        margin-bottom: 20px;
    }
    .home-counter-section .counter-div .d-flex {
        justify-content: unset;
    }
    .home-counter-section .counter-div  .number-div .count-num {
        font-size: 20px;
    }
    .home-counter-section .counter-div  .number-div .count-name {
        font-size: 13px;
    }
    .home-counter-section .counter-div .icon-div {
        min-width: 55px;
        height: 55px;
    }
    .home-counter-section .counter-div .icon-div .icon {
        width: 25px;
    }
}   

@media (min-width:576px) and (max-width:768px) {
    .home-counter-section .counter-div  .number-div .count-num {
        font-size: 22px;
    }
    .home-counter-section .counter-div  .number-div .count-name {
        font-size: 12px;
    }
    .home-counter-section .counter-div .icon-div {
        min-width: 50px;
        height: 50px;
    }
    .home-counter-section .counter-div .icon-div .icon {
        width: 20px;
    }
    .home-counter-section .counter-div  .number-div {
        margin-left: 10px;
    }
}

@media (min-width:769px) and (max-width:991px) {
    .home-counter-section .counter-div  .number-div .count-num {
        font-size: 25px;
    }
    .home-counter-section .counter-div  .number-div .count-name {
        font-size: 12px;
    }
    .home-counter-section .counter-div .icon-div {
        min-width: 70px;
        height: 70px;
    }
    .home-counter-section .counter-div .icon-div .icon {
        width: 25px;
    }
}


@media (min-width:992px) and (max-width:1200px) {
    
}