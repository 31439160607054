.Mca-reason-section {
    text-align: center;
    background: #FFF8F0 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 5% 0px 1% 0px;
    cursor: pointer;
}

.Mca-reason-section .mca-reason-card:hover .upper-sec h5 {
    color: #F0721F;
}

.Mca-reason-section .mca-reason-card:hover .sec-half-circle::after {
    background: #918a8aad;
}

.Mca-reason-section .heading h4 {
    font-size: 32px;
    font-weight: bold;
    color: #000;
    margin-bottom: 55px;
}

.Mca-reason-section .first-half-circle {
    position: relative;
    height: 100px;
    width: 100%;
    overflow: hidden;
}

.Mca-reason-section .icons-div {
    left: 50%;
    position: absolute;
    text-align: center;
    top: 11%;
    transform: translate(-50%, -50%);
}

.Mca-reason-section .icons-div .main-icon {
    width: 45px;
    margin-top: 19%;
}

.Mca-reason-section .first-half-circle::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background: #c7c7c736;
    height: 168px;
    width: 100%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
}

.Mca-reason-section .sec-half-circle::after {
    content: '';
    position: absolute;
    bottom: 77%;
    left: 20%;
    background: #FF8600;
    height: 154px;
    width: 58%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
}

.Mca-reason-section .mca-reason-card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #00000029;
    border-radius: 10px;
    margin: 0px 10px;
    height: 320px;
}

.Mca-reason-section .mca-reason-card .upper-sec h5 {
    font-size: 20px;
    font-weight: bold;
    color: #000;
    margin: 30px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.Mca-reason-section .mca-reason-card .lower-sec p {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    padding: 8px 5px;
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical; */
}

.Mca-reason-section .mca-reason-card .upper-sec {
    border-bottom: 1px solid black;
}

.Mca-reason-section .swiper-pagination-clickable .swiper-pagination-bullet {
    background: #000;
    width: 10px;
    height: 4px;
    border-radius: 5px;
    margin: 2px !important;
}

.Mca-reason-section .swiper-pagination-clickable .swiper-pagination-bullet-active {
    background: #000;
    width: 17px !important;
    height: 4px;
    border-radius: 5px;
    margin: 2px !important;
}

.Mca-reason-section .swiper-wrapper {
    padding: 0px 5px 70px 5px;
}

@media screen and (min-width: 0px) and (max-width: 400px) {
    .swiper-backface-hidden .swiper-slide {
        padding: 0px 0px;
    }

    .Mca-reason-section .mca-reason-card .lower-sec p {
        font-size: 14px;
    }

    .Mca-reason-section .mca-reason-card .upper-sec h5 {
        font-size: 16px;
    }

    .Mca-reason-section .mca-reason-card {
        margin: 0px 30px;
    }

    .Mca-reason-section .heading h4 {
        font-size: 20px;
    }
}

@media screen and (min-width: 401px) and (max-width: 576px) {
    .Mca-reason-section .mca-reason-card .lower-sec p {
        font-size: 14px;
    }

    .Mca-reason-section .mca-reason-card .upper-sec h5 {
        font-size: 16px;
    }

    .swiper-backface-hidden .swiper-slide {
        padding: 0px 0px;
    }

    .Mca-reason-section .heading h4 {
        font-size: 20px;
    }

}

@media screen and (min-width: 577px) and (max-width: 768px) {
    .Mca-reason-section .mca-reason-card .lower-sec p {
        font-size: 14px;
    }

    .Mca-reason-section .mca-reason-card .upper-sec h5 {
        font-size: 16px;
    }

    .swiper-backface-hidden .swiper-slide {
        padding: 0px 0px;
    }

    .Mca-reason-section .heading h4 {
        font-size: 20px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .Mca-reason-section .mca-reason-card .lower-sec p {
        font-size: 15px;
    }

    .Mca-reason-section .mca-reason-card .upper-sec h5 {
        font-size: 18px;
    }

    .Mca-reason-section .heading h4 {
        font-size: 25px;
    }
}