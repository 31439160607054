.blog-career-path-section {
    margin: 5% 0px 4% 0px;
}
.blog-career-path-section h1.heading {
    font-size: 32px;
    font-weight: bold;
    color: #000;
    text-align: center;
}
.blog-career-path-section .blog-swiper .career-path-card .img-div .swiper-img {
    width: 100%;
}
.blog-career-path-section .blog-swiper .career-path-card {
    position: relative;
    cursor: pointer;
}
.blog-career-path-section .blog-swiper .career-path-card .overlay-color {
    background: #00000082;
    opacity: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    transition: opacity 0.5s ease;
}
.blog-career-path-section .blog-swiper .career-path-card .overlay-text-before-hover {
    position: absolute;
    left: 50%;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    width: 100%;
    opacity: 1;
    transition: opacity 0.5s ease;
}
.blog-career-path-section .blog-swiper .career-path-card .overlay-text-before-hover h3 {
    color: #FFF;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    padding: 10px;
}
.blog-career-path-section .blog-swiper .career-path-card .overlay-text-after-hover {
    position: absolute;
    left: 50%;
    text-align: center;
    top: 54%;
    transform: translate(-50%, -50%);
    opacity: 1;
    width: 100%;
    opacity: 0;
    transition: opacity 0.5s ease;
}
.blog-career-path-section .blog-swiper .career-path-card .overlay-text-after-hover p {
    color: #FFF;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 10;
    padding: 10px;
}
.blog-career-path-section .blog-swiper .career-path-card:hover .overlay-text-after-hover {
    opacity: 1;
    transition: opacity 0.5s ease;
}
.blog-career-path-section .blog-swiper .career-path-card:hover .overlay-text-before-hover {
    opacity: 0;
    transition: opacity 0.5s ease;
}
.blog-career-path-section .blog-swiper .swiper-pagination-bullets {
    display: none;
}
.blog-career-path-section .blog-swiper .swiper-wrapper {
    padding: 50px 0px;
}


@media(min-width:0px) and (max-width:575px)
{
    .blog-career-path-section .blog-swiper .career-path-card .overlay-text-before-hover h3 {

        font-size: 18px;
    } 
    .blog-career-path-section .blog-swiper .career-path-card .overlay-text-after-hover p {

        font-size: 12px;
    }
    .blog-career-path-section h1.heading {
        font-size: 18px;
    }
}
@media(min-width:576px) and (max-width:768px)
{
    .blog-career-path-section .blog-swiper .career-path-card .overlay-text-before-hover h3 {
        font-size: 22px;
    } 
    .blog-career-path-section .blog-swiper .career-path-card .overlay-text-after-hover p {
        font-size: 12px;
    }
    .blog-career-path-section h1.heading {
        font-size: 24px;
    }
}

@media(min-width:769px) and (max-width:991px)
{
    .blog-career-path-section .blog-swiper .career-path-card .overlay-text-before-hover h3 {

        font-size: 24px;
    } 
    .blog-career-path-section .blog-swiper .career-path-card .overlay-text-after-hover p {

        font-size: 13px;
    }
    .blog-career-path-section h1.heading {
        font-size: 24px;
    }
}
@media(min-width:992px) and (max-width:1200px)
{
    .blog-career-path-section .blog-swiper .career-path-card .overlay-text-before-hover h3 {

        font-size: 26px;
    } 
    .blog-career-path-section .blog-swiper .career-path-card .overlay-text-after-hover p {

        font-size: 14px;
    }
    .blog-career-path-section h1.heading {
        font-size: 26px;
    }
}
@media(min-width:1201px) and (max-width:1400px)
{
    .blog-career-path-section .blog-swiper .career-path-card .overlay-text-before-hover h3 {

        font-size: 28px;
    } 
    .blog-career-path-section .blog-swiper .career-path-card .overlay-text-after-hover p {

        font-size: 14px;
    }
    .blog-career-path-section h1.heading {
        font-size: 30px;
    }
}